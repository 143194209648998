<template>
  <div class="dashboard p-4">
    <div class="mt-5 w-full">
      <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
        <Icon
          icon="bxs:rocket"
          class="mr-2 text-3xl"
        />
        Settings
      </h1>
    </div>





    <!-- grid wrapper card -->
    <div class="wrapper-card gap-2 mt-5 ">
      <div class="p-5 flex gap-4">
        <div
          class="w-48 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        >
          <!--<a href="#profile" 
                :class="classes('#profile')+' rounded-t-lg'">
                    Profile
                </a>-->
          <a
            href="#password"
            :class="classes('#password')+ 'border-b'"
          >
            Change Password
          </a>
          <a
            href="#2fa"
            :class="classes('#2fa')+ 'border-b'"
          >
            Two Factor
          </a>
          <a
            href="#membership" 
            :class="classes('#membership')+ 'border-b'"
          >
            Membership
          </a>
          <a
            href="#invoices"
            :class="classes('#invoices')+ 'rounded-b-lg'"
          >
            Invoices
          </a>
          <a
            href="#whitelist"
            :class="classes('#whitelist')+ 'rounded-b-lg'"
          >
            IP Whitelist
          </a>
        </div>
        <div class="card bg-white max-w-4xl dark:bg-gray-800 w-full rounded-md p-5 text-gray-800 dark:text-gray-200 dark:text-gray-200">
          <subscription v-if="$route.hash == '#membership'" />
          <invoices v-if="$route.hash == '#invoices'" />
          <TwoFactor v-if="$route.hash == '#2fa'" />
          <Password v-if="$route.hash == '#password'" />
          <Whitelist v-if="$route.hash == '#whitelist'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subscription from './subscription'
import Invoices from './invoices'
import TwoFactor from './2fa'
import Password from './password'
import Whitelist from './whitelist'

export default {
    name:"SettingsLayout",
    components:{
        Subscription,
        Invoices,
        TwoFactor,
        Password,
        Whitelist
    },
    mounted(){
        
    },
    methods:{
        classes(hash){
            if(this.$route.hash == hash){
                return "block py-2 px-4 w-full text-white bg-blue-700  border-gray-200 cursor-pointer dark:bg-gray-800 dark:border-gray-600";
            }
            return "block py-2 px-4 w-full border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
        }
    }
}
</script>

<style>

</style>