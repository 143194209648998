<template>
  <div class="dashboard p-4">
    <nav
      class="flex"
      aria-label="Breadcrumb"
    >
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <a
            href="#"
            class="
              inline-flex
              items-center
              text-sm
              font-medium
              hover:text-gray-900
              dark:text-gray-400 dark:hover:text-white
            "
          >
            <Icon
              :icon="'cryptocurrency:' + icon"
              class="mr-2"
            />

            {{ chainName }}
          </a>
        </li>
      </ol>
    </nav>
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
        <Icon
          :icon="'cryptocurrency:' + icon"
          class="mr-2 text-3xl"
        />
        {{ chainName }} Ipn: {{ $route.params.id }}
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid gap-2 mt-5">
      <!--
        <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5">
          <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
            Create new IPN
          </h2>
          <p class="text-gray-400 font-lexend font-normal">
            Please fill at least one of the fields "From Address", "To Address",
            "Contract" or "Token". To limit the ammount of Notifications, we
            recommend you to fill at least two of this fields.
          </p>

        
        </div>
        -->

      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5">
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
          IPNs
        </h2>
        <p class="text-gray-400 font-lexend font-normal">
          A list with your webhook requests
        </p>
        

        <div
          class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mt-5"
        >
          <div class="flex flex-wrap gap-2">
            <div
              class="
              flex
              lg:w-1/3
              md:w-1/2
              w-full
              items-center
              p-2
              rounded
              border border-gray-200
              dark:border-gray-700
              mb-3
            "
            >
              <input
                id="bordered-checkbox-1"
                v-model="onlyFailed"
                type="checkbox"
                name="bordered-checkbox"
                class="
                w-4
                h-4
                text-blue-600
                bg-gray-100
                rounded
                border-gray-300
                focus:ring-blue-500
                dark:focus:ring-blue-600 dark:ring-offset-gray-800
                focus:ring-2
                dark:bg-gray-700 dark:border-gray-600
              "
                @change="delayedSearch"
              >
              <label
                for="bordered-checkbox-1"
                class="
                ml-2
                text-sm
                font-medium
                text-gray-900
                dark:text-gray-300
              "
              >Only failed</label>
              <div class="flex-grow justify-self-end text-right">
                <button
                  type="button"
                  class="
                  text-white
                  bg-red-700
                  hover:bg-red-800
                  focus:ring-4 focus:outline-none focus:ring-red-300
                  font-medium
                  rounded-lg
                  
                  p-2
                  text-center
                  inline-flex
                  items-center
                  mr-2
                  dark:bg-red-600
                  dark:hover:bg-red-700
                  dark:focus:ring-red-800
                "
                  @click="resend($route.params.id, true)"
                >
                  <span class="text-2xl"><Icon icon="bx:refresh" /></span> Resend all
                </button>
              </div>
            </div>
            <div
              class="
              flex grow
              input-box mb-5
              border
              dark:bg-gray-900 dark:border-gray-700
              rounded-md
              box-border
              lg:flex
              md:flex
              focus-within:bg-gray-100
              dark:focus-within:bg-gray-700
            "
            >
              <span class="text-3xl p-2 text-gray-400"><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 50 50"
                class="iconify iconify--ei"
              >
                <path
                  fill="currentColor"
                  d="M23 36c-7.2 0-13-5.8-13-13s5.8-13 13-13s13 5.8 13 13s-5.8 13-13 13zm0-24c-6.1 0-11 4.9-11 11s4.9 11 11 11s11-4.9 11-11s-4.9-11-11-11z"
                />
                <path
                  fill="currentColor"
                  d="m32.682 31.267l8.98 8.98l-1.414 1.414l-8.98-8.98z"
                /></svg></span><input
                v-model="search"
                v-debounce:300="delayedSearch"
                type="text"
                placeholder="Search..."
                class="
                p-3
                w-full
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              "
              >
            </div>
          </div>
        </div>
        <div class="wrapper-card grid grid-cols-2 lg:grid-cols-3 gap-2 mt-5 dark:text-white">
          <div
            id="browser"
            class="
              col-span-3
              xl:col-span-1
              h-96
              w-full
              overflow-auto
              pr-2
              items-center
            "
          >
            <div
              v-if="isLoading"
              class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mx-auto mt-5"
            >
              <span class="mr-2 text-xl mx-auto text-6xl">
                <Icon
                  icon="line-md:loading-twotone-loop"
                  class="mx-auto"
                />
              </span>
            </div>

            <div
              v-show="ipns.length == 0 && !isLoading"
              class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200"
            >
              <Icon
                icon="akar-icons:face-sad"
                class="text-center mx-auto text-5xl"
              />
              <p class="text-xl text-center">
                No results were found. Try other search criterias or wait for new IPN's
              </p>
            </div>

            <div
              v-for="ipn in ipns"
              id="list"
              :key="ipn"
              :class="'flex flex-wrap items-center w-full p-2 items-center align-middle items-center bg-gray-200 h-12 mx-auto cursor-pointer hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 border-l-8 rounded mb-2 '+border(ipn.success, ipn.attempts)"
              @click="selectedIpn = ipn"
            >
              <div class="flex-1 items-center">
                <span>{{ ipn.id }}</span>  
              </div>
              <div class="justify-self-end text-right">
                <span v-if="!ipn.success && ipn.attempts < 10 && ipn.attempts > 0"
                >
                  tries: {{ipn.attempts}}
                </span>
                <button
                  v-if="!ipn.success && ipn.attempts >= 10"
                  type="button"
                  class="
                      text-white
                      bg-red-700
                      hover:bg-red-800
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-2xl
                      p-1
                      text-center
                      inline-flex
                      items-center
                      mr-2
                      dark:bg-red-600
                      dark:hover:bg-red-700
                      dark:focus:ring-red-800
                    "
                  @click="resend(ipn.id, false)"
                >
                  <Icon icon="bx:refresh" />
                </button>
              </div>
            </div>
            <button
              v-if="!isLoading && ipns.length > 0"
              type="button"
              :disabled="page == totalPages && page < totalPages"
              class="justify-center content-center items-center flex py-2.5 px-5 mr-2 my-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 w-full text-center"
              @click="getIpns()"
            > 
              <span
                v-if="buttonText == 'Loading'"
                class="text-2xl mr-2"
              ><Icon icon="line-md:loading-twotone-loop" /></span> <span
                v-else
                class="text-xl"
              >{{ buttonText }}</span>
            </button>
          </div>
    
          <div
          id="code" 
            class="col-span-3
                xl:col-span-2"
          >
            <div
              
              class="
              
              max-h-96
              
              overflow-auto
              pr-2
              p-2
              bg-gray-200
              dark:bg-gray-700
              rounded
            "
            >
              <pre v-if="selectedIpn != null"
              
                class="w-full overflow-x-auto overflow-y-auto">Response:<br>{{ selectedIpn.status }}<br><br>Webhook Request Body: <br>{{ selectedIpn.content }}
            </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import { Icon } from "@iconify/vue";
import { getIpns, resend } from "../api/webhooks.api";
import { vue3Debounce } from 'vue-debounce'
import Scrollbar from 'smooth-scrollbar';
import moment from "moment";

export default {
  name: "NotificationBrowser",
  components: { Icon },
  directives: {
    debounce: vue3Debounce({ lock: true })
  },
  props: {chainName: String, icon: String},
  data() {
    return {
      selectedIpn: {sontent:null, status:null},
      page:1,
      nextPage:1,
      limit:100,
      search: "",
      totalPages:1,
      ipns: [],
      noResult: false,
      isLoading: true,
      onlyFailed: this.$route.hash=='#failed'?true:false
    };
  },
  watch: {
    
  },
  mounted() {
    Scrollbar.init(document.querySelector('#browser'));
    Scrollbar.init(document.querySelector('#code'));
    this.getIpns();
    
  },
  created() {
    this.$watch(
      () => this.chainName,
      (toParams, previousParams) => {
        this.ipns = [];
        this.getIpns(toParams);
      }
    );
  }, 
  methods: {
    human(date){
        console.log(date);
      date = new Date((date.timestamp * 1000));

      //return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).toNow();
    },
    
    border(state, attempts) {
      if (attempts < 10 && !state) {
        return "border-yellow-600 ";
      }
      if (state) {
        return "border-green-600 ";
      }
      return "border-red-600 ";
    },
    delayedSearch(){
      this.page=1;
      this.nextPage = 1;
      this.totalPages = 1;
      this.ipns = [];
      console.log("search");
      this.getIpns();
    },
    async getIpns(chainName) {
      let params = this.chainName.toLowerCase();
      if (chainName) {
        params = chainName;
      }
      this.buttonText = 'Loading';
      this.isLoading = true;
      this.ipns = [];
        try {
        getIpns(params, this.$route.params.id, this.onlyFailed, this.nextPage, this.limit, this.search).then(
            (response) => {
              if (response.status == 200) {
                if (response.data.data.ipns.length > 0) {
                  this.ipns.push(...response.data.data.ipns);
                  this.totalPages = response.data.data.total_pages;
                  this.page = response.data.data.current_page;
                  this.nextPage = response.data.data.current_page+1;
                  this.buttonText = "Load more";
                  if(this.page == this.totalPages){
                    this.buttonText = "No more Data";
                  }
                  
                  
                }
               else {
                  this.buttonText = "No result found";
                }
                this.isLoading = false;
              }
            }
          ).catch(error => {
            this.buttonText = "No more Data";
          });
      } catch(err) {
        this.buttonText = "No more Data";
      }

      
      
    },
    resend(id, all) {
      resend(this.chainName.toLowerCase(), id, all).then((response) => {
        if (response.status == 200) {
          this.nextPage = this.nextPage-1;
          this.getIpns();
        }
      });
    },
  },
};
</script>
    
    <style>
</style>