<template>
  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl dark:text-white font-medium">
        Manage Announcements
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5" />

    <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
        Announcements
      </h2>
      <div class="mt-5 w-full">
        
      </div>
      <a
        href="/admin/announcement/new"
        class="float-right"
      >
        <h2 class="text-white bg-gruen px-3 py-1  my-3 rounded-md"> + New Announcement</h2>
      </a>

      <div class="wrapping-table mt-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Content
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Valid from - (to)
              </th>

              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Actions
              </th>
            </tr>
          </thead>
              

          <tbody>
            <tr
              v-for="announcement in announcements"
              :key="announcement.id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
            >
              <td class="px-6 py-4">
                <div
                  :class="announcement.type +' '+ (announcement.type=='danger-alert'?'bg-red-500':(announcement.type=='warning-alert'?'bg-yellow-500':(announcement.type=='success-alert'?'bg-green-500':'bg-gruen')))+' flex py-3 px-5 text-white w-full box-border rounded-md'"
                >
                  <span class="text-2xl mr-3">
                    <Icon
                      v-if="announcement.type == 'danger-alert'"
                      icon="ic:outline-dangerous"
                    />
                    <Icon
                      v-if="announcement.type == 'warning-alert'"
                      icon="clarity:warning-line"
                    />
                    <Icon
                      v-if="announcement.type == 'success-alert'"
                      icon="bi:check-circle"
                    />
                    <Icon
                      v-if="announcement.type == 'primary-alert'"
                      icon="bytesize:info"
                    />
                  </span>
                  <span> <b> {{ announcement.headline }}: </b> {{ announcement.content }} </span>
                </div>
              </td>


              <td class="px-6 py-4">
               from: {{ announcement.validFrom }} <br> to: <span v-if="(announcement.validTo != null)">{{ announcement.validTo }} </span> <span v-else>open end</span>
              </td>
              <td class="px-6 py-4">
                <a
                  :href="'/admin/announcement/'+announcement.id"
                  class="text-white bg-lila px-3 py-1 ml-1 rounded-md"
                >EDIT</a>
                <br><br>
                <form @submit.prevent="deleteAnnouncement(announcement.id)">
                  <button
                    type="submit"
                    class="text-white bg-red-700 px-3 py-1 ml-1 rounded-md"
                  >
                    DELETE
                  </button>
                </form>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {getAnnouncements, deleteAnnouncement} from '../../../api/admin/announcements.api'
import { Icon } from "@iconify/vue";

export default {
  components:{Icon},
    data() {
        return {
            announcements: [],
            errors: "",
            loading: true
        };
    },
    
    mounted() {
        this.getAnnouncements();
    },
    
    methods: {
        getAnnouncements() {
            getAnnouncements()
                .then(response => {
                    if (response.data) {
                        console.log(response.data.data.announcements);
                        this.announcements = response.data.data.announcements;
                        //console.log(response.data.data.announcements);
                    }
                }).catch((error) => {

                    console.log("error" + JSON.stringify(error));
                    this.errors = error;

                });
            //this.announcements = flatten(this.announcements);
        },
        deleteAnnouncement(id) {
            deleteAnnouncement(id)
                .then(response => {
                    if (response.data) {
                       
                        this.getAnnouncements();
                        //console.log(response.data.data.announcements);
                    }
                }).catch((error) => {

                    console.log("error" + JSON.stringify(error));
                    this.errors = error;

                });
            //this.announcements = flatten(this.announcements);
        }

    }
}
</script>
