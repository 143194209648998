<template>
  <div>
    <h2 class="text-xl">
      Invoices
    </h2>
    <div
      class=""
      :v-if="invoices"
    >
      <div
        v-for="invoice in invoices"
        :key="invoice.id"
        class="mb-2 min-h-20  box-shadow flex flex-wrap bg-gray-200 dark:bg-gray-700 rounded-lg flex p-2 place-items-center"
      >
        <div class="shrink pr-5">
          {{ moment(invoice.date).format("YYYY-MM-DD") }}
        </div>
        <div class="shrink px-5 w-300">
          {{ invoice.itemName }}
        </div>
        <div class="grow  w-100">
          €{{ th(invoice.itemprice) }}
        </div>
        <div class="shrink w-100">
          <a
            href="#"
            type="button"
            :v-if="invoice.uri != null"
            class="flex mr-2 text-white bg-gruen hover:bg-blue-800 focus:ring-2 focus:ring-lila font-medium rounded-lg text-sm px-5 py-2.5 float-right dark:hover:bg-lila focus:outline-none dark:focus:ring-lila"
            @click.prevent="downloadInvoice(invoice.id, invoice.date+'_'+invoice.itemName)"
          > Download </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getInvoices, downloadInvoice} from '../../api/invoices.api'


export default {
    name: "Invoices",
  data() {
    
    return {
        invoices: null,
        url: process.env.VUE_APP_API
    }},
    mounted(){
        this.getInvoices()
    },
  methods: {
    th(num){ 
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getInvoices() {
        getInvoices()
        .then((response) => {
          if (response.status == 200) {
            if (response.data != null) {
              this.invoices = response.data;
            }
          }
        });
    },
    downloadInvoice(id, name) {
      downloadInvoice(id)
      .then(response => {
        const linkSource = `data:application/pdf;base64,${response.data}`;
    const downloadLink = document.createElement("a");
    const fileName = name+".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
      }).catch(console.error)
  }
}
};
</script>

<style>
</style>