<template>
  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl  font-medium">
        <span v-if="$route.params.id =='new'">New Plan</span>
        <span v-if="$route.params.id !='new'">Edit Plan: </span>
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5" />

    <div class="mt-2 text-gray-400 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
      <form @submit.prevent="postPlan">
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="email"
          >Name</label>
          <input
            id="name"
            v-model="form.name"
            class="p-3 bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            type="text"
            placeholder="Name"
            name="name"
          >
          <span
            v-show="errors.name"
            class="text-xs text-red-700"
          >{{ errors.name }}</span>
        </div>
        
        
        <div class="space-y-2 mb-4">
          <input
            id="isFree"
            v-model="form.isFree"
            type="checkbox"
            name="isFree"
            class="mr-1 checked:bg-lila p-3 bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700" 
          >
      
          <label
            class="text-gray-400"
            for="policy"
          > Is free
          </label>
        </div>
        <div class="space-y-2 mb-4">
          <input
            id="isFeatured"
            v-model="form.isFeatured"
            type="checkbox"
            name="isFeatured"
            class="mr-1 checked:bg-lila p-3 bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700" 
          >
      
          <label
            class="text-gray-400"
            for="policy"
          > Is featured
          </label>
        </div>
        <div class="space-y-2 mb-4">
          <input
            id="isPrivate"
            v-model="form.isPrivate"
            type="checkbox"
            name="isPrivate"
            class="mr-1 checked:bg-lila p-3 bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700" 
          >
      
          <label
            class="text-gray-400"
            for="policy"
          > Is Private
          </label>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="plan_pricing"
          >Monthly Price</label>
          <input
            id="plan_pricing"
            v-model="form.plan_pricing"
            class="p-3 bg-white disabled:opacity-50 dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            type="number"
            placeholder="19"
            name="planPricing"
            :disabled="form.isFree"
          >
          <span
            v-show="errors.plan_pricing"
            class="text-xs text-red-700"
          >{{ errors.plan_pricing }}</span>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="stageprice_staging"
          >Yearly Price</label>
          <input
            id="stageprice_staging"
            v-model="form.plan_pricing_yearly"
            class="p-3 bg-white disabled:opacity-50 dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            type="number"
            placeholder="99"
            name="stageprice_staging"
            :disabled="form.isFree"
          >
          <span
            v-show="errors.plan_pricing_yearly"
            class="text-xs text-red-700"
          >{{ errors.plan_pricing_yearly }}</span>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="sla"
          >Support Response time (in hours)</label>
          <input
            id="sla"
            v-model="form.sla"
            class="p-3 bg-white disabled:opacity-50 dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            type="number"
            placeholder="24"
            name="sla"
          >
          <span
            v-show="errors.sla"
            class="text-xs text-red-700"
          >{{ errors.sla }}</span>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="transactions_limit"
          >Transaction Limit</label>
          <input
            id="transactions_limit"
            v-model="form.transactions_limit"
            class="p-3 bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            type="number"
            placeholder="100"
            name="transactions_limit"
          >
          <span
            v-show="errors.transactions_limit"
            class="text-xs text-red-700"
          >{{ errors.transactions_limit }}</span>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="ethaddresses_limit"
          >ETH addresses limit</label>
          <input
            id="ethaddresses_limit"
            v-model="form.ethaddresses_limit"
            class="p-3 bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            type="number"
            placeholder="100"
            name="ethaddresses_limit"
          >
          <span
            v-show="errors.ethaddresses_limit"
            class="text-xs text-red-700"
          >{{ errors.ethaddresses_limit }}</span>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="ipns_limit"
          >IPNs Limit</label>
          <input
            id="ipns_limit"
            v-model="form.ipns_limit"
            class="p-3 bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            type="number"
            placeholder="100"
            name="ipns_limit"
          >
          <span
            v-show="errors.ipns_limit"
            class="text-xs text-red-700"
          >{{ errors.ipns_limit }}</span>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="requests_limit"
          >Requests Limit</label>
          <input
            id="requests_limit"
            v-model="form.requests_limit"
            class="p-3 bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            type="number"
            placeholder="1000"
            name="requests_limit"
          >
          <span
            v-show="errors.requests_limit"
            class="text-xs text-red-700"
          >{{ errors.requests_limit }}</span>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="apikeys_limit"
          >Api keys Limit</label>
          <input
            id="apikeys_limit"
            v-model="form.apikeys_limit"
            class="p-3 bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            type="number"
            placeholder="10"
            name="apikeys_limit"
          >
          <span
            v-show="errors.apikeys_limit"
            class="text-xs text-red-700"
          >{{ errors.requests_limit }}</span>
        </div>

        <button
          class="
          bg-gradient-to-r from-blackberry-300 via-blackberry-400 to-blackberry-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blackberry-300 dark:focus:ring-blackberry-800
        w-full
        px-10
        py-2
        bg-gruen
        text-white
        rounded-md
        hover:bg-lila hover:drop-shadow-md
        duration-300
        ease-in
      "
          type="submit"
        >
          Create Plan
        </button>
      </form>
    </div>
  </div> 
</template>

<script>
import {updatePlan, createPlan, getPlan} from '../../../api/admin/plans.api'


export default {

    data() {
        return {
            form:{
                name:"",
                months: 1,
                isFree: false,
                isFeatured: false,
                isPrivate: false,
                transactions_limit: 0,
                ethaddresses_limit: 0,
                ipns_limit: 0,
                requests_limit: 0,
                apikeys_limit: 0,
                sla: 0,
                plan_pricing: 0,
                plan_pricing_yearly: 0
            },
            errors:""
        };
    },
    mounted(){
        if(this.$route.params.id != 'new'){
            this.getPlan(this.$route.params.id);
        }
        else{
            console.log("new Plan")
        }
    },
    methods:{
         getPlan(id) {
            getPlan(id)
                .then(response => {
                    if (response.data) {

                        this.form = JSON.parse(response.data.data.plan);
                        //console.log(response.data.data.plans);
                    }
                }).catch((error) => {

                    console.log("error" + JSON.stringify(error));
                    this.errors = error;

                });
            //this.plans = flatten(this.plans);
        },
        postPlan(){
            if(this.$route.params.id != 'new'){
                updatePlan(this.$route.params.id, this.form)
                .then(response => {
                    if (response.data) {

                        //this.form = JSON.parse(response.data.data.plan);
                        console.log(response.data.data.plan);
                        this.$router.push('/admin/plans') 
                    }
                }).catch((error) => {

                    console.log("error" + JSON.stringify(error));
                    this.errors = error;

                });
            }
            else{
                this.erors=[]
                createPlan(this.form)
                .then(response => {
                    if (response.data) {

                        //this.form = JSON.parse(response.data.data.plan);
                        console.log(response.data.data.plan);
                        this.$router.push('/admin/plans') 
                    }
                }).catch((error) => {

                    console.log(error.response.data.errors);
                    this.errors = error.response.data.errors;

                });
            }

            
        }

    
    }
}
</script>