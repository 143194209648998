<template>
  <div class="">
    <form
      class="space-y-4"
      @submit.prevent="send"
    >
      <div
        v-if="isSuccess"
        class="success-alert flex bg-green-500 py-3 px-5 text-white box-border rounded-md"
      >
        <span class="text-2xl mr-3"><svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 16 16"
          class="iconify iconify--bi"
        ><g fill="currentColor"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" /></g></svg>
        </span>
        <span> Your reset link was sent. Please check your Mailbox</span>
      </div>
      <div
        v-if="isFailed"
        class="success-alert flex bg-red-500 py-3 px-5 text-white box-border rounded-md"
      >
        <span class="text-2xl mr-3"><svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 16 16"
          class="iconify iconify--bi"
        ><g fill="currentColor"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" /></g></svg>
        </span>
        <span> {{errorMessage}}</span>
      </div>

      <div
        v-if="errors"
        class="danger-alert bg-red-500 flex py-3 px-5 text-white w-full box-border rounded-md"
      >
        <span class="text-2xl mr-3"><svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
          class="iconify iconify--ic"
        ><path
          fill="currentColor"
          d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8zm-4.17-7.14L12 10.59L9.17 7.76L7.76 9.17L10.59 12l-2.83 2.83l1.41 1.41L12 13.41l2.83 2.83l1.41-1.41L13.41 12l2.83-2.83l-1.41-1.41z"
        /></svg>
        </span>
        <span> {{ errors }} </span>
      </div>
    
      <h1 class="text-center text-2xl text-gray-800 dark:text-gray-800">
        Send password reset Link
      </h1>
      <div class="flex flex-col space-y-2">
        <label
          class="text-sm font-light"
          for="email"
        >Email</label>
        <input
          id="email"
          v-model="form.email"
          class="px-3 py-2 rounded-md border border-slate-400"
          type="email"
          placeholder="Your Email"
          name="email"
        >
      </div>
      <button
        class="w-full px-10 py-2 bg-gruen text-white rounded-md
              hover:bg-lila hover:drop-shadow-md duration-300 ease-in"
        type="submit"
      >
        Send Link
      </button>
    </form>
  </div>
</template>

<script>

  import {sendResetLink } from "../../api/accounts.api";



export default {
    name: "Login",

    data() {
      return {
        form:{
          email: "",
         
        },

        errors: null,
        isSuccess: false,
        isFailed: false,
        errorMessage: false
      }
    },

    mounted() {

      if(this.$route.query.confirm != undefined){
        this.confirm();
      }
    },
    methods: {
      send(){


        sendResetLink(this.form)
          .then(response => {
            this.isSuccess=true;

          }).catch(error => {
            this.isFailed = true;
            this.errorMessage = error.response.data;
          });

        }
    }

}
</script>

<style>

</style>


