import axios from 'axios';
import UserService from "./user.service";
import { createToaster } from "@meforma/vue-toaster";
import router from '@/router';

const toaster = createToaster({ /* options */ });

let axiosClient = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials':true
  }
});

axiosClient.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if (!error.response) {
    toaster.error(
        "Network/Server Error <br/> Please Contact Support",
        {}
      );
    return Promise.reject(error);
  }
  // all the other error responses
  switch(error.response.status) {
      case 400:
        console.error(error.response.status, error.message);
        //toaster.error( 'Nothing to display, Data Not Found');
        break;
      case 401: // authentication error, logout the user
          //console.error(error.response.status, error.message);
          toaster.error( 'Session Expired, Please login again', {});
          UserService.logout();
          router.push('/login')
          return;

          
          break;

      case 404: // authentication error, logout the user
        toaster.error( '404 not Found', {});
        break;

      default:
          console.error(error.response.status, error.message);
          toaster.error('Server Error');

  }

  console.error("Looks like there was a problem. Status Code: " + error.response.status);
  return Promise.reject(error)
})



/* Vor jedem Request wird nachgesehen ob ein Token im Localstorage vorhanden ist und dann dynamisch der Auth Header gesetzt */
axiosClient.interceptors.request.use(config => {

  config.headers["Accept"] = 'application/json';
  config.headers["Content-Type"] = 'application/json';
  config.headers["Access-Control-Allow-Origin"] = '*';
  config.headers["Access-Control-Allow-Headers"] = '*';
  config.headers["Access-Control-Allow-Credentials"] = true;
  config.withCredentials = true;

    // Do something before request is sent
    if(localStorage.hasOwnProperty('token')){
      config.headers["Authorization"] = "Bearer " + localStorage.getItem('token');
    }

  
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});









  export default axiosClient;
