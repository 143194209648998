<template>
  <!-- App -->
  <div class="flex  font-lexend bg-gray-300 dark:bg-gray-900 bg-gradient-to-br from-blackberry/80 to-teal/80">
    <component :is="layout">
      <router-view />
    </component>
  </div>
  <!-- end app -->
</template>

<script>
  const default_layout="dashboard";
 


  export default {
    
    name: "App",
    computed:{
      layout(){
        return (this.$route.meta.layout || default_layout) + '-layout';
      }

    },
    

    mounted() {


      var acc = document.getElementsByClassName("accordion");
      var i;
      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function () {
          this.classList.toggle("active");
          var panel = this.nextElementSibling;
          if (panel.style.display === "block") {
            panel.style.display = "none";
            this.classList.remove("bg-gray-100");
            this.classList.add("bg-transparent");
          } else {
            panel.style.display = "block";
            this.classList.add("bg-gray-100");
            this.classList.remove("bg-transparent");
          }
        });
      }
    },
  };
</script>
