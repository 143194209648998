<template>
  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl  text-gray-800 dark:text-gray-200 font-medium">
        Manage Coupons
      </h1>
    </div>
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5" />

    <div class="mt-2 bg-white dark:bg-gray-800 dark:text-white p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
        Create new coupon
      </h2>

      <form @submit.prevent="create()" class="grid lg:grid-cols-2 grid-cols-1  gap-2">
        <div class="flex flex-col space-y-2 mb-4">
          <label class="text-gray-400" for="amount">Amount off</label>
          <input id="name" v-model="form.amount" class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              " type="number" placeholder="99" name="amount">
          <span v-show="errors.type" class="text-xs text-red-700">{{
            errors.type
          }}</span>
        </div>

        <div class="flex flex-col space-y-2 mb-4">
          <label class="text-gray-400" for="percent">Percent off</label>
          <input id="percent" v-model="form.percent" class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              " type="number" placeholder="10" name="percent">
          <span v-show="errors.percent" class="text-xs text-red-700">{{
            errors.type
          }}</span>
        </div>


        <div class="flex flex-col space-y-2 mb-4">
          <label class="text-gray-400" for="redemptions">Max Redemptions</label>
          <input id="name" v-model="form.redemptions" class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              " type="number" placeholder="5" name="redemptions">
          <span v-show="errors.redemptions" class="text-xs text-red-700">{{
            errors.type
          }}</span>
        </div>


        <div class="flex flex-col space-y-2 mb-4">
          <label class="text-gray-400" for="email">Assign to users Dashboard</label>
          <input id="name" v-model="form.email" class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              " type="email" placeholder="info@chaingateway.io" name="email">
          <span v-show="errors.email" class="text-xs text-red-700">{{
            errors.type
          }}</span>
        </div>






        <button class="
              w-full
              col-span-2 
              px-10
              py-2
              bg-gruen
              text-white
              rounded-md
              hover:bg-lila hover:drop-shadow-md
              duration-300
              ease-in
              
              
            " type="submit">
          create
        </button>
      </form>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5" />

    <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
        coupons
      </h2>

      <div class="wrapping-table mt-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="uppercase px-6 py-3">
                ID
              </th>
              <th scope="col" class="uppercase px-6 py-3">
                value
              </th>
              <th scope="col" class="uppercase px-6 py-3">
                Redemptions
              </th>
 
            </tr>
          </thead>
          <tbody>
            <tr v-for="coupon in coupons" :key="coupon.id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50">
              <td class="px-6 py-4">
                {{ coupon.id }}
              </td>

              <td class="">
               <span v-if="coupon.amountOff">€{{ coupon.amountOff }}</span>  
               <span v-else>%{{ coupon.percentOff }}</span> 
              </td>
              <td class="px-6 py-4">
                {{ coupon.timesRedeemed }}/{{ coupon.maxRedemptions }}
              </td>



            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>


import { getCoupons, createCoupon } from '../../../api/admin/coupons.api'
import { Icon } from "@iconify/vue";


export default {
  components: {
    
  },

  data() {
    return {
      coupons: [],
      form_processing: false,
      form: {
        amount: null,
        percent: null,
        redemptions: 1,
        email: null



      },
      errors: "",
    };
  },
  mounted() {
    this.getCoupons();
  },

  methods: {
    getCoupons() {
      getCoupons()
        .then(response => {
          if (response.data) {

            this.coupons = JSON.parse(response.data);
            //console.log(response.data.data.coupons);
          }
        })
      //this.coupons = flatten(this.coupons);
    },
    remove(id) {
      deleteSubscriptioncoupons(id)
        .then(response => {
          console.log(response)
          this.$toast.success("coupon deleted successfully", {
            // optional options Object
          });
          this.getCoupons();
        })
    },
    create() {
      this.errors = [];
      this.form_processing = true;
      createCoupon(this.form)
        .then((response) => {
          this.form_processing = false;
          this.$toast.success(response.data, {
            // optional options Object
          });
          this.getCoupons();
          this.form.name = null;
        }).catch(error => {

          this.errors = error.response.data;
          this.form_processing = false;

        });
    }
  }
}
</script>
