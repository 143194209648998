import axiosClient  from "../services/axios.service";


export function getRequests(page=1, limit=100, search = ""){
    let data = {
        "page": page,
        "limit": limit,
        "search": search
    };
    return axiosClient.post('/v2/log/request/search', data);
}

export function getStatistics(type){
    let data = {
        "type": type,
       
    };
    return axiosClient.post('/v2/log/request/statistics', data);
}