import { createApp } from "vue";
import App from "./App.vue";
import Auth from "./layouts/Auth.vue";
import Dashboard from "./layouts/Dashboard.vue";
import router from "./router";
import { Icon } from "@iconify/vue";
import VueApexCharts from "vue3-apexcharts";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
// import "flowbite";
import "./assets/tailwind.css";
import "./assets/animate.css";
import "./assets/sass/css/windzo.css";
import Toaster from "@meforma/vue-toaster"
import moment from 'moment'
import { createPinia } from "pinia";
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import VueMatomo from 'vue-matomo'
var VueScrollTo = require('vue-scrollto');



const app = createApp(App);
const pinia = createPinia();

app.use(pinia);

app.component('AuthLayout', Auth)
app.component('DashboardLayout', Dashboard)
app.use(router, Icon);
app.use(VueApexCharts);
app.use(Toaster);
app.use(VueScrollTo);
app.use(VueTailwindDatepicker);

app.config.globalProperties.moment = moment;


app.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://analytics.bitblade.de/',
  siteId: 38,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  //cookieDomain: 'app.chaingateway.io',
  domains: 'app.chaingateway.io',
  preInitActions: [],
  trackSiteSearch: false,
  crossOrigin: undefined,
});


app.use(PerfectScrollbar);

app.mount("#app");

router.beforeEach((to, from, next) => {
  if(document.querySelector(".flex-sidebar")!== null){
    document.querySelector(".flex-sidebar").classList.add("hidden");
  }
  
  next();
});
