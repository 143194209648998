<template>
  <!-- App -->

    <div
      id="sidebar-scroll"
      class="flex-sidebar lg:flex-auto w-sidebar lg:block hidden bg-white dark:bg-gray-800 border-r-2 dark:border-gray-700 h-screen lg:z-0 z-40 overflow-auto lg:relative fixed" 
    >
      <Sidebar :user="user" />
    </div>
    <div class="dashboard flex-auto w-full overflow-auto h-screen" id="body-scroll">
      <Header :user="user" />
      <slot :scrollbar="scrollbar" />
      <Footer />
    </div>

  <!-- end app -->
</template>

<script>
  //import {state} from '../state/index.js';
  // vue Components
  import Sidebar from "@/components/Sidebar";
  import Header from "@/components/Header";
  import Footer from "@/components/Footer";
  // npm-js
  import Scrollbar from "smooth-scrollbar";
  import { useUserStore } from "../store/user";
  import { storeToRefs } from 'pinia'

  export default {
    name: "Dashboard",

    components: {
      Header,
      Footer,
      Sidebar,
    },
    setup() {

        const { user } = storeToRefs(useUserStore())
        return { user };
      },
    data(){
      return{
        scrollbar: null

      }
    },
    methods:{
      sidebarHide: function () {
        document.querySelector(".flex-sidebar").classList.add("hidden");
      },
    },
    mounted() {
  
      this.scrollbar = Scrollbar.init(document.querySelector("#body-scroll"));
      

      setTimeout(() => {
        var alert_dis = document.querySelectorAll(".alert-dismiss");
        alert_dis.forEach((x) =>
          x.addEventListener("click", function () {
            x.parentElement.classList.add("hidden");
          })
        );
      }, 100);

      //this.user = UserService.getAccount();

      // var acc = document.getElementsByClassName("accordion");
      // var i;
      // for (i = 0; i < acc.length; i++) {
      //   acc[i].addEventListener("click", function () {
      //     this.classList.toggle("active");
      //     var panel = this.nextElementSibling;
      //     if (panel.style.display === "block") {
      //       panel.style.display = "none";
      //       this.classList.remove("bg-gray-100");
      //       this.classList.add("bg-transparent");
      //     } else {
      //       panel.style.display = "block";
      //       this.classList.add("bg-gray-100");
      //       this.classList.remove("bg-transparent");
      //     }
      //   });
      // }
    },
  };
</script>
