<template>
  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl dark:text-white font-medium">
        Manage Plans
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5" />

    <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
        Plans
      </h2>
      <div class="mt-5 w-full">
        <div class="input-box my-3 border dark:bg-gray-900 dark:border-gray-700 rounded-md mr-5 hidden  w-full box-border lg:flex md:flex focus-within:bg-gray-100 dark:focus-within:bg-gray-700">
          <span class="text-3xl p-2 text-gray-400"><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 50 50"
            class="iconify iconify--ei"
          >
            <path
              fill="currentColor"
              d="M23 36c-7.2 0-13-5.8-13-13s5.8-13 13-13s13 5.8 13 13s-5.8 13-13 13zm0-24c-6.1 0-11 4.9-11 11s4.9 11 11 11s11-4.9 11-11s-4.9-11-11-11z"
            />
            <path
              fill="currentColor"
              d="m32.682 31.267l8.98 8.98l-1.414 1.414l-8.98-8.98z"
            />
          </svg></span><input
            v-model="search"
            type="text"
            placeholder="Search..."
            class="p-3 w-full bg-white dark:bg-gray-900 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
            @change="searchPlans"
          >
        </div>
      </div>
      <a
        href="/admin/plan/new"
        class="float-right"
      >
        <h2 class="text-white bg-gruen px-3 py-1  my-3 rounded-md"> + New Plan</h2>
      </a>

      <div class="wrapping-table mt-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                ID
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Name
              </th>

              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                limits
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                isFree
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Price
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Stripe
              </th>

              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Action
              </th>
            </tr>
          </thead>
              

          <tbody>
            <tr
              v-for="plan in plans"
              :key="plan.id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
            >
              <td class="px-6 py-4">
                {{ plan.id }}
              </td>
              <td class="px-6 py-4">
                {{ plan.name }}
              </td>

              <td class="px-6 py-4">
                <p class="mb-3">
                  <span class="text-green-800 bg-green-300 px-3 py-1  rounded-md"> transactions: {{ plan.transactions_limit }}</span>
                </p>
                <p class="mb-3">
                  <span class="text-yellow-800 bg-yellow-300 px-3 py-1 rounded-md"> ETH addresses: {{ plan.ethaddresses_limit }}</span>
                </p>
                <p class="mb-3">
                  <span class="text-orange-800 bg-orange-300 px-3 py-1 rounded-md"> IPN's: {{ plan.ipns_limit }}</span>
                </p>
                <p><span class="text-blue-800 bg-blue-300 px-3 py-1  rounded-md"> Requests: {{ plan.requests_limit }}</span></p>
              </td>
              <td class="px-6 py-4">
                {{ plan.isFree }}
              </td>


              <td class="px-6 py-4">
                monthly: {{ plan.plan_pricing }} <br> yearly: {{ plan.plan_pricing_yearly }}
              </td>
              <td class="px-6 py-4">
                id: {{ plan.stripe_id }} <br> monthly: {{ plan.stageprice }} <br> yearly: {{ plan.stageprice_yearly }}
              </td>
              <td class="px-6 py-4">
                <a
                  :href="'/admin/plan/'+plan.id"
                  class="text-white bg-gradient-to-r from-blackberry-300 via-blackberry-400 to-blackberry-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blackberry-300 dark:focus:ring-blackberry-800 px-3 py-1 ml-1 rounded-md"
                >EDIT</a>
                <br><br>
                <form @submit.prevent="deletePlan(plan.id)">
                  <button
                    type="submit"
                    class="text-white bg-red-700 px-3 py-1 ml-1 rounded-md"
                  >
                    DELETE
                  </button>
                </form>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {getPlans, searchPlans} from '../../../api/admin/plans.api'

export default {
    data() {
        return {
            plans: [],
            errors: "",
            loading: true
        };
    },
    mounted() {
        this.getPlans();
    },
    methods: {
        getPlans() {
            getPlans()
                .then(response => {
                    if (response.data) {

                        this.plans = JSON.parse(response.data.data.plans);
                        //console.log(response.data.data.plans);
                    }
                }).catch((error) => {

                    console.log("error" + JSON.stringify(error));
                    this.errors = error;

                });
            //this.plans = flatten(this.plans);
        },
        searchPlans() {
            searchPlans({
                    'search': this.search
                })
                .then(response => {
                    if (response.data) {

                        this.plans = JSON.parse(response.data.data.plans);
                        //console.log(response.data.data.plans);
                    }
                }).catch((error) => {

                    console.log("error" + JSON.stringify(error));
                    this.errors = error;

                });
            //this.plans = flatten(this.plans);
        }

    }
}
</script>
