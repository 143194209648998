import axiosClient  from "../services/axios.service";

export function getWallets(){
    return axiosClient.get('/v2/bitcoin/wallets');
}

export function getWallet(walletname){
    return axiosClient.get('/v2/bitcoin/wallets/'+walletname);
}

export function createWallet(data){
    return axiosClient.post('/v2/bitcoin/wallets', JSON.stringify(data));
}


export function deleteWallet(walletname, data){
    return axiosClient.delete('/v2/bitcoin/wallets/'+walletname, data);
}

export function getWalletAddresses(walletname){
    return axiosClient.get('/v2/bitcoin/wallets/'+walletname+'/addresses');
}

export function createWalletAddress(walletname){
    return axiosClient.post('/v2/bitcoin/wallets/'+walletname+'/addresses');
}


/*
//Not implemented yet
export function importWallet(data){
    return axiosClient.post('/v2/bitcoin/walletnamees/import', data);
}
*/