<template>
<div class="dashboard p-4">
    <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
                <a href="#" class="inline-flex items-center text-sm font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                    </svg>
                    Dashboard
                </a>
            </li>
        </ol>
    </nav>
    <!-- end nav -->
    <div class="mt-5 w-full">
        <h1 class="text-2xl  font-medium text-gray-800 dark:text-gray-200">
            Dashboard
        </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="w-full wrapper-card mt-5">
        <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 text-gray-800 dark:text-gray-200 dark:text-gray-200">
            <h2 class="font-bold text-lg ">
                Your Current limits
            </h2>
            <p class="mb-2">
                Be sure to upgrade your plan before you exceed the limits
            </p>
            <usage />
        </div>
    </div>
    <!-- end wrapper card -->
    <div class="mt-2 lg:flex block lg:gap-2">
        <requestsChart />
        <FailedWebhooks />
    </div>
    <latestRequests />
</div>
</template>

<script>
// @ is an alias to /src
import Usage from "./components/usage.vue";
import RequestsChart from "./components/requestsChart.vue";
import FailedWebhooks from "./components/failedWebhooks.vue";
import LatestRequests from "./components/latestRequests.vue";
import {
    useUserStore
} from "../store/user";
import {
    storeToRefs
} from 'pinia'

export default {
    name: "Dashboard",
    components: {
        Usage,
        RequestsChart,
        FailedWebhooks,
        LatestRequests
    },
    setup() {
        const {
            user
        } = storeToRefs(useUserStore())
        return {
            user
        };
    },
    data() {
        return {
            // for more guide apexchart.js
            // https://apexcharts.com/docs/chart-types/line-chart/
        };
        // end chart data line
    },

    mounted() {

    },
};
</script>
