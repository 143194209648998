<template>
  <div class="dashboard p-4">
    <nav
      class="flex"
      aria-label="Breadcrumb"
    >
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <a
            href="#"
            class="
              inline-flex
              items-center
              text-sm
              font-medium
              hover:text-gray-900
              dark:text-gray-400 dark:hover:text-white
            "
          >
            <Icon
              icon="bxs:key"
              class="mr-2"
            />

            Api Keys
          </a>
        </li>
      </ol>
    </nav>
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
        <Icon
          icon="bxs:key"
          class="mr-2 text-3xl"
        /> API Keys
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div
      class="
        wrapper-card
        grid

        gap-2
        mt-5
      "
    >
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5">
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
          Create new key
        </h2>
        <p class="text-gray-400 font-lexend font-normal">
          Here you can create an new key by define a name
        </p>

        <form
          class="space-y-6"
          action="#"
          @submit.prevent="createToken"
        >
          <div class="mt-2">
            <label
              for="name"
              :class="errors.name?'block mb-2 text-sm font-medium text-red-700 dark:text-red-500':'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'"
            >Token Name</label>
            <input
              id="token_name"
              v-model="form.name"
              type="text"
              name="token_name"
              placeholder="Application 1"
              :class="errors.name?'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500':'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' "
              required
            >
            <p
              v-show="errors.name"
              class="mt-2 text-sm text-red-600 dark:text-red-500"
            >
              <span class="font-medium"></span> {{ errors.name }}
            </p>
            <div class="mt-3">
              <button
                type="submit"
                clas="flex text-white bg-gruen hover:bg-blue-800 focus:ring-2 focus:ring-lila font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  dark:hover:bg-lila focus:outline-none dark:focus:ring-lila"
                class="button text-white inline-flex items-center bg-gradient-to-br from-blackberry-400 to-teal-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
              >
                <span
                  v-if="form_processing"
                  class="mr-2 text-xl"
                >
                  <Icon icon="line-md:loading-twotone-loop" /> </span>
                <span
                  v-else
                  class="mr-2 text-xl"
                >
                  <Icon icon="akar-icons:plus" /> </span>

                <span> Create Token </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div
      class=" 
        wrapper-card
        grid
        gap-2
        mt-5
      "
    >
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 overflow-hidden">
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
          Api Keys
        </h2>
        <p class="text-gray-400 font-lexend font-normal">
          A list with your existing Keys
        </p>
        <div
          v-if="isLoading"
          class="w-full  text-gray-800 dark:text-gray-200 dark:text-gray-200 mx-auto mt-5"
        >
          <span class="mr-2 text-xl mx-auto text-6xl ">
            <Icon
              icon="line-md:loading-twotone-loop"
              class="mx-auto"
            /> </span>
        </div>

            

        <div
          v-show="tokens.length == 0 && !isLoading"
          class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 "
        >
          <Icon
            icon="akar-icons:face-sad"
            class="text-center mx-auto text-5xl"
          />
          <p class="text-xl text-center">
            You have no api keys yet. Please create one
          </p>
        </div>

        <div
          
          v-show="tokens.length > 0 && !isLoading"
          class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mt-5 lg:overflow-auto overflow-x-scroll"
        >
          <table
            id="api-scroll" 
            class="table-auto text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll"
          >
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th
                  scope="col"
                  class="uppercase px-6 py-3"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="uppercase px-6 py-3"
                >
                  Created at
                </th>
                <th
                  scope="col"
                  class="uppercase px-6 py-3"
                >
                  Token
                </th>
                <th
                  scope="col"
                  class="uppercase px-6 py-3"
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="token in tokens"
                :key="token.id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              >
                <td class="px-6 py-4">
                  {{ token.name }}
                </td>
                <td class="px-6 py-4">
                  {{ moment(token.createdAt).format('YYYY-MM-DD hh:mm') }}
                </td>
                <td class="px-6 py-4">
                  {{ token.apikey }}
                </td>
                <td class="px-6 py-4">
                  <form
                    action="#"
                    @submit.prevent="deleteToken(token.id)"
                  >
                    <button
                      type="submit"
                      class="flex text-white bg-red-900 hover:bg-blue-800 focus:ring-2 focus:ring-lila font-medium rounded-lg text-sm px-2.5 py-2.5 mr-2 mb-2  dark:hover:bg-red-800 focus:outline-none dark:focus:ring-red-800"
                    >
                      <Icon icon="bx:trash" />
                    </button>
                  </form>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
    Icon
} from "@iconify/vue";

import {createApiKey, deleteApiKey, getApiKeys} from '../../api/accounts.api'
import Scrollbar from 'smooth-scrollbar';

export default {
    name: "Api Keys",
    components: { 
        Icon,
    },
    props: {chainName: String, icon: String},
    data() {
        return {
            errors: {},
            form: {
                name: null,
            },
            isLoading: false,
            tokens: [],
            hasKeys: false,
            form_processing: false
        };
    },
    mounted() {
      Scrollbar.init(document.querySelector('#api-scroll'));
        this.getTokens();
        

    },
    methods: {
        getTokens() {
            this.isLoading = true;
            getApiKeys()
                .then((response) => {

                    if (response.status == 200) {
                        console.log(response.data.data.hasKeys)
                        this.hasKeys = response.data.data.hasKeys;
                        this.tokens = JSON.parse(response.data.data.apiKeys);
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status == 400) {
                        this.errors = error.response.data.errors;
                        console.log(error.response.data);
                        this.isLoading = false;
                    } else {

                        this.$toast.error("An Error occured: <br> " + error + " <br> please contact support", {
                            // optional options Object
                        });

                    }

                });
        },

        createToken() {

            this.form_processing = true;
            this.errors = {};
            createApiKey(this.form)
                .then((response) => {

                    if (response.status == 200) {
                        this.isSuccess = true;
                        this.$toast.success("Token created successfully", {
                            // optional options Object
                        });
                        this.getTokens();
                        this.form_processing = false;
                    }
                })
                .catch((error) => {

                    if (error.response) {
                        this.errors = error.response.data.data;
                        //console.log(error.response.data);
                        this.form_processing = false;
                    }
                });

        },

        deleteToken(id) {
            deleteApiKey(id)
                .then((response) => {

                    if (response.status == 200) {
                        this.isSuccess = true;
                        this.$toast.warning("Token deleted successfully", {
                            // optional options Object
                        });
                        this.getTokens();
                 
                    }
                })
                .catch((error) => {

                    if (error.response.status == 400) {
                        this.errors = error.response.data.errors;
                        console.log(error.response.data);
                    } else {

                        this.$toast.error("An Error occured: <br> " + error + " <br> please contact support", {
                            // optional options Object
                        });
                        this.form_processing = false;

                    }

                });

        },

    }
};
</script>

<style>
</style>
