<template>
  <div class="dashboard p-4">
    <nav
      class="flex"
      aria-label="Breadcrumb"
    >
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <a
            href="#"
            class="
            inline-flex items-center text-sm font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-white
            "
          >
            <Icon
              :icon="'cryptocurrency:' + icon"
              class="mr-2"
            />

            {{ chainName }}
          </a>
        </li>
      </ol>
    </nav>
    <!-- end nav -->
    <div class="w-full flex mt-5">
      <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
        <Icon
          :icon="'cryptocurrency:' + icon"
          class="mr-2 text-3xl"
        />
        {{ chainName }} Addresses
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="w-full wrapper-card mt-5">
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md">
        <div class="border-b border-gray-200 dark:border-gray-700">
          <ul
            id="myTab"
            class="flex flex-wrap -mb-px text-sm font-medium text-center"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            <li
              class="mr-2 text-gray-800 dark:text-gray-200 dark:text-gray-200"
              role="presentation"
            >
              <button
                id="profile-tab"
                :class="
                  (tabActive == 'new' ? '' : 'border-transparent') +
                    ' inline-block p-4 rounded-t-lg border-b-2  hover:text-gray-800 dark:text-gray-200 dark: text-gray-400 hover:border-gray-300 dark:hover:text-gray-300'
                "
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                @click="tabActive = 'new'"
              >
                Create new address
              </button>
            </li>
            <li
              class="mr-2 text-gray-800 dark:text-gray-200 dark:text-gray-200"
              role="presentation"
            >
              <button
                id="dashboard-tab"
                :class="
                  (tabActive == 'import' ? '' : 'border-transparent') +
                    ' inline-block p-4 rounded-t-lg border-b-2  hover:text-gray-800 dark:text-gray-200 dark: text-gray-400 hover:border-gray-300 dark:hover:text-gray-300'
                "
                type="button"
                role="tab"
                aria-controls="import"
                aria-selected="false"
                @click="tabActive = 'import'"
              >
                Import Keyfile
              </button>
            </li>
          </ul>
        </div>
        <div
          v-if="tabActive == 'new'"
          id="new"
        >
          <div
            id="profile"
            class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
              Create new
            </h2>
            <p class="text-gray-400 font-lexend font-normal">
              Create a new address by entering a password.
              <span class="text-orange-500">
                Please save your password! We cannot encrypt your addresses
                without this password!
              </span>
            </p>

            <form
              class="space-y-6"
              action="#"
              @submit.prevent="createAddress"
            >
              <div class="mt-2">
                <label
                  for="name"
                  :class="
                    errors.password
                      ? 'block mb-2 text-sm font-medium text-red-700 dark:text-red-500'
                      : 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                  "
                >Password</label>
                <input
                  id="address_name"
                  v-model="form.password"
                  type="password"
                  name="address_password"
                  placeholder="••••••••"
                  :class="
                    errors.password
                      ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                      : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                  "
                  required
                >
                <p
                  v-show="errors.password"
                  class="mt-2 text-sm text-red-600 dark:text-red-500"
                >
                  <span class="font-medium"></span>
                  {{ errors.password }}
                </p>
                <div class="mt-3">
                  <button
                    type="submit"
                    class=" button
                      flex
                      text-white
                      bg-gruen
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      mr-2
                      mb-2
                    "
                  >
                    <span
                      v-if="form_processing"
                      class="mr-2 text-xl"
                    >
                      <Icon icon="line-md:loading-twotone-loop" />
                    </span>
                    <span
                      v-else
                      class="mr-2 text-xl"
                    >
                      <Icon icon="akar-icons:plus" />
                    </span>

                    <span> Create address </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          v-if="tabActive == 'import'"
          id="import"
        >
          <div
            id="dashboard"
            class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            role="tabpanel"
            aria-labelledby="dashboard-tab"
          >
            <form
              class="space-y-6"
              action="#"
              @submit.prevent="onUpload"
            >
              <div
                id="profile"
                class="mt-2 bg-gray-50 rounded-lg dark:bg-gray-800"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <label
                  class="
                        block
                        mb-2
                        text-sm
                        font-medium
                        text-gray-900
                        dark:text-gray-300
                      "
                  for="multiple_files"
                >Upload multiple files</label>
                <input
                  id="multiple_files"
                  class="
                        block
                        w-full
                        text-sm text-gray-900
                        bg-gray-50
                        rounded-lg
                        border border-gray-300
                        cursor-pointer
                        dark:text-gray-400
                        focus:outline-none
                        dark:bg-gray-700
                        dark:border-gray-600
                        dark:placeholder-gray-400
                      "
                  type="file"
                  multiple
                  @change="changeKeyFiles"
                >
              </div>
              <div class="mt-2">
                <label
                  for="name"
                  :class="
                    errors.password
                      ? 'block mb-2 text-sm font-medium text-red-700 dark:text-red-500'
                      : 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                  "
                >Password</label>
                <input
                  id="address_password"
                  v-model="importForm.password"
                  type="password"
                  name="address_password"
                  placeholder="••••••••"
                  :class="
                    errors.password
                      ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                      : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                  "
                  required
                >
                <p
                  v-show="errors.password"
                  class="mt-2 text-sm text-red-600 dark:text-red-500"
                >
                  <span class="font-medium"></span>
                  {{ errors.password }}
                </p>
                
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      button
                        flex
                        text-white
                        bg-gruen
                        font-medium
                        rounded-lg
                        text-sm
                        px-5
                        py-2.5
                        mr-2
                        mb-2
                      "
                  >
                    <span
                      v-if="form_processing"
                      class="mr-2 text-xl"
                    >
                      <Icon icon="line-md:loading-twotone-loop" />
                    </span>
                    <span
                      v-else
                      class="mr-2 text-xl"
                    >
                      <Icon icon="akar-icons:plus" />
                    </span>

                    <span> Import addresses </span>
                  </button>
                  <p
                    class="mt-2 text-sm text-red-600 dark:text-red-500"
                    :v-if="error_import.length >0 "
                  >
                    <span
                      v-for="err in error_import"
                      :key="err"
                      class="font-medium"
                    >{{ err }}</span>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-2  mt-5">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
        Addresses
      </h2>
      <p class="text-gray-400 font-lexend font-normal">
        A list with your existing addresses
      </p>
      <div
        v-if="isLoading"
        class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mx-auto mt-5"
      >
        <span class="mr-2 text-xl mx-auto text-6xl">
          <Icon
            icon="line-md:loading-twotone-loop"
            class="mx-auto"
          />
        </span>
      </div>

      <div
        v-show="addresses.length == 0 && !isLoading"
        class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200"
      >
        <Icon
          icon="akar-icons:face-sad"
          class="text-center mx-auto text-5xl"
        />
        <p class="text-xl text-center">
          You have no addresses yet. Please add one
        </p>
      </div>

      <div
        v-show="addresses.length > 0 && !isLoading"
        class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mt-5"
      >
        <table 
          id="address-table"
          class="
          table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll
          "
        >
          <thead
            class="
              text-xs text-gray-700
              uppercase
              bg-gray-50
              dark:bg-gray-700 dark:text-gray-400
            "
          >
            <tr>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Address
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Balance
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="address in addresses"
              :key="address"
              class="
                bg-white
                border-b
                dark:bg-gray-800 dark:border-gray-700
                odd:bg-white
                even:bg-gray-50
              "
            >
              <td class="px-6 py-4">
                {{ address }}
              </td>
              <td
                :id="address"
                class=" px-6 py-4 text-center"
              >
                <button
                  type="button"
                  class="
                  button
                  flex  content-center
                      m-auto
                      text-white
                      bg-blue-700
                      hover:bg-blue-800
                      focus:ring-2 focus:ring-blue-800
                      font-medium
                      rounded-lg
                      text-sm
                      px-2.5
                      py-2.5
                      dark:hover:bg-blue-800
                      focus:outline-none
                      dark:focus:ring-blue-800
                    "
                  @click="getBalance(address)"
                >
                  <Icon class="inline-block" :inline="true"  icon="akar-icons:eye" v-if="(balance[address] === undefined)" />
                  <span class="inline-block ml-1" v-if="(balance[address] !== undefined)">  <Icon class="inline-block" :inline="true"  icon="ic:baseline-update" /> {{ balance[address] }} </span>
                </button>
              </td>
              <td class="px-6 py-4 text-center">
                <div class="flex">
                  <form
                    action="#"
                    @submit.prevent="popupDelete(address)"
                  >
                    <button
                      type="submit"
                      class="
                      bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800
                        m-auto
                        text-white
                        bg-red-700
                        font-medium
                        rounded-lg
                        text-sm
                        px-2.5
                        py-2.5
                        text-center
                      "
                    >
                      <Icon icon="bx:trash" />
                    </button>
                  </form>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <!-- Main modal -->
  <div
    id="password-modal"
    tabindex="-1"
    aria-hidden="true"
    :class="
      (showModal ? '' : 'hidden') +
        ' fixed inset-0 bg-gray-900 top-100 bg-opacity-70 overflow-y-auto h-full w-full'
    "
  >
    <div class="relative mx-auto p-4 w-full  h-full md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button
          type="button"
          class="
            absolute
            top-3
            right-2.5
            text-gray-400
            bg-transparent
            hover:bg-gray-200 hover:text-gray-900
            rounded-lg
            text-sm
            p-1.5
            ml-auto
            inline-flex
            items-center
            dark:hover:bg-gray-800 dark:hover:text-white
          "
          data-modal-toggle="authentication-modal"
          @click="closePopupDelete()"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="py-6 px-6 lg:px-8">
          <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
            Are you sure?
          </h3>
          <form
            class="space-y-6"
            action="#"
            @submit.prevent="deleteAddress()"
          >
            <div>
              <p class="text-white">
                This process can take up to several minutes
              </p>
            </div>
            <div>
              <input
                
                v-model="modal_form.password"
                type="password"
                name="address_password"
                placeholder="••••••••"
                class="
                  bg-gray-50
                  border border-gray-300
                  text-gray-900 text-sm
                  rounded-lg
                  focus:ring-blue-500 focus:border-blue-500
                  block
                  w-full
                  p-2.5
                  dark:bg-gray-600
                  dark:border-gray-500
                  dark:placeholder-gray-400
                  dark:text-white
                "
                required
              >
              <p
                class="text-red-500 mt-3"
                :v-if="modal_form.error.length > 0"
              >
                {{ modal_form.error }}
              </p>
            </div>

            <button
              type="submit"
              class="
                w-full
                text-white
                bg-red-700
                hover:bg-red-800
                focus:ring-4 focus:outline-none focus:ring-red-300
                font-medium
                rounded-lg
                text-sm
                px-5
                py-2.5
                text-center
                dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800
              "
            >
              Delete now
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue";
import { useDropzone } from "vue3-dropzone";
import { getAddresses, createAddress, importAddress, deleteAddress, getBalance } from "../api/addresses.api";
import Scrollbar from "smooth-scrollbar";

export default {
  name: "Addresses",
  components: {
    Icon,
  },
  props: {chainName: String, icon: String},

  data() {
    return {
      errors: {},
      form: {
        password: null,
      },
      isLoading: false,
      addresses: [],
      hasKeys: false,
      form_processing: false,
      modal_form: {
        password: null,
        error: "",
      },
      showModal: false,
      tabActive: "new",
      importForm: {
        keyFileArray: null,
        password: null,
      },
      contents:[],
      error_import:[],
      balance:{}
    };
  },
  created() {
    this.$watch(
      () => this.chainName,
      (toParams, previousParams) => {
        this.addresses=[];
        this.getAddresses(toParams);
      }
    )
  },
  mounted() {
    Scrollbar.init(document.querySelector("#address-table"));
    this.getAddresses();
   
  },
  methods: {
    changeKeyFiles(event) {
        const reader = new FileReader();
        this.contents = [];
        self = this;
        reader.onload = function(e) {
            self.contents.push(e.target.result);
        };
        this.importForm.keyFileArray = [];

        for (const [key, file] of Object.entries(event.target.files)) {
            reader.readAsText(file);
            this.importForm.keyFileArray.push({"name":file.name})
        };

    },
    onUpload() {
      this.error_import=[];
      for (const i of Object.keys(this.importForm.keyFileArray)) {
        
      importAddress(this.chainName.toLowerCase(),
        {
          "filename": this.importForm.keyFileArray[i].name,
          "content": JSON.parse(this.contents[i]),
          "password": this.importForm.password
        }
      ).then((response) => {
          this.form_processing = false;
          this.$toast.success("address imported successfully", {
          // optional options Object
        });
      }).catch(error => {
        if (error.response.status == 422) {
            this.errors.password = error.response.data.message;
            this.form_processing = false;
          }
      });
      }
      this.importForm = {
        keyFileArray: null,
        password: null
      },
      this.contents = []
      this.getAddresses();
    },

    popupDelete(add) {
      this.popup_address = add;
      this.showModal = true;
      this.modal_form.error = "";
    },
    closePopupDelete() {
      this.popup_address = "";
      this.showModal = false;
    },
    getBalance(address) {
      delete this.balance[address]
      getBalance(this.chainName.toLowerCase(), address)
      .then((response) => {
        this.balance[address] = JSON.parse(response.data.data.balance);
      });
    },

    getAddresses(chainName) {
      let params = this.chainName.toLowerCase();
      if(chainName){
        params = chainName;
      }
      this.isLoading = true;
      getAddresses(params)
      .then((response) => {
        if (response.data.data.addresses.length > 0) {
          this.addresses = response.data.data.addresses;
              
              this.hasKeys = true;
            } else {
              this.hasKeys = false;
            }
      });
      this.isLoading = false;
      
    },

    createAddress() {
      this.form_processing = true;
      this.errors = {};
      createAddress(this.chainName.toLowerCase(), this.form)
      .then((response) => {
        this.isSuccess = true;
        this.$toast.success("address created successfully", {
          // optional options Object
        });
        this.getAddresses();
        this.form_processing = false;
          
      }).catch(error => {
        if (error.response.status == 400) {
            this.errors = error.response.data.data;
            this.form_processing = false;
          }
      });
      this.isLoading = false;
    },

    deleteAddress() {
      deleteAddress(this.chainName.toLowerCase(), this.popup_address,
          { data: this.modal_form }
        )
        .then((response) => {
          if (response.status == 200) {
            this.isSuccess = true;
            this.$toast.warning("Address deleted successfully", {
              // optional options Object
            });
            this.getAddresses();
            this.showModal = false;
          }
        });
    },
  },
};
</script>

<style>
</style>