<template>
  <div>
    <div class="dashboard p-4">
      <nav
        class="flex"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <a
              href="#"
              class="
                inline-flex
                items-center
                text-sm
                font-medium
                text-gray-700
                hover:text-gray-900
                dark:text-gray-400 dark:hover:text-white
              "
            >
              <Icon icon="bx:transfer" /> Request log
            </a>
          </li>
        </ol>
      </nav>
      <!-- end nav -->
      <div class="mt-5 w-full">
        <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
          <Icon icon="bx:transfer" /> Request log
        </h1>
      </div>
      <!-- grid wrapper card -->
      <div class="wrapper-card grid gap-2 mt-5">
        <!--
          <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5">
            <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
              Create new IPN
            </h2>
            <p class="text-gray-400 font-lexend font-normal">
              Please fill at least one of the fields "From Address", "To Address",
              "Contract" or "Token". To limit the ammount of Notifications, we
              recommend you to fill at least two of this fields.
            </p>
  
          
          </div>
          -->

        <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5">
          <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
            requests
          </h2>
          <p class="text-gray-400 font-lexend font-normal">
            A list with your request history
          </p>
          

          <div
            class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mt-5"
          >
            <div class="flex flex-wrap gap-2">
              <div
                class="
                flex grow
                input-box mb-5
                border
                dark:bg-gray-900 dark:border-gray-700
                rounded-md
                box-border
                lg:flex
                md:flex
                focus-within:bg-gray-100
                dark:focus-within:bg-gray-700
              "
              >
                <span class="text-3xl p-2 text-gray-400"><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 50 50"
                  class="iconify iconify--ei"
                >
                  <path
                    fill="currentColor"
                    d="M23 36c-7.2 0-13-5.8-13-13s5.8-13 13-13s13 5.8 13 13s-5.8 13-13 13zm0-24c-6.1 0-11 4.9-11 11s4.9 11 11 11s11-4.9 11-11s-4.9-11-11-11z"
                  />
                  <path
                    fill="currentColor"
                    d="m32.682 31.267l8.98 8.98l-1.414 1.414l-8.98-8.98z"
                  /></svg></span><input
                  v-model="search"
                  v-debounce:300="delayedSearch"
                  type="text"
                  placeholder="Search..."
                  class="
                  p-3
                  w-full
                  bg-white
                  dark:bg-gray-900
                  rounded-md
                  outline-none
                  focus:bg-gray-100
                  dark:focus:bg-gray-700
                "
                >
              </div>
            </div>
          
            <div class="flex lg:flex-nowrap flex-wrap gap-2">
              <div
                class="
                  flex-col
                  lg:w-1/2
                  md:w-1/2
                  h-96
                  w-full
                  overflow-auto
                  pr-2
                  items-center
                "
              >
                <div
                  v-if="isLoading"
                  class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mx-auto mt-5"
                >
                  <span class="mr-2 text-xl mx-auto text-6xl">
                    <Icon
                      icon="line-md:loading-twotone-loop"
                      class="mx-auto"
                    />
                  </span>
                </div>

                <div
                  v-show="requests.length == 0 && !isLoading"
                  class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200"
                >
                  <Icon
                    icon="akar-icons:face-sad"
                    class="text-center mx-auto text-5xl"
                  />
                  <p class="text-xl text-center">
                    No results were found. Try other search criterias or wait for new IPN's
                  </p>
                </div>

                <div
                  v-for="request in requests"
                  id="list"
                  :key="request"
                  :class="
                    'flex flex-nowrap items-center w-full p-2 items-center align-middle items-center bg-gray-200 min-h-12 mx-auto cursor-pointer hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 border-l-8 rounded mb-2 ' +
                      border(request.httpStatus)
                  "
                  @click="selectedRequest = request"
                >
                  <div class="shrink items-center mr-2">
                    <span
                      v-if="request.blockchain == 'ethereum'"
                      class="text-3xl"
                    ><Icon icon="cryptocurrency:eth" /></span>
                    <span
                      v-else-if="request.blockchain == 'tron'"
                      class="text-3xl"
                    ><Icon icon="cryptocurrency:trx" /></span>
                    <span
                      v-else-if="request.blockchain == 'bsc'"
                      class="text-3xl"
                    ><Icon icon="cryptocurrency:bnb" /></span>
                    <span
                      v-else
                      class="text-3xl"
                    ><Icon icon="bx:transfer" /></span>
                  </div>
                  <div class="flex-1 items-center">
                    <span class="truncate ...">{{ request.function.substring(0,30) }}<span v-if="request.function.length > 30">...</span></span>
                  </div>

                  <div class="flex-1 items-center  text-center">
                    <span class="bg-gray-500 rounded-md text-xs py-1 px-4 text-white">{{ moment(request.createdAt).format("YYYY-MM-DD HH:mm:ss") }} </span>
                  </div>

                  <div class="shrink items-center">
                    <span :class="bg(request.httpStatus) +'rounded-md text-xs py-1 px-4 text-white'">{{ request.httpStatus }}</span>
                  </div>
                </div>
                <button
                  v-if="!isLoading && requests.length > 0"
                  type="button"
                  :disabled="page == totalPages && page < totalPages"
                  class="justify-center content-center items-center flex py-2.5 px-5 mr-2 my-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 w-full text-center"
                  @click="getRequests()"
                > 
                  <span
                    v-if="buttonText == 'Loading'"
                    class="text-2xl mr-2"
                  ><Icon icon="line-md:loading-twotone-loop" /></span> <span
                    v-else
                    class="text-xl"
                  >{{ buttonText }}</span>
                </button>
              </div>
             

              <div
                v-if="selectedRequest"
                class="
                  flex-col flex-grow
                  min-h-56
                  w-1/2
                  overflow-x-scroll
                  pr-2
                  p-2
                  bg-gray-200
                  dark:bg-gray-700
                  rounded
                "
              >
                <div class="grid grid-cols-1 divide-y gap-5">
                  <div>
                    <h2>Route</h2>
                    <pre>{{ selectedRequest.function }}</pre>
                  </div>
              
                  <div><h2>Body</h2><pre class="overflow">{{ filterElement(selectedRequest) }}</pre></div>
                  <div>
                    <h2>Response content</h2>
                    {{ selectedRequest.responseJson }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import { Icon } from "@iconify/vue";
import { getRequests } from "../api/requests.api";
import { vue3Debounce } from 'vue-debounce'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';


export default {
  name: "Request Log",
  components: { Icon },
  directives: {
    debounce: vue3Debounce({ lock: true })
  },
  components: {
    Icon,
  },
  data() {
    return {
      selectedRequest: null,
      page:1,
      nextPage:1,
      limit:50,
      search: "",
      totalPages:1,
      requests: [],
      noResult: false,
      isLoading: true,
      onlyFailed: this.$route.hash=='#failed'?true:false
    };
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    filterElement(obj){
      let element = {
        ...obj
      }
      if(element != null){
        delete element.responseJson;
        delete element.id;
        delete element.blockchain;
        delete element.function;
        delete element.httpStatus;
        delete element.createdAt;
        Object.keys(element).forEach(key => {
          if (element[key] === null) {
            delete element[key];
          }
        });
        return element;
      }
      return null;
      
    },
    
    border(code) {

      if (code < 400 ) {
        return "border-green-600 ";
      }
      return "border-red-600 ";
    },
    bg(code) {

      if (code < 400 ) {
        return "bg-green-600 ";
      }
      return "bg-red-600 ";
    },
    delayedSearch(){
      this.page=1;
      this.nextPage = 1;
      this.totalPages = 1;
      this.requests = [];
      console.log("search");
      this.getRequests();
    },
    async getRequests() {

      this.buttonText = 'Loading';
      this.isLoading = true;
      this.requests = [];
        try {
        getRequests(this.nextPage, this.limit, this.search).then(
            (response) => {
              if (response.status == 200) {
                if (response.data.data.requests.length > 0) {
                  console.log(typeof response.data.data.requests);
                  this.requests.push(...JSON.parse(response.data.data.requests));
                  this.totalPages = response.data.data.total_pages;
                  this.page = response.data.data.current_page;
                  this.nextPage = response.data.data.current_page+1;
                  this.buttonText = "Load more";
                  if(this.page == this.totalPages){
                    this.buttonText = "No more Data";
                  }
                  
                  
                }
               else {
                  this.buttonText = "No result found";
                }
                this.isLoading = false;
              }
            }
          );
      } catch(err) {
        this.buttonText = "Error loading data";
      }

      
      
    }
  },
};
</script>
    
    <style>
</style>