<template>
  <div>


      <!-- end nav -->
      <div class="mt-5 w-full">
        <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
          <Icon
            icon="bxs:shopping-bag"
            class="mr-2 text-3xl"
          />
          Renew Subscription
        </h1>
      </div>
      
      <!-- grid wrapper card -->
      <div
        v-if="subscription"
        class="grid grid-cols-1 md:grid-cols-2 mt-5 gap-2"
      >
        <div
          v-if="subscription.provider != 'forumpay'"
          class="col card bg-white dark:bg-gray-800  rounded-md p-5 text-gray-800 dark:text-gray-200 dark:text-gray-200"
        >
          <Icon
            icon="bxs:error"
            class="text-center mx-auto text-5xl"
          />
          <p class="text-xl text-center">
            Only memberships payed by Forumpay provider need manual renewal. Free and stripe memberships are recurred automatically
          </p>
        </div>
        <div
          v-else
          class="col card bg-white dark:bg-gray-800  rounded-md p-5 text-gray-800 dark:text-gray-200 dark:text-gray-200"
        >

        <div v-if="!subscription.yearly">

        <h2 class="font-bold text-lg "> Extend Membership</h2>
        <label for="steps-range" class="mt-5 block mb-2 text-md font-medium text-gray-900 dark:text-white">
          Number of months to extend:  {{ months  }}</label>
<input id="steps-range" v-model="months" type="range" min="0" max="11"  step="1" class="w-full h-2 bg-lila rounded-lg appearance-none cursor-pointer ">
          <h2 class="font-bold text-lg ">
            Membership Information
          </h2>

        </div>

          

          <div class="flex lg:w-3/4 justify-start py-2 my-2">
            <div class="flex-grow font-medium px-2">
              Current Payment interval:
            </div>
            <div class="text-sm font-normal text-gray-300 tracking-wide">
              {{ moment(subscription.current_period_start).format("YYYY-MM-DD") }} - {{ moment(subscription.current_period_end).format("YYYY-MM-DD") }}
            </div>
          </div>  
          <div class="flex lg:w-3/4 justify-start py-2 my-2">
            <div class="flex-grow font-medium px-2">
              New Payment interval:
            </div>
            <div class="text-sm font-normal text-gray-300 tracking-wide">
              {{ moment(subscription.current_period_end).format("YYYY-MM-DD") }} -  {{ moment(newEnd(subscription.current_period_end)).format("YYYY-MM-DD") }}
            </div>
          </div>
          <div class="flex lg:w-3/4 justify-start py-2 my-2">
            <div class="flex-grow font-medium px-2">
              Base Price:
            </div>
            <div v-if="!subscription.upcomingPlan" class="text-sm font-normal text-gray-300 tracking-wide">
              <span v-if="subscription.yearly">€{{ subscription.plan.plan_pricing_yearly }} /year</span>
              <span v-else>€{{ subscription.plan.plan_pricing*parseInt(months)  }} /month</span>
            </div>
            <div v-else class="text-sm font-normal text-gray-300 tracking-wide">
              <span v-if="subscription.yearly">€{{ subscription.upcomingPlan.plan_pricing_yearly }} /year</span>
              <span v-else>€{{ subscription.upcomingPlan.plan_pricing*parseInt(months)  }} /month</span>
            </div>
          </div>
          <div class="flex lg:w-3/4 justify-start py-2 my-2">
            <div class="flex-grow font-medium px-2">
              Addons Price:
            </div>
            <div  class="text-sm font-normal text-gray-300 tracking-wide">
              <span >€ {{ subscription.addonValue*parseInt(months) }} </span>
            </div>
          </div>
          <div class="flex lg:w-3/4 justify-start py-2 my-2">
            <div class="flex-grow font-medium px-2">
              Activated Coupons:
            </div>
            <div  class="text-sm font-normal text-gray-300 tracking-wide">
              <span >€ -{{ subscription.couponValue }} </span>
            </div>
          </div>

          <div class="flex lg:w-3/4 justify-start py-2 my-2">
            <div class="flex-grow font-medium px-2">
              <b>Total</b>
            </div>
            <div  class="text-sm font-normal text-gray-300 tracking-wide">
              <span >€ {{ subscription.total*parseInt(months) }} </span>
            </div>
          </div>
            
          <div class="my-4 mt-5 flex text-center gap-2">
                    <button @click="renew()" class=" flex-1 mx-auto text-white bg-[#FF9119] mx-auto hover:bg-[#FF9119]/80 focus:ring-4 focus:ring-[#FF9119]/50 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 mr-2 mb-2 disabled:opacity-50 hover:disabled:bg-[#FF9119] dark:hover:disabled:bg-[#FF9119]"
                    :disabled="months<1">
                        <Icon icon="bx:bitcoin" class="mr-2" />
                        Pay with Crypto
                    </button>
                </div>
                  <div class="flex items-center">
                <p>Crypto payment accepts: </p>

                    <Icon icon="cryptocurrency:usdt" class="ml-2 mr-2 text-xl" />
                    <Icon icon="cryptocurrency:trx" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:btc" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:eth" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:ltc" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:bch" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:usdc" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:dash" class="mr-2 text-xl" />
                </div>
          *You will be redirected to our payment provider. Once your payment is completed, we will renew your Subscription.
        </div>

        <div class="col card rounded-md order-first md:order-last mb-5">
          <pricing-table
            v-if="subscription"
            class="w-full"
            :yearly="subscription.yearly"
            :plan="subscription.upcomingPlan != null?subscription.upcomingPlan:subscription.plan"
            hidebutton="true"
          />
        </div>
      </div>
    </div>

</template>

<script>

import { Icon } from "@iconify/vue";
import PricingTable from "../components/PricingTable.vue";
import { getSubscription, renewSubscription } from '../api/subscriptions.api'

export default {
  name: "Renew Membership",
  components:{
    Icon,
    PricingTable
  },
  data() {
    return {
        subscription: null,
        months: 1
    }
  },
  mounted(){
    console.log("mounted")
    this.getSubscription();
  },
  methods:{
    dt(dt){
      return new Date(dt);
    },
    newEnd(end){
      end = new Date(end);
      console.log(end.getMonth()+this.months);

      //return new Date(this.dt(end).setMonth(m+this.months));
      return new Date(end.setMonth(parseInt(end.getMonth())+parseInt(this.months)));
    },
    getSubscription() {
      getSubscription()
        .then((response) => {
          if (response.status == 200) {
            if (response.data != null) {
              this.subscription = response.data;
            }
          }
        }).catch((error) => {
            if (error.response.status == 400) {
              this.errors = error.response.data.errors;
              this.isLoading = false;
            } else {
              this.$toast.error(

                  error.response.data.message ,

                {
                  // optional options Object
                }
              );
              }
        });
    },
    renew(){
      renewSubscription(parseInt(this.months))
          .then((response) => {
            if (response.status == 200) {
               window.location.href = response.data.data.url;
            }
          })
          .catch((error) => {
            if (error.response.status == 400) {
              console.log(this.errors = error.response);
              this.isLoading = false;
            } else {
              this.$toast.error(

                  error.response.data.message ,

                {
                  // optional options Object
                }
              );
            }
          });
    },
  }
  
  }

</script>

<style>

</style>