<template>
  <div class="dashboard p-4">
    <nav
      class="flex"
      aria-label="Breadcrumb"
    >
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <a
            href="#"
            class="
            inline-flex items-center text-sm font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-white
            "
          >
            <Icon
              icon="cryptocurrency:btc"
              class="mr-2"
            />
            Bitcoin
          </a>
        </li>
      </ol>
    </nav>
    <!-- end nav -->
    <div class="w-full flex mt-5">
      <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
        <Icon
          icon="cryptocurrency:btc"
          class="mr-2 text-3xl"
        />
        Bitcoin Wallets
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="w-full wrapper-card mt-5">
      <div class="card bg-white dark:bg-gray-800 w-full rounded-md">
        <!--<div class="border-b border-gray-200 dark:border-gray-700">
         <ul
            id="myTab"
            class="flex flex-wrap -mb-px text-sm font-medium text-center"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            <li
              class="mr-2 text-gray-800 dark:text-gray-200 dark:text-gray-200"
              role="presentation"
            >
              <button
                id="profile-tab"
                :class="
                  (tabActive == 'new' ? '' : 'border-transparent') +
                    ' inline-block p-4 rounded-t-lg border-b-2  hover:text-gray-800 dark:text-gray-200 dark: text-gray-400 hover:border-gray-300 dark:hover:text-gray-300'
                "
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                @click="tabActive = 'new'"
              >
                Create new wallet
              </button>
            </li>
            li
              class="mr-2 text-gray-800 dark:text-gray-200 dark:text-gray-200"
              role="presentation"
            >
              <button
                id="dashboard-tab"
                :class="
                  (tabActive == 'import' ? '' : 'border-transparent') +
                    ' inline-block p-4 rounded-t-lg border-b-2  hover:text-gray-800 dark:text-gray-200 dark: text-gray-400 hover:border-gray-300 dark:hover:text-gray-300'
                "
                type="button"
                role="tab"
                aria-controls="import"
                aria-selected="false"
                @click="tabActive = 'import'"
              >
                Import Keyfile
              </button>
            </li>
          </ul>
        </div>-->
        <div
          v-if="tabActive == 'new'"
          id="new"
        >
          <div
            id="profile"
            class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
              Create new
            </h2>
            <p class="text-gray-400 font-lexend font-normal">
              Create a new wallet by entering a password.
              <span class="text-orange-500">
                Please save your password! We cannot encrypt your wallets
                without this password!
              </span>
            </p>

            <form
              class="space-y-6"
              action="#"
              @submit.prevent="createWallet"
            >
              <div class="mt-2">
                <label
                  for="name"
                  :class="
                    errors.password
                      ? 'block mb-2 text-sm font-medium text-red-700 dark:text-red-500'
                      : 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                  "
                >Password</label>
                <input
                  id="wallet_name"
                  v-model="form.password"
                  type="password"
                  name="wallet_password"
                  placeholder="••••••••"
                  :class="
                    errors.password
                      ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                      : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                  "
                  required
                >
                <p
                  v-show="errors.password"
                  class="mt-2 text-sm text-red-600 dark:text-red-500"
                >
                  <span class="font-medium"></span>
                  {{ errors.password }}
                </p>
                <div class="mt-3">
                  <button
                    type="submit"
                    class=" button
                      flex
                      text-white
                      bg-gruen
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      mr-2
                      mb-2
                    "
                  >
                    <span
                      v-if="form_processing"
                      class="mr-2 text-xl"
                    >
                      <Icon icon="line-md:loading-twotone-loop" />
                    </span>
                    <span
                      v-else
                      class="mr-2 text-xl"
                    >
                      <Icon icon="akar-icons:plus" />
                    </span>

                    <span>Create wallet </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          v-if="tabActive == 'import'"
          id="import"
        >
          <div
            id="dashboard"
            class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
            role="tabpanel"
            aria-labelledby="dashboard-tab"
          >
            <form
              class="space-y-6"
              action="#"
              @submit.prevent="onUpload"
            >
              <div
                id="profile"
                class="mt-2 bg-gray-50 rounded-lg dark:bg-gray-800"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <label
                  class="
                        block
                        mb-2
                        text-sm
                        font-medium
                        text-gray-900
                        dark:text-gray-300
                      "
                  for="multiple_files"
                >Upload multiple files</label>
                <input
                  id="multiple_files"
                  class="
                        block
                        w-full
                        text-sm text-gray-900
                        bg-gray-50
                        rounded-lg
                        border border-gray-300
                        cursor-pointer
                        dark:text-gray-400
                        focus:outline-none
                        dark:bg-gray-700
                        dark:border-gray-600
                        dark:placeholder-gray-400
                      "
                  type="file"
                  multiple
                  @change="changeKeyFiles"
                >
              </div>
              <div class="mt-2">
                <label
                  for="name"
                  :class="
                    errors.password
                      ? 'block mb-2 text-sm font-medium text-red-700 dark:text-red-500'
                      : 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                  "
                >Password</label>
                <input
                  id="wallet_password"
                  v-model="importForm.password"
                  type="password"
                  name="wallet_password"
                  placeholder="••••••••"
                  :class="
                    errors.password
                      ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                      : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                  "
                  required
                >
                <p
                  v-show="errors.password"
                  class="mt-2 text-sm text-red-600 dark:text-red-500"
                >
                  <span class="font-medium"></span>
                  {{ errors.password }}
                </p>
                
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      button
                        flex
                        text-white
                        bg-gruen
                        font-medium
                        rounded-lg
                        text-sm
                        px-5
                        py-2.5
                        mr-2
                        mb-2
                      "
                  >
                    <span
                      v-if="form_processing"
                      class="mr-2 text-xl"
                    >
                      <Icon icon="line-md:loading-twotone-loop" />
                    </span>
                    <span
                      v-else
                      class="mr-2 text-xl"
                    >
                      <Icon icon="akar-icons:plus" />
                    </span>

                    <span> Import wallets </span>
                  </button>
                  <p
                    class="mt-2 text-sm text-red-600 dark:text-red-500"
                    :v-if="error_import.length >0 "
                  >
                    <span
                      v-for="err in error_import"
                      :key="err"
                      class="font-medium"
                    >{{ err }}</span>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-2  mt-5">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
        Wallets
      </h2>
      <p class="text-gray-400 font-lexend font-normal">
        A list with your existing wallets
      </p>
      <div
        v-if="isLoading"
        class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mx-auto mt-5"
      >
        <span class="mr-2 text-xl mx-auto text-6xl">
          <Icon
            icon="line-md:loading-twotone-loop"
            class="mx-auto"
          />
        </span>
      </div>

      <div
        v-show="wallets.length == 0 && !isLoading"
        class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200"
      >
        <Icon
          icon="akar-icons:face-sad"
          class="text-center mx-auto text-5xl"
        />
        <p class="text-xl text-center">
          You have no wallets yet. Please add one
        </p>
      </div>

      <div
        v-show="wallets.length > 0 && !isLoading"
        class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mt-5"
      >
        <table 
          id="wallet-table"
          class="
          table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll
          "
        >
          <thead
            class="
              text-xs text-gray-700
              uppercase
              bg-gray-50
              dark:bg-gray-700 dark:text-gray-400
            "
          >
            <tr>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Wallet
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Balance
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="wallet in wallets"
              :key="wallet"
              class="
                bg-white
                border-b
                dark:bg-gray-800 dark:border-gray-700
                odd:bg-white
                even:bg-gray-50
              "
            >
              <td class="px-6 py-4">
                {{ wallet }}
              </td>
              <td
                :id="wallet"
                class=" px-6 py-4 text-center"
              >
                <button
                  type="button"
                  class="
                  button
                  flex  content-center
                      m-auto
                      text-white
                      bg-blue-700
                      hover:bg-blue-800
                      focus:ring-2 focus:ring-blue-800
                      font-medium
                      rounded-lg
                      text-sm
                      px-2.5
                      py-2.5
                      dark:hover:bg-blue-800
                      focus:outline-none
                      dark:focus:ring-blue-800
                    "
                  @click="getBalance(wallet)"
                >
                  <Icon class="inline-block" :inline="true"  icon="akar-icons:eye" v-if="(balance[wallet] === undefined)" />
                  <span class="inline-block ml-1" v-if="(balance[wallet] !== undefined)">  <Icon class="inline-block" :inline="true"  icon="ic:baseline-update" /> {{ balance[wallet] }} </span>
                </button>
              </td>
              <td class="px-6 py-4 text-center">
                
                  <button class="text-2xl" @click="(menu[wallet] = true)" @blur="(menu[wallet] = false)" data-dropdown-placement="left-start">
                    <div class="user-avatar content-center items-center hover:bg-gray-100 dark:hover:bg-gray-700 p-1 cursor-pointer rounded-md">
                        <span class="text-md mt-4 text-gray-300">
                            <Icon icon="mi:options-horizontal" /></span>
                    </div>
                  </button>
                  <transition name="fade" class="z-50">
                      <div v-show="menu[wallet] != null && menu[wallet]" id="dropdownSmall" class="block absolute  mt-0 z-10 w-44 -translate-x-[110px] border dark:border-gray-700 bg-white dark:bg-gray-800 rounded divide-y dark:divide-gray-700 divide-gray-100 shadow">
                          <ul class="py-1 z-50 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSmallButton">
                              <li class="">
                                  <button class="w-full flex hover:bg-primary hover:text-white justify-start items-left gap-2 p-2" @click="createAddress(wallet)">
                                      <Icon icon="material-symbols:format-list-bulleted-add-rounded" class="text-lg " /> <span>New address</span>
                                  </button>
                              </li>
                              <li class="">
                                  <button class="w-full flex hover:bg-primary hover:text-white justify-start items-left gap-2 p-2" @click="getAddresses(wallet)">
                                      <Icon icon="material-symbols:format-list-bulleted-rounded" class="text-lg " /> <span>Show addresses</span>
                                  </button>
                              </li>
                              <li class="">
                                  <button class="w-full text-red-500 hover:text-white flex hover:bg-red-500 hover:text-white justify-start items-left gap-2 p-2" @click="popupDelete(wallet)">
                                      <Icon icon="bx:trash" class="text-lg " /> <span>Delete</span>
                                  </button>
                              </li>
                          </ul>
                      </div>
                  </transition>

              
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <!-- Password modal -->
  <div
    id="password-modal"
    tabindex="-1"
    aria-hidden="true"
    :class="
      (showModal ? '' : 'hidden') +
        ' fixed inset-0 bg-gray-900 top-100 bg-opacity-70 overflow-y-auto h-full w-full'
    "
  >
    <div class="relative mx-auto p-4 w-full  h-full md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button
          type="button"
          class="
            absolute
            top-3
            right-2.5
            text-gray-400
            bg-transparent
            hover:bg-gray-200 hover:text-gray-900
            rounded-lg
            text-sm
            p-1.5
            ml-auto
            inline-flex
            items-center
            dark:hover:bg-gray-800 dark:hover:text-white
          "
          data-modal-toggle="authentication-modal"
          @click="closePopupDelete()"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="py-6 px-6 lg:px-8">
          <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
            Are you sure?
          </h3>
          <form
            class="space-y-6"
            action="#"
            @submit.prevent="deleteWallet()"
          >
            <div>
              <p class="text-white">
                This process can take up to several minutes
              </p>
            </div>
            <div>
              <input
                
                v-model="modal_form.password"
                type="password"
                name="wallet_password"
                placeholder="••••••••"
                class="
                  bg-gray-50
                  border border-gray-300
                  text-gray-900 text-sm
                  rounded-lg
                  focus:ring-blue-500 focus:border-blue-500
                  block
                  w-full
                  p-2.5
                  dark:bg-gray-600
                  dark:border-gray-500
                  dark:placeholder-gray-400
                  dark:text-white
                "
                required
              >
              <p
                class="text-red-500 mt-3"
                :v-if="modal_form.error.length > 0"
              >
                {{ modal_form.error }}
              </p>
            </div>

            <button
              type="submit"
              class="
                w-full
                text-white
                bg-red-700
                hover:bg-red-800
                focus:ring-4 focus:outline-none focus:ring-red-300
                font-medium
                rounded-lg
                text-sm
                px-5
                py-2.5
                text-center
                dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800
              "
            >
              Delete now
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
    <!-- List Addresses modal -->
  <div
    id="password-modal"
    tabindex="-1"
    aria-hidden="true"
    :class="
      (showAddressModal ? '' : 'hidden') +
        ' fixed inset-0 bg-gray-900 top-100 bg-opacity-70 overflow-y-auto h-full w-full'
    "
  >
    <div class="relative mx-auto p-4 w-full  h-full md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button
          type="button"
          class="
            absolute
            top-3
            right-2.5
            text-gray-400
            bg-transparent
            hover:bg-gray-200 hover:text-gray-900
            rounded-lg
            text-sm
            p-1.5
            ml-auto
            inline-flex
            items-center
            dark:hover:bg-gray-800 dark:hover:text-white
          "
          data-modal-toggle="authentication-modal"
          @click="showAddressModal = false"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="py-6 px-6 lg:px-8">
          <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
            {{ addressModalTitle }}
          </h3>

          <span class="dark:text-white" v-for="address in addresses" v-bind:key="address">
            {{address}} <br>
          </span>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue";
import { useDropzone } from "vue3-dropzone";
import { getWallets, createWallet, deleteWallet, getWallet, getWalletAddresses, createWalletAddress } from "@/api/wallets.btc.api";
import Scrollbar from "smooth-scrollbar";

export default {
  name: "Wallets",
  components: {
    Icon,
  },
  data() {
    return {
      errors: {},
      form: {
        password: null,
      },
      addressModalTitle: "Addresses",
      showAddressModal: false,
      isLoading: false,
      wallets: [],
      addresses: [],
      hasKeys: false,
      form_processing: false,
      modal_form: {
        password: null,
        error: "",
      },
      showModal: false,
      tabActive: "new",
      importForm: {
        keyFileArray: null,
        password: null,
      },
      contents:[],
      error_import:[],
      balance:{},
      menu:[]
    };
  },
  mounted() {
    Scrollbar.init(document.querySelector("#wallet-table"));
    this.getWallets();
   
  },
  methods: {
    changeKeyFiles(event) {
        const reader = new FileReader();
        this.contents = [];
        self = this;
        reader.onload = function(e) {
            self.contents.push(e.target.result);
        };
        this.importForm.keyFileArray = [];

        for (const [key, file] of Object.entries(event.target.files)) {
            reader.readAsText(file);
            this.importForm.keyFileArray.push({"name":file.name})
        };

    },
    onUpload() {
      this.error_import=[];
      for (const i of Object.keys(this.importForm.keyFileArray)) {
        
      importWallet(
        {
          "filename": this.importForm.keyFileArray[i].name,
          "content": JSON.parse(this.contents[i]),
          "password": this.importForm.password
        }
      ).then((response) => {
          this.form_processing = false;
          this.$toast.success("wallet imported successfully", {
          // optional options Object
        });
      }).catch(error => {
        if (error.response.status == 422) {
            this.errors.password = error.response.data.message;
            this.form_processing = false;
          }
      });
      }
      this.importForm = {
        keyFileArray: null,
        password: null
      },
      this.contents = []
      this.getWallets();
    },

    popupDelete(add) {
      this.popup_wallet = add;
      this.showModal = true;
      this.modal_form.error = "";
    },
    closePopupDelete() {
      this.popup_wallet = "";
      this.showModal = false;
    },
    getBalance(wallet) {
      delete this.balance[wallet]
      getWallet(wallet)
      .then((response) => {
        this.balance[wallet] = JSON.parse(response.data.data.balance);
      });
    },

    getWallets() {
      this.isLoading = true;
      getWallets()
      .then((response) => {
        if (response.data.data.wallets.length > 0) {
          this.wallets = response.data.data.wallets;
              
              this.hasKeys = true;
            } else {
              this.hasKeys = false;
            }
      });
      this.isLoading = false;
      
    },
    getAddresses(wallet) {
      getWalletAddresses(wallet)
      .then((response) => {
        if (response.data.data.length > 0) {
              this.addresses = response.data.data;
              this.addressModalTitle = "Addresses for " + wallet;
              this.showAddressModal = true;
            } else {
              
            }
      });
    },
    createAddress(wallet) {
      createWalletAddress(wallet)
      .then((response) => {
        if (response.data.data.address.length > 0) {
              this.addresses = [response.data.data.address];
              this.addressModalTitle = "New addresses for " + wallet;
              this.showAddressModal = true;
            } else {
              
            }
      });
    },
    createWallet() {
      this.form_processing = true;
      this.errors = {};
      createWallet( this.form)
      .then((response) => {
        this.isSuccess = true;
        this.$toast.success("wallet created successfully", {
          // optional options Object
        });
        this.getWallets();
        this.form_processing = false;
          
      }).catch(error => {
        if (error.response.status == 400) {
            this.errors = error.response.data.data;
            this.form_processing = false;
          }
      });
      this.isLoading = false;
    },

    deleteWallet() {
      deleteWallet( this.popup_wallet,
          { data: this.modal_form }
        )
        .then((response) => {
          if (response.status == 200) {
            this.isSuccess = true;
            this.$toast.warning("Wallet deleted successfully", {
              // optional options Object
            });
            this.getWallets();
            this.showModal = false;
          }
        });
    },
  },
};
</script>

<style>
</style>