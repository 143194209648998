import axiosClient  from "../services/axios.service";

export function getAccount(){
    return axiosClient.get('/api/account');
}

export function updateAddress(form){
    return axiosClient.post("/api/account/address", form);
}

export function updateReferralCode(id){
    return axiosClient.post("/api/account/referral/"+id);
}

export function redeemCode(id){
    return axiosClient.post("/api/coupons/redeem/"+id);
}

export function getReferrals(){
    return axiosClient.get("/api/account/referrals");
}


export function login(form){
    return axiosClient.post('/api/login_check', JSON.stringify(form));
}

export function sendResetLink(form){
    return axiosClient.post('/api/login/send-reset-mail', JSON.stringify(form));
}


export function resetPassword(token, form){
    return axiosClient.post('/api/login/reset-password/'+token, JSON.stringify(form));
}

export function updatePassword(form){
    return axiosClient.post('/api/account/password', JSON.stringify(form));
}

export function twoFactorCheck(auth_code){
    let data = {
        "auth_code": parseInt(auth_code)
    } 
    return axiosClient.post('/api/login/2fa_check', JSON.stringify(data));
}

export function register(form){
    return axiosClient.post('/api/register', JSON.stringify(form));
}

export function confirm(form){
    return axiosClient.post('/api/login/confirm', JSON.stringify(form));
}

export function createApiKey(form){
    return axiosClient.post('/api/account/apikey', JSON.stringify(form));
}

export function deleteApiKey(id){
    return axiosClient.delete('/api/account/apikey/'+id);
}

export function getApiKeys(){
    return axiosClient.get('/api/account/apikey');
}

export function get2faSecret(){
    return axiosClient.get('/api/account/2fa-secret');
}

export function get2faQrCode(secret){
    let data = {
        'secret': secret
    }
    return axiosClient.post('/api/account/2fa-qr-code', data);
}

export function confirm2fa(secret, code){
    let data = {
        'secret': secret,
        'code': code
    }

    return axiosClient.post('/api/account/2fa-confirm', data);
}

export function disable2fa(){

    return axiosClient.post('/api/account/2fa-disable');
}

export function toggleWhitelist(){

    return axiosClient.post('/api/account/whitelist/toggle');
}


export function updateWhitelist($data){

    return axiosClient.post('/api/account/whitelist/gui_update', $data);
}

export function getWhitelist(){

    return axiosClient.get('/api/account/whitelist');
}

export function getWhitelistState(){

    return axiosClient.get('/api/account/whitelist/toggle');
}


