<template>
  <form
    class="space-y-4 "
    @submit.prevent="send"
  >
    <div
      v-if="errors"
      class="danger-alert bg-red-500 flex py-3 px-5 text-white w-full box-border rounded-md"
    >
      <span class="text-2xl mr-3"><svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        class="iconify iconify--ic"
      ><path
        fill="currentColor"
        d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8zm-4.17-7.14L12 10.59L9.17 7.76L7.76 9.17L10.59 12l-2.83 2.83l1.41 1.41L12 13.41l2.83 2.83l1.41-1.41L13.41 12l2.83-2.83l-1.41-1.41z"
      /></svg>
      </span>
      <span> {{ errors }} </span>
    </div>
    <div
      v-if="isSuccess"
      class="success-alert bg-green-500 text-white flex py-3 px-5 text-white w-full box-border rounded-md"
    >
      <span> Your password has been updated, please return to <b><a href="/login"> LOGIN </a></b> to sign up </span>
    </div>
   
    <h1 class="text-center text-2xl text-gray-800 dark:text-gray-800">
      New password
    </h1>
    <div class="flex flex-col space-y-2">
      <label
        class="text-sm font-light"
        for="new-password"
      >New password</label>
      <input
        id="new-password"
        v-model="form.password"
        class="w-96 px-3 py-2 rounded-md border border-slate-400"
        type="password"
        placeholder="Your Password"
        name="new-password"
      >
      <label
        class="text-sm font-light"
        for="email"
      >Repeat new password</label>
      <input
        id="repeat-new-password"
        v-model="form.password_repeat"
        class="w-96 px-3 py-2 rounded-md border border-slate-400"
        type="password"
        placeholder="Your Password"
        name="repeat-new-password"
      >
    </div>
    <button
      class="w-full px-10 py-2 bg-gruen text-white rounded-md
            hover:bg-lila hover:drop-shadow-md duration-300 ease-in"
      type="submit"
    >
      Update password
    </button>
  </form>
</template>

<script>

  import { resetPassword } from "../../api/accounts.api";



export default {
    name: "Login",
    data() {
      return {
        form:{
          password: "",
          password_repeat: "",
         
        },
        errors: "",
        isSuccess: false,
      }
    },

    mounted() {
    },
    methods: {
      send(){


        resetPassword(this.$route.params.token, this.form)
          .then(response => {
            console.log(response.data)
            this.isSuccess = true

          }).catch(error => {
            this.errors = error.data
            
          });

        }
    }

}
</script>

<style>

</style>


