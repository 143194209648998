import router from "@/router";
import { defineStore } from "pinia";
import { getAccount } from '../api/accounts.api';
import { ref, computed, watch } from 'vue'



export const useUserStore = defineStore("user", () => {

    const user = ref({
        name: null
    });

    if(localStorage.getItem('user')){
        user.value = JSON.parse(localStorage.getItem('user'));
    }

    watch(
        user, 
        (userVal) => {
            localStorage.setItem("user", JSON.stringify(userVal));
        },
        {deep: true}
    )

      const fetchUser = () => {
          console.log("reload User");
         getAccount().then(response => {
                localStorage.setItem("user", JSON.stringify(response.data));
                user.value = JSON.parse(response.data);
            });
    
      };

      return {
        user,
        fetchUser
      }
    }
);
    
