import axiosClient  from "../services/axios.service";

export function createSubscription(data){
    return axiosClient.post('/api/subscription', JSON.stringify(data));
}


export function getSubscription(){
    return axiosClient.get('/api/subscription');
}

export function cancelSubscription(){
    return axiosClient.delete('/api/subscription');
}

export function retrieveSubscription(){
    return axiosClient.post('/api/subscription/retrieve');
}

export function renewSubscription(months){
    return axiosClient.post('/api/subscription/renew', JSON.stringify({"months":months}));
}


export function switchPlan(form){
    return axiosClient.post('/api/subscription/switch', form);
}

export function getSubscriptionAddons(){
    return axiosClient.get('/api/subscription/addons');
}

