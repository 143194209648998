<template>
  <div v-if="!isSuccess" class="w-full">
    <form
      class="space-y-4 w-full" 
      @submit.prevent="register"
    >
      <h1 class="text-center text-2xl text-gray-800 dark:text-gray-800">
        Register for free plan now!
      </h1>
      <div class="flex flex-col space-y-2">
        <label
          class="text-sm font-light"
          for="email"
        >Email*</label>
        <input
          id="email"
          v-model="form.email"
          class="w-100 px-3 py-2 rounded-md border border-slate-400"
          type="email"
          placeholder="Your Email"
          name="email"
        >
        <span
          v-show="errors.email"
          class="text-xs text-red-700"
        >{{ errors.email }}</span>
      </div>
      <div class="flex flex-col space-y-2">
        <label
          class="text-sm font-light"
          for="username"
        >Username*</label>
        <input
          id="username"
          v-model="form.username"
          class="w-100 px-3 py-2 rounded-md border border-slate-400"
          type="test"
          placeholder="Your Username"
          name="username"
        >
      </div>
      <span
        v-show="errors.telegramUser"
        class="text-xs text-red-700"
      >{{ errors.telegramUser }}</span>

      <div class="flex flex-col space-y-2">
        <label
          class="text-sm font-light"
          for="telegram-username"
        >Telegram</label>
        <div class="flex">
          <span class="inline-flex items-center px-3 text-sm  text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md ">
            @
          </span>
          <input
            id="telegram-username"
            v-model="form.telegramUser"
            class="block flex-1 min-w-0 w-full  bg-gray-50 px-3 py-2 border border-r-md rounded-r-md border-slate-400"
            type="test"
            placeholder="Your Telegram Username"
            name="telegramUser"
          >
          </div>
        </div>
 
      <span
        v-show="errors.username"
        class="text-xs text-red-700"
      >{{ errors.telegramUser }}</span>


      <div class="flex flex-col space-y-2">
        <label
          class="text-sm font-light"
          for="country"
        >Country*</label>
        <select
          id="country"
          v-model="form.country"
          name="country"
          class="bg-white w-100 px-3 py-2 rounded-md border border-slate-400"
        >
          <option selected>
            Choose a country
          </option>
          <option
            v-for="country in countries"
            :key="country.iso2"
            :value="country.iso2"
          >
            {{ country.name }} <span
              style="float:right"
              v-html="country.emoji"
            />
          </option>
        </select>
      </div>
      <span
        v-show="errors.country"
        class="text-xs text-red-700"
      >{{ errors.country }}</span>

      <div class="flex flex-col space-y-2">
        <label
          class="text-sm font-light"
          for="password"
        >Password*</label>
        <input
          id="password"
          v-model="form.password"
          class="w-100 px-3 py-2 rounded-md border border-slate-400"
          type="password"
          placeholder="Your Password"
          name="password"
        >
        <span
          v-show="errors.password"
          class="text-xs text-red-700"
        >{{ errors.password }}</span>
      </div>
    

      <div>
        <input
          id="subscribe"
          v-model="form.subscribe"
          type="checkbox"
          name="subscribe"
          class="mr-1 checked:bg-blue-500"
        >
        <label
          class="text-sm font-light break-words"
          for="subscribe"
        >Subscribe to the Chaingateway.io newsletter and receive news and
          special deals
        </label>
      </div>
      <span
        v-show="errors.subscribe"
        class="text-xs text-red-700"
      >{{ errors.subscribe }}</span>
      <div>
        <input
          id="policy"
          v-model="form.policy"
          type="checkbox"
          name="policy"
          class="mr-1 checked:bg-lila"
          required
        >
        <label
          class="text-sm font-light"
          for="policy"
        >I accept the
          <a href="https://chaingateway.io/tos"> terms of service </a> and
          <a href="https://chaingateway.io/privacy"> privacy policy </a> *
        </label>
      </div>
      <span
        v-show="errors.policy"
        class="text-xs text-red-700"
      >{{ errors.policy }}</span>

      <button
        class="
        button
        w-full
        px-10
        py-2
        bg-gruen
        text-white
        rounded-md
        duration-300
        ease-in
      "
        type="submit"
      >
        Create Account
      </button>
      <span class="text-xs">* required</span>



      <p class="text-center text-xl pt-6">
        Already have an account? <br><a
          href="/login"
          class="text-lila font-light underline hover:text-gruen"
        >
          Login now</a>
      </p>
    </form>
  </div>
  <div
    v-else
    class="text-center"
  >
    <div>
      <Vue3Lottie
        :animation-data="RocketJSON"
        :height="200"
        :width="200"
      />
    </div>
    <h2>Your registration was sucessful. <br>You received an Email with an activation Code to <br>activate your account</h2>
    <div class="mt-10 mb-30">
      <a
        href="/login"
        class=" button
        px-2.5
        py-2.5
        bg-gruen
        text-white
        rounded-md
        hover:bg-lila hover:drop-shadow-md
        duration-300
        ease-in
      "
      > Already activated? Log In Now</a>
    </div>
  </div>
</template>
<script>

import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

import RocketJSON from '../../assets/lotties/rocket.json'
import {register} from '../../api/accounts.api'
import {getCountries} from '../../api/regions.api'


export default {
  components: {
    Vue3Lottie,
  },
  data() {
    return {
      countries: null,
      form: {
        email: "",
        username: "",
        telegramUser: "",
        country: "",
        password: "",
        subscribe: false,
        policy: false,
        referrer: null
        
      },
      errors: {},
      isSuccess: false,
      RocketJSON
    };
  },
  computed: {
    /*sortedCountries: function () {
      function compare(a, b) {
        if (a.name.common < b.name.common) return -1;
        if (a.name.common > b.name.common) return 1;
        return 0;
      }

      return this.countries.sort(compare);
    },*/
  },
  mounted(){
    
    this.getCountries();
  },
  methods: {
    register() {
      console.log(this.$route.query.referral)
      if(this.$route.query.referral){
        this.form.referrer = this.$route.query.referral
      }
      register(this.form)
        .then((response) => {

          if(response.status == 201){
            this.isSuccess = true;
          }
        })
        .catch((error) => {

          if(error.response.status == 400){
            this.errors = error.response.data.errors;

          }
          else{
            

            this.$toast.error("An Error occured: <br> "+error+" <br> please contact support", {
              // optional options Object
            });


          }
          
        });
    },
    getCountries(){

      getCountries()
        .then((response) => {
          if(response.status == 200){
            this.countries = response.data;

          }
        })
        .catch((error) => {

          if(error.response.status == 400){
            this.errors = error.response.data;

          }
          else{
            

            this.$toast.error("An Error occured: <br> "+error+" <br> please contact support", {
              // optional options Object
            });


          }
          
        });
    }
  },
};
</script>
