

import { getAccount }  from "../api/accounts.api";
 
class UserService {

  
  getAccount() {
    let $token = localStorage.getItem('token');
    let $user = localStorage.getItem('user');
    
    
    if(!$token){
        console.log("no token");
        return null;
    }
    if($token && $user != null){
        console.log("token and user");
        return JSON.parse($user);
    }
    if($token && $user == null){
        console.log("token but no user");

        getAccount()
         .then((response) => {
            if (response.data) {
              let user = response.data;

              return JSON.parse(user);
            }
            return response.data;
          });
       
         
    }

    return null;
    
  }

  updateAccount() {
    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user');

    if(user){
      return user;
    }
    if(token){
      getAccount()
         .then((response) => {
          try{
            if (response.data) {
              user = JSON.parse(response.data);

              return user;
            }
            return user;
          }catch(error){
            console.log(error);
          }
            
          });
         
    }

    return null;
    
  }



  logout(){
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }

  hasOneOfRoles(roles){
      let user = this.getAccount();


      if(roles){
        let isAuth = roles.some( ai => user.roles.includes(ai) );
        return isAuth;
      }
      else{
        return false;
      }

   

  }

}
export default new UserService();
