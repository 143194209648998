import axiosClient  from "../../services/axios.service";

export function getSubscriptions(){
    return axiosClient.get('/api/admin/subscriptions');
}
export function getActiveSubscriptions(){
    return axiosClient.get('/api/admin/subscriptions/active');
}

export function getSubscription(id){
    return axiosClient.get('/api/admin/subscription/'+id);
}

export function cancelSubscription(id){
    return axiosClient.delete('/api/admin/subscription/'+id);
}

export function retrieveSubscription(id){
    return axiosClient.post('/api/admin/subscription/'+id+'/retrieve');
}

export function renewSubscription(id){
    return axiosClient.get('/api/admin/subscription/'+id+'/renew');
}


export function switchPlan(id,form){
    return axiosClient.post('/api/admin/subscription/'+id+'/switch', form);
}



