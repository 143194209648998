import axiosClient  from "../../services/axios.service";

export function getAccounts(){
    return axiosClient.get('/api/admin/accounts');
}

export function getAccount(id){
    return axiosClient.get('/api/admin/account/'+id);
}

export function updateAccount(id, data){
    return axiosClient.post('/api/admin/account/'+id, data);
}

