<template>
  <div class="flex bg-packed font-lexend w-full ">
    <div
      class="h-screen flex justify-center items-center
        bg-gradient-to-r from-lila to-gruen w-full "
    >
      <div class="m-4 w-[100%] md:w-96">
        <div class="p-10 bg-white rounded-xl drop-shadow-lg space-y-5 content-center">
          <Logo />
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import Logo from "../views/components/logo.vue"


  export default {
    name: "AuthLayout",
    components: {
      Logo
    },

  }
</script>
