<template>
  <div class="p-4">
    <div
      class="bg-white dark:bg-gray-800 w-full p-6 font-lexend shadow-md rounded-md mt-5"
    >
      <div class="wrapper-link flex gap-4 text-sm text-gray-400">
        <a target="_blank" href="https://chaingateway.io/terms-of-service">
          Terms and conditions
        </a>
        <a target="_blank" href="https://chaingateway.io/privacy">
          Privacy Policy
        </a>
        <a target="_blank" href="https://chaingateway.io/imprint">
          Imprint
        </a>
        <a target="_blank" href="https://chaingateway.io/blog/">
          Blog
        </a>
        <a target="_blank" href="https://chaingateway.io/support">
          Contact and Support
        </a>
      </div>
    </div>
    <footer class="w-full box-border p-5 mt-3 text-center">
      <!--<p class="text-sm dark:text-gray-400">
        Chaingateway Made by ❤️ Bitblade.io
      </p>-->
    </footer>
  </div>
</template>
