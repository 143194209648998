<template>
  <div class="dasboard p-4">
    <h2 class="text-xl text-white">
      Membership
    </h2>


    <div
      v-if="subscription"
      class="wrapper-card  gap-2 mt-5"
    >
      <div class="mt-2 bg-white dark:bg-gray-800 dark:text-white p-5 w-full rounded-md box-border shadow">
        <div class="md:w-1/2 grid grid-cols-2 items-left">
          <span>User</span>
          <p>
            <a :href="('/admin/account/'+subscription.account.id)" class="text-teal-500 hover:text-teal-400">({{ subscription.account.id }}) {{ subscription.account.username }} <br></a>
            <a :href="('mailto:'+subscription.account.email)" class="text-teal-500 hover:text-teal-400">{{ subscription.account.email }}</a> <br>

          </p>
          <span>Payment provider</span>
          <span>{{ subscription.provider }}</span>
          <span>Stripe Id</span>
          <span>{{ subscription.provider_id }}</span>
          <span>Created at</span>
          <span>{{ subscription.createdAt }}</span>

        </div>

        <p class="text-gray-400 font-lexend font-normal my-2">
          Plan
        </p>

        <div
          v-if="subscription != null"
          class="min-h-20  dark:text-white box-shadow flex flex-wrap bg-gray-200 dark:bg-gray-700 rounded-lg flex p-2 place-items-center"
        >
          <div class="shrink pr-5">
            {{ subscription.plan.name }}
          </div>
          <div class="shrink px-5 w-40">
            <div
              v-if="!yearly"
              class="flex justify-center items-baseline my-8"
            >
              <span class="mr-2  ">€{{ subscription.plan.plan_pricing }}</span>
              <span class="text-gray-500 dark:text-gray-400">/ month</span>
            </div>
            <div
              v-else
              :class="'flex justify-center items-baseline mt-2 mb-8'"
            >
              <span class="mr-2  ">€{{ subscription.plan.plan_pricing_yearly }}</span>
              <span class="text-gray-500 dark:text-gray-400">/ year</span> 
            </div>
          </div>
          <div class="grow  w-300">
            <span>Webhooks:
              <span class="">{{ th(subscription.plan.ipns_limit) }}</span></span> <br>
            <span>Transactions:
              <span class="">{{ th(subscription.plan.transactions_limit) }}</span></span> <br>
            <span>Wallets:
              <span class="">{{ th(subscription.plan.ethaddresses_limit) }}</span></span> <br>
            <span>Requests:
              <span class="">{{ th(subscription.plan.requests_limit) }}</span></span>
          </div>
          <div class="shrink w-100">
            <button
              v-if="!subscription.plan.isFree && subscription.endsAt == null"
              type="button"
              class="button flex mr-2 text-white bg-gruen hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 float-right "
              @click="switchPopup = true"
            >
              Switch plan
            </button>
        
     
            <button
              v-if="!subscription.plan.isFree && subscription.endsAt == null"
              type="button"
              class="  mr-2 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-2.5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              @click="cancelPopup = true"
            >
              Cancel
            </button>
            <button
              v-if="!subscription.plan.isFree && subscription.endsAt != null"
              type="button"
              class="  mr-2 text-white bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-2.5 py-2.5 text-center"
              @click="retrieveSubscription"
            >
              retrieve
            </button>
            <a
              v-if="subscription.plan.isFree"
              href="/subscription"
              class="button flex mr-2 text-white bg-gruen hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 float-right"
            > Subscribe </a>
          </div>
        </div>
        <p
          v-if="subscription != null && subscription.upcomingPlan != null"
          class="text-gray-400 font-lexend font-normal my-2"
        >
          Upcoming Changes at next Billing period
        </p>
        <div
          v-if="subscription != null && subscription.upcomingPlan != null"
          class="min-h-20  box-shadow flex flex-wrap bg-gray-200 dark:bg-gray-700 rounded-lg flex p-2 place-items-center"
        >
          <div class="shrink pr-5">
            {{ subscription.upcomingPlan.name }}
          </div>
          <div class="shrink px-5 w-40">
            <div
              v-if="!yearly"
              class="flex justify-center items-baseline my-8"
            >
              <span class="mr-2  ">€{{ subscription.upcomingPlan.plan_pricing }}</span>
              <span class="text-gray-500 dark:text-gray-400">/ month</span>
            </div>
            <div
              v-else
              :class="'flex justify-center items-baseline mt-2 mb-8'"
            >
              <span class="mr-2  ">€{{ subscription.upcomingPlan.plan_pricing_yearly }}</span>
              <span class="text-gray-500 dark:text-gray-400">/ year</span> 
            </div>
          </div>
          <div class="grow  w-300">
            <span>Webhooks:
              <span class="">{{ th(subscription.upcomingPlan.ipns_limit) }}</span></span> <br>
            <span>Transactions:
              <span class="">{{ th(subscription.upcomingPlan.transactions_limit) }}</span></span> <br>
            <span>Wallets:
              <span class="">{{ th(subscription.upcomingPlan.ethaddresses_limit) }}</span></span> <br>
            <span>Requests:
              <span class="">{{ th(subscription.upcomingPlan.requests_limit) }}</span></span>
          </div>
        </div>
        <div class="flex-1">
          <p class="text-gray-400 font-lexend font-normal my-2 ">
            Payment information
          </p>
          <div
            v-if="subscription != null"
            class=" flex flex-wrap min-h-20 box-shadow flex bg-gray-200 dark:bg-gray-700 rounded-lg flex p-2 justify-content-center"
          >
            <div class="flex-1 pr-5 text-center">
              Current period start: <br>{{ moment(subscription.current_period_start).format("YYYY-MM-DD") }}
            </div>
            <div class="flex-1 pr-5 text-center">
              Next billing around: <br>{{ moment(subscription.current_period_end).format("YYYY-MM-DD") }}
            </div>
            <div class="flex-1 pr-5 text-center">
              Cancellation date: <br><span v-if="subscription.endsAt">{{ moment(subscription.endsAt).format("YYYY-MM-DD") }}</span>
            </div>
          </div>

          <div
            v-if="subscription != null"
            class=" flex flex-wrap min-h-20 box-shadow flex bg-gray-200 dark:bg-gray-700 rounded-lg flex p-2 justify-content-center"
          >
            <billing :button="true" />
          </div>
        </div>
      </div>
   



      <div
        id="password-modal"
        tabindex="-1"
        aria-hidden="true"
        :class="
          (cancelPopup ? '' : 'hidden') +
            ' fixed inset-0 mx-auto bg-gray-900 top-100 bg-opacity-70 overflow-y-auto h-full w-full'
        "
      >
        <div
          id="popup-modal"
          class="relative mx-auto sm:w-3/4 md:w-2/4  p-4 w-full max-w-md h-full md:h-auto"
        >
          <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="popup-modal"
                @click="cancelPopup = false"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                ><path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                /></svg>
                <span class="sr-only" />
              </button>
              <div class="p-6 text-center">
                <svg
                  aria-hidden="true"
                  class="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                ><path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                /></svg>
                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to cancel this membership?
                </h3>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  @click="deleteSubscription"
                >
                  Yes, I'm sure
                </button>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  class="text-gray-500 bg-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  @click="cancelPopup = false"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="switch-plan-modal"
        tabindex="-1"
        aria-hidden="true"
        :class="
          (switchPopup ? '' : 'hidden') +
            ' fixed inset-0 mx-auto bg-gray-900 top-100 bg-opacity-70 overflow-y-auto h-full w-full'
        "
      >
        <div
          id="popup-modal-switch"
          class="relative mx-auto sm:w-3/4 md:w-2/4  p-4 w-full max-w-md h-full md:h-auto"
        >
          <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="popup-modal"
                @click="switchPopup=false"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                ><path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                /></svg>
                <span class="sr-only" />
              </button>
              <div class="p-6 text-center">
                <h3> Switch Plan</h3>
              </div>


              <div class="p-6 text-left">
                <form @submit.prevent="">
                  <label
                    for="countries"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >Select your new Plan</label>
                  <select
                    id="countries"
                    v-model="form.plan_id"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option
                      v-for="plan in plans"
                      :key="plan.id"
                      :value="plan.id"
                    >
                      {{ plan.name }} for  € <span v-if="yearly">{{ plan.plan_pricing_yearly }}/year</span><span velse>{{ plan.plan_pricing }} / year</span> 
                    </option>
                  </select>
                  <div
                    v-if="errors"
                    class="mt-5 danger-alert bg-red-500 flex py-3 px-5 text-white w-full box-border rounded-md"
                  >
                    {{ errors }}
                  </div>
                </form>
              </div>
              <div class="p-6 text-center">
                <span v-if="subscription">Your new Plan will begin at the end of your current Billing cycle   </span>
              </div>


              <div class="p-1 text-center">
                <button
                  data-modal-toggle="popup-modal-switch"
                  type="submit"
                  class="text-white bg-lila hover:bg-lila focus:ring-4 focus:outline-none focus:ring-lila dark:focus:ring-lila font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  @click="switchPlan"
                >
                  Yes, I'm sure
                </button>
                <button
                  data-modal-toggle="popup-modal-switch"
                  type="button"
                  class="text-gray-500 bg-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  @click="switchPopup = false"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getSubscription, cancelSubscription, retrieveSubscription, switchPlan} from '@/api/admin/subscriptions.api'
import { getPlans } from "@/api/plans.api";
import userService from '@/services/user.service';

export default {
    name: "MembershipSettings",
  data() {
    
    return {
        subscription: null,
        cancelPopup: false,
        switchPopup: false,
        plans: [],
        errors: null,
        form:{
          plan_id: null
        }

    }},
    mounted(){
        this.getSubscription();
        this.getPlans();

    },
  methods: {
    th(num){ 
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dt(dt){
      return new Date(dt);
    },
    getSubscription() {
      getSubscription(this.$route.params.id)
        .then((response) => {
          if (response.status == 200) {
            if (response.data != null) {
              this.subscription = response.data.data.subscription;
            }
          }
        });
    },
    deleteSubscription(){

      cancelSubscription(this.$route.params.id)
        .then((response) => {
          if (response.status == 200) {
            this.getSubscription();
            this.$toast.success(
             "Your subscription was cancelled successfully",
              {
                // optional options Object
              }
            );
            this.cancelPopup = false;
          }
        });
    },
    retrieveSubscription(){
      retrieveSubscription(this.$route.params.id)
        .then((response) => {
          if (response.status == 200) {
            this.getSubscription();
            this.$toast.success(
             "Your subscription was retrieved successfully",
              {
                // optional options Object
              }
            );
            this.cancelPopup = false;
          }
        });
    },
    getPlans() {
      this.isLoading = true;
      getPlans()
        .then((response) => {
          if (response.status == 200) {
            if (response.data.data.plans.length > 0) {
              this.plans = JSON.parse(response.data.data.plans);
            } else {
            }

            this.isLoading = false;
          }
        });
    },
    switchPlan() {
      switchPlan(this.$route.params.id, this.form)
        .then((response) => {
          if (response.status == 200) {
            this.switchPopup = false;
            userService.updateAccount();
            this.getSubscription();
          }
        }).catch(error => {
            this.errors = error.response;
          }
      );
    },
  },
};
</script>

<style>
</style>