<template>
  <!-- sidebar -->
  <nav class="sidebar bg-white dark:bg-gray-800" id="sidebar">
    <!-- sidebar head -->
    <div class="sidebar-head p-4">
   
      <router-link
        to="/"
        exact
      />

        <span
          class="text-2xl font-normal text-gray-800 dark:text-gray-500 opacity-50"
          
        >
          <img
            src="../assets/img/navlogo.png"
            width="30"
            style="float:left;"
            class="invert dark:invert-0 "
          >
          <span class="text-black dark:text-white left text-xl">Chaingateway.io</span>
         
        </span>

    </div>
    <!-- User Menu list -->
    <div class="sidebar-list p-4 mt-4">
      <p class="font-medium text-gray-400 dark:text-gray-600 dark: text-gray-400">
        Menu
      </p>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="bxs:dashboard" /></span>
            <span class="w-full"> Dashboard </span>
          </router-link>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/account/apiKeys"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="bxs:key" /></span>
            <span class="w-full"> API Keys </span>
          </router-link>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/subscription"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="bxs:rocket" /></span>
            <span class="w-full"> Subscription </span>
          </router-link>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <a
            href="https://chaingateway.io/docs"
            target="_blank"
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="akar-icons:paper" /></span>
            <span class="w-full"> Documentation </span>
          </a>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/migration"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="eos-icons:database-migration" /></span>
            <span class="w-full"> Migration </span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="sidebar-list p-4 mt-4">
      <p class="font-medium text-gray-400 dark:text-gray-600 dark: text-gray-400">
        Blockchain functions
      </p>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item mt-3">
          <menu-accordion>
            <template #icon>
              <Icon icon="cryptocurrency:eth" />
            </template>
            <template #title>
              Ethereum
            </template>
            <template #content>
              <router-link
                to="/addresses/ethereum"
                class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span class="mr-3 text-xl"><Icon icon="akar-icons:key" /></span>
                <span class="w-full"> Addresses </span>
              </router-link>
              <router-link
                to="/webhooks/ethereum"
                class="w-full flex text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span class="mr-3 text-xl"><Icon icon="ic:baseline-webhook" /></span>
                <span class="w-full"> Webhooks </span>
              </router-link>
            </template>
          </menu-accordion>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item mt-3">
          <menu-accordion>
            <template #icon>
              <Icon icon="cryptocurrency:matic" />
            </template>
            <template #title>
              Polygon
            </template>
            <template #content>
              <router-link
                to="/addresses/polygon"
                class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span class="mr-3 text-xl"><Icon icon="akar-icons:key" /></span>
                <span class="w-full"> Addresses </span>
              </router-link>
              <router-link
                to="/webhooks/polygon"
                class="w-full flex text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span class="mr-3 text-xl"><Icon icon="ic:baseline-webhook" /></span>
                <span class="w-full"> Webhooks </span>
              </router-link>
            </template>
          </menu-accordion>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item mt-3">
          <menu-accordion>
            <template #icon>
              <Icon icon="cryptocurrency:bnb" />
            </template>
            <template #title>
              Binance Smart Chain
            </template>
            <template #content>
              <router-link
                to="/addresses/bsc"
                class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span class="mr-3 text-xl"><Icon icon="akar-icons:key" /></span>
                <span class="w-full"> Addresses </span>
              </router-link>
              <router-link
                to="/webhooks/bsc"
                class="w-full flex text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span class="mr-3 text-xl"><Icon icon="ic:baseline-webhook" /></span>
                <span class="w-full"> Webhooks </span>
              </router-link>
            </template>
          </menu-accordion>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item mt-3">
          <menu-accordion>
            <template #icon>
              <Icon icon="cryptocurrency:trx" />
            </template>
            <template #title>
              Tron Network
            </template>
            <template #content>
              <router-link
                to="/webhooks/tron"
                class="w-full flex text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span class="mr-3 text-xl"><Icon icon="ic:baseline-webhook" /></span>
                <span class="w-full"> Webhooks</span>
              </router-link>
            </template>
          </menu-accordion>
        </div>
      </div>
       <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item mt-3">
          <menu-accordion>
            <template #icon>
              <Icon icon="cryptocurrency:btc" />
            </template>
            <template #title>
              
                Bitcoin [BETA]
                
            </template>
            <template #content>
               <router-link
                to="/wallets/bitcoin"
                class="w-full flex text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span class="mr-3 text-xl"><Icon icon="bx:wallet" /></span>
                <span class="w-full"> Wallets</span>
              </router-link>
             <router-link
                to="/webhooks/bitcoin"
                class="w-full flex text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span class="mr-3 text-xl"><Icon icon="ic:baseline-webhook" /></span>
                <span class="w-full"> Webhooks</span>
              </router-link>
            </template>
          </menu-accordion>
        </div>
      </div>
        <div class="wrap-item mt-4 dark:text-gray-500">
          <div class="item mt-3">
            <router-link
              to="/requestlog"
              exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <span class="mr-3 text-xl"><Icon icon="bx:transfer" /></span>
              <span class="w-full"> Request Log </span>
            </router-link>
          </div>
        </div>
      </div>
    

    <!-- User Menu list -->
    <div class="sidebar-list p-4 mt-4" v-if="user && user.roles && user.roles.includes('ROLE_ADMIN')">
      <p class="font-medium text-gray-400 dark:text-gray-600">Admin</p>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/admin"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="bxs:dashboard" /></span>
            <span class="w-full"> Dashboard </span>
          </router-link>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/admin/accounts"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="bxs:user" /></span>
            <span class="w-full"> Accounts </span>
          </router-link>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/admin/plans"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="bxs:cart" /></span>
            <span class="w-full"> Plans </span>
          </router-link>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/admin/subscriptions"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="bxs:calendar" /></span>
            <span class="w-full"> Subscriptiona </span>
          </router-link>
        </div>
      </div>
      
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/admin/subscription_addons"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="mdi:plugin" /></span>
            <span class="w-full"> Subscription Addons </span>
          </router-link>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/admin/announcements"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="mdi:message-alert" /></span>
            <span class="w-full"> Announcements </span>
          </router-link>
        </div>
      </div>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/admin/coupons"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="mdi:coupon" /></span>
            <span class="w-full"> Coupons </span>
          </router-link>
        </div>
      </div>
    </div>

      
 

    <!-- Examples list -->
    <div class="sidebar-list p-4 mt-4" v-if="user.roles && (user.roles.indexOf('ROLE_ADMIN') !== -1 ) && user.email == 'dane@holzwarth-net.de'">
      <p class="font-medium text-gray-400 dark:text-gray-600">DEMO-BAUSTEINE</p>
      <div class="wrap-item mt-4 dark:text-gray-500">
        <div class="item">
          <router-link
            to="/"
            exact
            class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <span class="mr-3 text-xl"><Icon icon="bxs:dashboard" /></span>
            <span class="w-full"> Dashboard </span>
          </router-link>
        </div>
        <div class="item mt-3">
          <menu-accordion>
            <template #icon>
              <Icon icon="gg:components" />
            </template>
            <template #title>
              Components
            </template>
            <template #content>
              <router-link
                to="/component/alert"
                class="w-full text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Alert
              </router-link>
              <router-link
                to="/component/accordion"
                class="w-full text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Accordion
              </router-link>
              <router-link
                to="/component/badge"
                class="w-full text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Badge
              </router-link>
              <router-link
                to="/component/breadcumb"
                class="w-full text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Breadcumb
              </router-link>
              <router-link
                to="/component/button"
                class="w-full text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Button
              </router-link>
              <router-link
                to="/component/card"
                class="w-full text-left block rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Card
              </router-link>
              <button
                class="w-full text-left rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Dropdown
              </button>
              <button
                class="w-full text-left rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                List Group
              </button>
              <button
                class="w-full text-left rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Modal
              </button>
              <button
                class="w-full text-left rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Nav
              </button>
              <button
                class="w-full text-left rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Pagination
              </button>
              <button
                class="w-full text-left rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Progress
              </button>
            </template>
          </menu-accordion>
        </div>
        <div class="item mt-3">
          <menu-accordion>
            <template #icon>
              <Icon icon="bi:layout-wtf" />
            </template>
            <template #title>
              Layouts
            </template>
            <template #content>
              <button
                class="w-full text-left rounded-md p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Coming Soon
              </button>
            </template>
          </menu-accordion>
        </div>
      </div>
      <p class="font-medium text-gray-400 mt-4 dark:text-gray-600 dark: text-gray-400">
        UI Form & Tables
      </p>

      <div class="item mt-3">
        <menu-accordion>
          <template #icon>
            <Icon icon="ant-design:form-outlined" />
          </template>
          <template #title>
            Form Element
          </template>
          <template #content />
        </menu-accordion>
      </div>

      <div class="item mt-3">
        <menu-accordion>
          <template #icon>
            <Icon icon="mdi:form-textbox" />
          </template>
          <template #title>
            Form Editor
          </template>
          <template #content />
        </menu-accordion>
      </div>
      <div class="item mt-3">
        <router-link to="/">
          <button
            class="text-gray-800 dark:text-gray-500 bg-transparent hover:bg-gray-100 dark:hover:bg-gray-700 w-full flex text-left rounded-md box-border p-3"
          >
            <span class="mr-3 text-xl"><Icon icon="bi:table" /></span>
            <span class="w-full"> Table </span>
            <span class="box-border mt-1 text-gray-500" />
          </button>
        </router-link>
      </div>
      <div class="item mt-3">
        <router-link to="/">
          <button
            class="text-gray-800 dark:text-gray-500 bg-transparent hover:bg-gray-100 dark:hover:bg-gray-700 w-full flex text-left rounded-md box-border p-3"
          >
            <span class="mr-3 text-xl"><Icon icon="carbon:data-table" /></span>
            <span class="w-full"> DataTables </span>
            <span class="box-border mt-1 text-gray-500" />
          </button>
        </router-link>
      </div>
    </div>
  </nav>
</template>
<script>
  import { Icon } from "@iconify/vue";
  import MenuAccordion from "./MenuAccordion.vue";
  import { useUserStore } from "@/store/user";
  import { storeToRefs } from 'pinia'
  import Scrollbar from 'smooth-scrollbar';


  export default {
    components: {
      Icon,
      MenuAccordion,
    },
    setup() {

const { user } = storeToRefs(useUserStore())
return { user };
},
    mounted() {
console.log("hide")
      Scrollbar.init(document.querySelector('#sidebar'));
    },
     methods: {
      sidebarHide() {
        console.log("hide")
        document.querySelector(".flex-sidebar").classList.add("hidden");
      },
    },
   
    
  };
</script>
<style>
  .active {
  }
</style>
