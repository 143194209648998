import axiosClient  from "../services/axios.service";


export function getIpns(chain, id, onlyFailed, page=1, limit=30, search = ""){
    let data = {
        "page": page,
        "limit": limit,
        "search": search
    };

    if(onlyFailed){
        return axiosClient.post('/v2/' + chain + '/webhooks/'+id+'/notifications/failed', data);
    }
    return axiosClient.post('/v2/' + chain + '/webhooks/'+id+'/notifications', data);
}

export function resend(chain, id, all){
    if(all){
        return axiosClient.post('/v2/' + chain + '/webhooks/'+id+'/notifications/failed/all');
    }
    return axiosClient.post('/v2/' + chain + '/webhooks/notifications/failed/'+id);
}



export function searchWebhooks(chain, page=1, limit, search = ""){
    let data = {
        "page": page,
        "limit": limit,
        "search": search
    };
    return axiosClient.post('/v2/' + chain + '/webhooks/search', data);
}

export function getWebhooks(chain){
    return axiosClient.get('/v2/' + chain + '/webhooks');
}

export function createTest(chain, webhookId){
    return axiosClient.post('/v2/' + chain + '/webhooks/'+webhookId+'/test');
}

export function getFailedNotifications(chain){
    return axiosClient.get('/v2/' + chain + '/webhooks/notifications/failed');
}

export function createWebhook(chain, form){
    console.log('chain: '+JSON.stringify(chain))
    return axiosClient.post('/v2/' + chain + '/webhooks', form);
}

export function deleteWebhook(chain, id){
    return axiosClient.delete('/v2/' + chain + '/webhooks/'+id);
}

