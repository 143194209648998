<template>
  <div class="dashboard p-4">
    <nav
      class="flex"
      aria-label="Breadcrumb"
    >
      
      <ol class="inline-flex items-center space-x-1 md:space-x-3">
        <li class="inline-flex items-center">
          <a
            href="#"
            class="inline-flex items-center text-sm font-medium hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
          >
            <svg
              class="mr-2 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              />
            </svg>
            Dashboard
          </a>
        </li>
      </ol>
    </nav>
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl  text-gray-800 dark:text-gray-200 font-medium">
        Dashboard
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div
      class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5"
    >
      <!-- card  -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex"
      >
        <div class="p-2 max-w-sm">
          <div
            class="bg-orange-200 rounded-full w-14 h-14 text-3xl p-3 text-orange-600 mx-auto"
          >
            <Icon icon="ic:baseline-calendar-month" />
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-200 text-xl">
            {{ elements.active_subscriptions }}
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">
            Total Active Subs
          </h2>
        </div>
      </div>
      <!-- end card -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex"
      >
        <div class="p-2 max-w-sm">
          <div
            class="bg-green-200 rounded-full w-14 h-14 text-3xl p-3 text-white mx-auto"
          >
            <Icon icon="material-symbols:person-add-rounded" />
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-200 text-xl">
            {{ elements.new_users_this_month }}
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">
            New users tihs month
          </h2>
        </div>
      </div>
      <!-- end card -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex"
      >
        <div class="p-2 max-w-sm">
          <div
            class="bg-red-200 rounded-full w-14 h-14 text-3xl p-3 text-red-600 mx-auto"
          >
            <Icon icon="bx:dollar" />
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-200 text-xl">
            {{ elements.new_subscriptions_this_month }}
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">
            New Subscriptions this month
          </h2>
        </div>
      </div>
      <!-- end card -->
      <div
        class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 shadow flex"
      >
        <div class="p-2 max-w-sm">
          <div
            class="bg-purple-200 rounded-full w-14 h-14 text-3xl p-3 text-purple-600 mx-auto"
          >
            <Icon icon="mdi:account-cancel-outline" />
          </div>
        </div>

        <div class="block p-2 w-full">
          <p class="font-semibold text-gray-200 text-xl">
            {{ elements.cancelations_month }}
          </p>
          <h2 class="font-normal text-gray-400 text-md mt-1">
            Cancellations this month
          </h2>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end wrapper card -->
    <div class="mt-2 lg:flex block lg:gap-2">
      <div
        class="bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow"
      >
        <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
          Total Sales
        </h2>
        <p class="text-gray-400 font-lexend font-normal">
          your sales chart per-years
        </p>
        <!-- <span class="float-right mr-20">
          <h2 class="text-red-500 -mt-12 flex">
            <span class="mr-2"> 15.9% </span><span>
              <Icon icon="akar-icons:arrow-down" />
            </span>
          </h2>
        </span>
        <span class="float-right">
          <h2 class="text-green-500 -mt-12 flex">
            <span class="mr-2"> 87.9% </span><span>
              <Icon icon="akar-icons:arrow-up" />
            </span>
          </h2>
        </span> -->
        <br>
        <apexchart
          width="100%"
          height="380"
          type="area"
          :options="optionsArea"
          :series="seriesArea"
          :sparkline="{
            enabled: true,
          }"
        />
        <br>
        <hr>
        <div class="wrapper-button mt-3">
          <select
            id=""
            v-model="type"
            name=""
            class="dark:bg-gray-800 dark:hover:bg-gray-700 border-gray-200 dark:border-gray-300 border max-w-lg px-4 py-3 block rounded-md text-gray-500 dark:text-gray-400"
            @change="reloadChart"
          >
            <option value="month">
              Monthly
            </option>
            <option value="year">
              Yearly
            </option>
          </select>
          <button
            class="uppercase float-right -mt-7 border-b border-red-600 text-red-600"
          >
            Sales Report
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { Icon } from "@iconify/vue";
  import { getDashboard } from "@/api/admin/dashboard.api";
  import moment from "moment";

  export default {
    name: "AdminDashboard",
    components: {
      Icon,
    },
    data() {
      return {
        // for more guide apexchart.js
        // https://apexcharts.com/docs/chart-types/line-chart/

        // chart data area
        elements:{},
        type: 'month',
      // chart data area
      optionsArea: {
        chart: {
          id: 'chart',
          foreColor: '#9ca3af',
          fontFamily: "lexend, sans-serif",
        },
        noData: {
          text: "No Data to display",
          align: "center",
          verticalAlign: "middle",
        },
        xaxis: {
          
        },
        
        fontFamily: "Segoe UI, sans-serif",
        stroke: {
          curve: "straight",
        },

        markers: {
          size: 0,
        },
        yaxis: {
          show: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
      },
      colors : ['#008ffb', '#00e396','#ef4444'],
      seriesArea: [
        {
          name: "Revenue",
          data: [],
        },
        {
          name: "Revenue (Previous period)",
          data: [],
        },
      ],
      };
      // end chart data line
    },
    mounted(){
      this.getDashboard();
    },
    methods:{
      getDashboard(){
        getDashboard()
        .then((response) => {
          if (response.status == 200) {
            if (response.data.data) {
             this.elements = response.data.data;
             this.reloadChart();
          }
        }});
      },
      reloadChart(){
        let element = this.elements.invoiced_month;
        if(this.type=='year'){
          element = this.elements.invoiced_year;
        }
        console.log(element.map(({ amount }) => amount));

        this.optionsArea = {
          
              xaxis :{
                categories: element.map(({ period }) => period),
                labels:{
                  style:{

                  }
                }
              },
              colors : ['#008080']
            }

            this.seriesArea = [
              {
                name: 'Sales',
                data: element.map(({ amount }) => amount)
              }
            ]

            

      }
    }
        
  };
</script>
