import { createRouter, createWebHistory } from "vue-router";

// Default Pages
import Dashboard from "../views/Dashboard.vue";
import Subscription from "../views/subscription.vue";
import RenewSubscription from "../views/renewSubscription.vue";
import Migration from "../views/migration.vue";
import Order from "../views/order.vue";
import Thankyou from "../views/thankyou.vue";
import NotSubscribed from "../views/notSubscribed.vue";

import RequestLog from "../views/requestlog.vue";
import Announcements from "../views/admin/announcements/index.vue";
import AdminDashboard from "../views/admin/dashboard.vue";

import AnnouncementForm from "../views/admin/announcements/form.vue";
import Settings from "../views/settings/settings.vue";
import IPNS from "../views/ipns.vue";
import Addresses from "../views/addresses.vue";
import Wallets from "../views/bitcoin/wallets.vue";
import Webhooks from "../views/webhooks.vue";
import BtcWebhooks from "../views/bitcoin/webhooks.vue";

import ApiKeys from "../views/account/apiKeys.vue";
import Login from "../views/auth/login.vue";
import Referral from "../views/auth/referral.vue";
import ForgetPassword from "../views/auth/forgetPassword.vue";
import ResetPassword from "../views/auth/resetPassword.vue";
import { useUserStore } from "@/store/user";
import { storeToRefs } from 'pinia'

import Register from "../views/auth/register.vue";
import Accounts from "../views/admin/accounts/index.vue";
import EditAccount from "../views/admin/accounts/edit.vue";
import Subscriptions from "../views/admin/subscriptions/index.vue";
import SubscriptionAddons from "../views/admin/subscriptionAddons/index.vue";
import EditSubscriptions from "../views/admin/subscriptions/edit.vue";
import Plans from "../views/admin/plans/index.vue";
import Coupons from "../views/admin/coupons/index.vue";

import PlanForm from "../views/admin/plans/form.vue";
import Referrals from "../views/Referrals.vue";

// Component Pages
import Valert from "../views/components/alert.vue";
import Vaccrodion from "../views/components/accordion.vue";
import Vbadges from "../views/components/badges.vue";
import Vbreadcumb from "../views/components/breadcumbs.vue";
import Vbutton from "../views/components/button.vue";
import Vcard from "../views/components/card.vue";
import UserService from "../services/user.service";
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

var appname = " - Chaingateway.io";



const routes = [
  // Routes
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: "Dashboard " + appname, authorize: [] },
    pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/referrals",
    name: "Referrals",
    component: Referrals,
    meta: { title: "Referrals " + appname, authorize: [] },
    pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Subscription " + appname, authorize: [] },
    pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/migration",
    name: "Migration",
    component: Migration,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Migration " + appname, authorize: [] },
    pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/subscription/renew",
    name: "Renew Subscription",
    component: RenewSubscription,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Dashboard " + appname, authorize: [] },
    pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/order/:id/:interval",
    name: "Order",
    component: Order,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Dashboard " + appname, authorize: [] },
    pathToRegexpOptions: { strict: true } 

  },

  {
    path: "/webhooks/bitcoin",
    name: "Bitcoin Webhooks",
    component: BtcWebhooks,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Requests " + appname, authorize: []},
    pathToRegexpOptions: { strict: true } 
  },
  {
    path: "/referral/:id",
    name: "Referral",
    component: Referral,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { layout: "auth", title: "Requests " + appname},
    pathToRegexpOptions: { strict: true } 
  },
  {
    path: "/webhooks/ethereum",
    name: "Eteherum Webhooks",
    component: Webhooks,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Requests " + appname, authorize: []},
    props: {chainName: "Ethereum",
            icon: "eth"},
            pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/webhooks/tron",
    name: "Tron Webhooks",
    component: Webhooks,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Requests " + appname, authorize: []},
    props: {chainName: "Tron",
            icon: "trx"},
            pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/webhooks/bsc",
    name: "BSC Webhooks",
    component: Webhooks,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Requests " + appname, authorize: []},
    props: {chainName: "BSC",
            icon: "bnb"},
            pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/webhooks/polygon",
    name: "Polygon Webhooks",
    component: Webhooks,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Requests " + appname, authorize: []},
    props: {chainName: "Polygon",
            icon: "matic"},
            pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/requestlog",
    name: "Request Log",
    component: RequestLog,
    meta: { title: "Request Log " + appname, authorize: [] },
    pathToRegexpOptions: { strict: true } 
  },
  {
    path: "/webhooks/:chain/:id",
    name: "Webhook browser",
    component: IPNS,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Webhooks " + appname, authorize: []},
    props: (route) => ({chainName: (route.params.chain == "ethereum"?"Ethereum":( route.params.chain == "tron"?"Tron":( route.params.chain == "polygon"?"Polygon":( route.params.chain == "bitcoin"?"Bitcoin":"BSC")))),
                        icon: (route.params.chain == "ethereum"?"eth":( route.params.chain == "tron"?"trx":( route.params.chain == "bitcoin"?"btc":( route.params.chain == "polygon"?"matic":"bnb")))) }),
                        pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/addresses/:chain",
    name: "Addresses",
    component: Addresses,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Requests " + appname, authorize: []},
    props: (route) => ({chainName: (route.params.chain == "ethereum"?"Ethereum":( route.params.chain == "tron"?"Tron":( route.params.chain == "polygon"?"Polygon":( route.params.chain == "polygon"?"matic":"BSC")))),
                        icon: (route.params.chain == "ethereum"?"eth":( route.params.chain == "tron"?"trx":"bnb")) }),
                        pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/wallets/bitcoin",
    name: "Wallets",
    component: Wallets,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Wallets " + appname, authorize: []},
    pathToRegexpOptions: { strict: true } 
  },
  {
    path: "/account/apiKeys",
    name: "apikeys",
    component: ApiKeys,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Api Keys " + appname, authorize: []},
    pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { layout: "auth", title: "Login " + appname },
    pathToRegexpOptions: { strict: true } 
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    meta: { layout: "auth", title: "Login " + appname },
    pathToRegexpOptions: { strict: true } 
  },
  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: ResetPassword,
    meta: { layout: "auth", title: "Login " + appname },
    pathToRegexpOptions: { strict: true } 
  },

  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { layout: "auth", title: "Register " + appname },
    pathToRegexpOptions: { strict: true } 
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Settings " + appname, authorize: [] },
    //pathToRegexpOptions: { strict: true } 

  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: Thankyou,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Thank You " + appname, authorize: [] },
 

  },
  {
    path: "/not-subscribed",
    name: "NotSubscribed",
    component: NotSubscribed,
    //meta: { title: "Dashboard " + appname, authorize: [] },
    meta: { title: "Thank You " + appname, authorize: [] },
 

  },
  {
    path: "/admin/accounts",
    name: "Users",
    component: Accounts,
    meta: { title: "Accounts " + appname },

  },
  {
    path: "/admin/coupons",
    name: "Coupons",
    component: Coupons,
    meta: { title: "Coupons " + appname },

  },
  {
    path: "/admin/account/:id",
    name: "Edit User",
    component: EditAccount,
    meta: { title: "Edit Account " + appname },

  },
  {
    path: "/admin/plans",
    name: "Plans",
    component: Plans,
    meta: { title: "Plans  " + appname },

  },
  {
    path: "/admin/plan/:id",
    name: "Edit Plans",
    component: PlanForm,
    meta: { title: "Plans  " + appname },
  },
  {
    path: "/admin/announcements",
    name: "Announcements",
    component: Announcements,
    meta: { title: "Announcements  " + appname },
  },
  {
    path: "/admin/announcement/:id",
    name: "Edit ",
    component: AnnouncementForm,
    meta: { title: "Announcements  " + appname },
  },
  {
    path: "/admin/subscriptions",
    name: "Subscriptions",
    component: Subscriptions,
    meta: { title: "Subscription  " + appname },
  },
  {
    path: "/admin/subscription_addons",
    name: "Subscription Addons",
    component: SubscriptionAddons,
    meta: { title: "Subscription Addons  " + appname },
  },
  {
    path: "/admin/subscription/:id",
    name: "Edit Subscription ",
    component: EditSubscriptions,
    meta: { title: "Subscription  " + appname },
  },
  {
    path: "/admin",
    name: "Admin Dashboard",
    component: AdminDashboard,
    meta: { title: "Announcements  " + appname },
  },

  // Components based Routes
  {
    path: "/component/alert",
    name: "Valert",
    component: Valert,
    meta: { title: "Alert" + appname },
  },
  {
    path: "/component/accordion",
    name: "Vaccordion",
    component: Vaccrodion,
    meta: { title: "Accordion" + appname },
  },
  {
    path: "/component/badge",
    name: "Vbadge",
    component: Vbadges,
    meta: { title: "Badge" + appname },
  },
  {
    path: "/component/breadcumb",
    name: "Vbreadcumb",
    component: Vbreadcumb,
    meta: { title: "Breadcumb" + appname },
  },
  {
    path: "/component/button",
    name: "Vbutton",
    component: Vbutton,
    meta: { title: "Button" + appname },
  },
  {
    path: "/component/card",
    name: "Vcard",
    component: Vcard,
    meta: { title: "Card" + appname },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  linkExactActiveClass: "exact-active",
});

/*
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("user") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (!store.state.isAuthenticated) {
        next({
          path: "/login",
          params: { nextUrl: to.fullPath },
        });
      } else {
        document.title = to.meta.title;
        document.layout = to.meta.layout;
        next();
      }
    }
  } else {
    next();
  }
});
*/
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  let authorize = to.meta.authorize;

  if (authorize) {
      if (localStorage.getItem("token") == null) {
          // not logged in so redirect to login page with the return url
          return next({ path: '/login', query: { returnUrl: to.path } });
      }
     
     /*
     let user  = JSON.parse(localStorage.getItem("user"))
     console.log("user"+user.subscription);
    if(user.subscription == null && to.path !== '/not-subscribed' && to.path !== '/settings' && to.path !== '/referrals'){
      console.log("went in")  
      return next({ path: '/not-subscribed' });
      }*/
      

      // check if route is restricted by role
      if (authorize.length && !UserService.hasOneOfRoles(authorize)) {
          // role not authorised so redirect to home page
          
          
          document.title = to.meta.title;
          document.layout = to.meta.layout;
          next();
          return next({ path: '/' });
      }
  }

  next();
})

export default router;
