<!-- eslint-disable vue/require-default-prop -->
<template>
<div class="dashboard p-4 relative">
    <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
            <li class="inline-flex items-center">
                <a href="#" class="
              inline-flex
              items-center
              text-sm
              font-medium
              hover:text-gray-900
              dark:text-gray-400 dark:hover:text-white
            ">
                    <Icon :icon="'cryptocurrency:' + icon" class="mr-2" />

                    {{ chainName }}
                </a>
            </li>
        </ol>
    </nav>
    <!-- end nav -->
    <div class="mt-5 w-full">
        <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
            <Icon :icon="'cryptocurrency:' + icon" class="mr-2 text-3xl" />
            {{ chainName }} Webhooks
        </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid gap-2 mt-5">
        <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5">
            <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
                Create new IPN
            </h2>
            <p class="text-gray-400 font-lexend font-normal">
                Please fill at least one of the fields "From Address", "To Address",
                "Contract" or "Token". To limit the ammount of Notifications, we
                recommend you to fill at least two of this fields.
            </p>

            <form class="space-y-2" action="#" @submit.prevent="createWebhook()">
                <div class="flex mt-2">
                    <div class="flex-auto pr-3">
                        <label for="from" :class="
                  errors.from
                    ? 'block mb-2 text-sm font-medium text-red-700 dark:text-red-500'
                    : 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                ">From address</label>
                        <input id="from" v-model="form.from" type="text" name="from" placeholder="4x1234...." :class="
                  errors.from
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                ">
                        <p v-show="errors.from" class="mt-2 text-sm text-red-600 dark:text-red-500">
                            <span class="font-medium" />
                            {{ errors.from }}
                        </p>
                    </div>
                    <div class="flex-auto pr-3">
                        <label for="to" :class="
                  errors.to
                    ? 'block mb-2 text-sm font-medium text-red-700 dark:text-red-500'
                    : 'block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                ">To address</label>
                        <input id="to" v-model="form.to" type="text" name="to" placeholder="0x1234...." :class="
                  errors.to
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                ">
                        <p v-show="errors.to" class="mt-2 text-sm text-red-600 dark:text-red-500">
                            <span class="font-medium" />
                            {{ errors.to }}
                        </p>
                    </div>
                </div>

                <div class="flex">
                    <div class="flex-auto pr-3">
                        <label for="contractaddress" :class="
                  errors.contractaddress
                    ? 'block my-2 text-sm font-medium text-red-700 dark:text-red-500'
                    : 'block my-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                ">Contract address</label>
                        <input id="contractaddress" v-model="form.contractaddress" type="text" name="contractaddress" placeholder="0x1234...." :class="
                  errors.contractaddress
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                ">
                        <p v-show="errors.contractaddress" class="mt-2 text-sm text-red-600 dark:text-red-500">
                            <span class="font-medium" />
                            {{ errors.contractaddress }}
                        </p>
                    </div>

                    <div class="flex-auto pr-3">
                        <label for="tokenid" :class="
                  errors.tokenid
                    ? 'block my-2 text-sm font-medium text-red-700 dark:text-red-500'
                    : 'block my-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                ">Token ID</label>
                        <input id="contractaddress" v-model="form.tokenid" type="text" name="contractaddress" placeholder="1234...." :class="
                  errors.tokenid
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                ">
                        <p v-show="errors.tokenid" class="mt-2 text-sm text-red-600 dark:text-red-500">
                            <span class="font-medium" />
                            {{ errors.tokenid }}
                        </p>
                    </div>
                </div>

                <div class="flex">
                    <div class="flex-auto pr-3">
                        <label for="contractaddress" :class="
                  errors.url
                    ? 'block my-2 text-sm font-medium text-red-700 dark:text-red-500'
                    : 'block my-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                ">URL</label>
                        <input id="contractaddress" v-model="form.url" type="text" name="contractaddress" placeholder="https://example.com/ipnreceiver.php" :class="
                  errors.url
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                ">
                        <p v-show="errors.url" class="mt-2 text-sm text-red-600 dark:text-red-500">
                            <span class="font-medium" />
                            {{ errors.url }}
                        </p>
                    </div>

                    <div class="flex-auto pr-3">
                        <label for="tokenid" :class="
                  errors.type
                    ? 'block my-2 text-sm font-medium text-red-700 dark:text-red-500'
                    : 'block my-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                ">Type </label>
                        <select id="countries" v-model="form.type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option v-for="ty in types[chainName.toLowerCase()]" :key="ty">
                                {{ ty }}
                            </option>
                        </select>
                        <p v-show="errors.type" class="mt-2 text-sm text-red-600 dark:text-red-500">
                            <span class="font-medium" />
                            {{ errors.type }}
                        </p>
                    </div>
                </div>

                <div class="flex mt-4">
                    <button type="submit" class="
                button
                flex
                text-white
                bg-gruen
                hover:bg-blue-800
                font-medium
                rounded-lg
                text-sm
                px-5
                py-2.5
                mr-2
                mb-2
              ">
                        <span v-if="form_processing" class="mr-2 text-xl">
                            <Icon icon="line-md:loading-twotone-loop" />
                        </span>
                        <span v-else class="mr-2 text-xl">
                            <Icon icon="akar-icons:plus" />
                        </span>

                        <span> Create Webhooks </span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="wrapper-card mt-5 relative">
        <div class="card relative bg-white dark:bg-gray-800 w-full rounded-md p-5">
            <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
                Webhooks
            </h2>
            <p class="text-gray-400 font-lexend font-normal">
                A list with your existing Webhooks
            </p>
            <div v-if="isLoading" class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mx-auto mt-5">
                <span class="mr-2 text-xl mx-auto text-6xl">
                    <Icon icon="line-md:loading-twotone-loop" class="mx-auto" />
                </span>
            </div>

            <div class="
            flex 
              input-box my-3
              border
              dark:bg-gray-900 dark:border-gray-700
              rounded-md
              box-border

              focus-within:bg-gray-100
              dark:focus-within:bg-gray-700
            ">
                <span class="flex flex-row text-3xl p-2 text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 50 50" class="iconify iconify--ei">
                        <path fill="currentColor" d="M23 36c-7.2 0-13-5.8-13-13s5.8-13 13-13s13 5.8 13 13s-5.8 13-13 13zm0-24c-6.1 0-11 4.9-11 11s4.9 11 11 11s11-4.9 11-11s-4.9-11-11-11z" />
                        <path fill="currentColor" d="m32.682 31.267l8.98 8.98l-1.414 1.414l-8.98-8.98z" /></svg></span><input v-model="search" v-debounce:300="delayedSearch" type="text" placeholder="Search..." class="
                p-3
                w-full
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              ">
            </div>

            <table id="webhooks" class=" w-full
            text-sm text-left text-gray-500
            dark:text-gray-400
            lg:overflow-auto
            overflow-x-scroll
          ">
                <thead class="
              text-xs text-gray-700
              uppercase
              bg-gray-50
              dark:bg-gray-700 dark:text-gray-400
            ">
                    <tr>
                        <th scope="col" class="uppercase px-6 py-3">
                            Webhook ID
                        </th>
                        <th scope="col" class="uppercase px-6 py-3">
                            addresses
                        </th>
                        <th scope="col" class="uppercase px-6 py-3">
                            Meta
                        </th>
                        <th scope="col" class="uppercase px-6 py-3">
                            URL
                        </th>
                        <th scope="col" class="uppercase px-6 py-3">
                            Action
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="webhook in webhooks" :key="webhook" class="
                bg-white
                border-b
                dark:bg-gray-800 dark:border-gray-700
                odd:bg-white
                even:bg-gray-50
              ">
                        <td class="px-6 py-4">
                            {{ webhook.id }}
                        </td>
                        <td class="px-6 py-4">
                            from: {{ webhook.from }} <br> to: {{ webhook.to }}
                        </td>
                        <td class="px-6 py-4">
                            Contract: {{ webhook.contractaddress }}<br>
                            Token: {{ webhook.tokenid }} <br>
                            Type: {{ webhook.type }}<br>
                        </td>
                        <td class="px-6 py-4">
                            {{ webhook.url }}
                        </td>
                        <td :id="Webhook" class=" text-center">
                            <button class="text-2xl" @click="(menu[webhook.id] = true)" @blur="(menu[webhook.id] = false)" data-dropdown-placement="left-start">
                                <div class="user-avatar content-center items-center hover:bg-gray-100 dark:hover:bg-gray-700 p-1 cursor-pointer rounded-md">
                                    <span class="text-md mt-4 text-gray-300">
                                        <Icon icon="mi:options-horizontal" /></span>
                                </div>
                            </button>

                            <transition name="fade" class="z-50">
                                <div v-show="menu[webhook.id] != null && menu[webhook.id]" id="dropdownSmall" class="block absolute  mt-0 z-10 w-44 -translate-x-[110px] border dark:border-gray-700 bg-white dark:bg-gray-800 rounded divide-y dark:divide-gray-700 divide-gray-100 shadow">
                                    <ul class="py-1 z-50 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSmallButton">
                                        <li class="">
                                            <button class="w-full flex hover:bg-primary hover:text-white justify-start items-left gap-2 p-2" @click="test(webhook.id)">
                                                <Icon icon="bx:test-tube" class="text-lg " /> <span>Send demo data</span>
                                            </button>
                                        </li>
                                        <li class="">
                                            <a :href="'/webhooks/'+chainName.toLowerCase()+'/'+webhook.id" class="w-full flex hover:bg-primary hover:text-white justify-start items-left gap-2 p-2">
                                                <Icon icon="akar-icons:eye" class="text-lg " /> <span>Show Notifications</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <button class="w-full text-red-500 hover:text-white flex hover:bg-red-500 hover:text-white justify-start items-left gap-2 p-2" @click="popupDelete(webhook.id)">
                                                <Icon icon="bx:trash" class="text-lg " /> <span>Delete</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </transition>

                        </td>
                    </tr>
                    <tr>
                        <td colspan="5">
                            <button v-if="!isLoading && webhooks.length > 0 && page < totalPages" type="button" :disabled="page == totalPages" class="justify-center content-center items-center flex py-2.5 px-5 mr-2 my-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 w-full text-center" @click="getWebhooks()">
                                <span v-if="buttonText == 'Loading'" class="text-2xl mr-2">
                                    <Icon icon="line-md:loading-twotone-loop" /></span> <span v-else class="text-xl">{{ buttonText }}</span>
                            </button>

                        </td>
                       </tr>
                    <tr v-show="webhooks.length == 0 && !isLoading">
                    <td colspan="5">
                        <div class="w-full text-gray-800 dark:text-gray-200 dark:text-gray-200 mt-5">
                            <Icon icon="akar-icons:face-sad" class="text-center mx-auto text-5xl" />
                            <p class="text-xl text-center">
                                You have no webhooks yet. Please add one
                            </p>
                        </div>
                    </td>
             
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>

<!-- Main modal -->
<div id="password-modal" tabindex="-1" aria-hidden="true" :class="
      (showModal ? '' : 'hidden') +
        ' fixed inset-0 bg-gray-900 top-100 bg-opacity-70 overflow-y-auto h-full w-full'
    ">
    <div class="relative mx-auto p-4 w-full max-w-md h-full md:h-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" class="
            absolute
            top-3
            right-2.5
            text-gray-400
            bg-transparent
            hover:bg-gray-200 hover:text-gray-900
            rounded-lg
            text-sm
            p-1.5
            ml-auto
            inline-flex
            items-center
            dark:hover:bg-gray-800 dark:hover:text-white
          " data-modal-toggle="authentication-modal" @click="closePopupDelete()">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="py-6 px-6 lg:px-8">
                <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                    Are you sure?
                </h3>
                <form class="space-y-6" action="#" @submit.prevent="deleteWebhook()">
                    <div>
                        <p class="text-white">
                            This process can take up to several minutes
                        </p>
                    </div>

                    <button type="submit" class="
                w-full
                text-white
                bg-red-700
                hover:bg-red-800
                focus:ring-4 focus:outline-none focus:ring-red-300
                font-medium
                rounded-lg
                text-sm
                px-5
                py-2.5
                text-center
                dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800
              ">
                        Delete now
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

  
<script>
import {
    Icon
} from "@iconify/vue";
import {
    searchWebhooks,
    createWebhook,
    createTest,
    deleteWebhook
} from '@/api/webhooks.api';
import {
    vue3Debounce
} from 'vue-debounce'
import Scrollbar from 'smooth-scrollbar';

export default {
    name: "WebhooksBrowser",
    directives: {
        debounce: vue3Debounce({
            lock: true
        })
    },
    components: {
        Icon,
    },
    // eslint-disable-next-line vue/require-default-prop
    props: {
        chainName: String,
        icon: String
    },
    data() {
        return {
            menu: {},
            errors: {},
            form: {
                url: null,
            },
            isLoading: false,
            page: 1,
            nextPage: 1,
            limit: 50,
            search: "",
            totalPages: 1,
            webhooks: [],
            hasWebhooks: false,
            form_processing: false,
            showModal: false,
            types: {
                ethereum: ["ETH", "ERC20", "ERC721"],
                polygon: ["ETH", "ERC20", "ERC721"],
                tron: ["Tron", "TRC10", "TRC20", "TRC721"],
                bsc: ["BNB", "BEP-20", "BEP-721"]
            },
            popup_webhook: null,
            buttonText: "Load more"
        };
    },
    mounted() {
        Scrollbar.init(document.querySelector('#webhooks'));

        this.isLoading = true;
        this.getWebhooks();
        this.isLoading = false;
    },
    created() {
        this.$watch(
            () => this.chainName,
            (toParams, previousParams) => {
                this.webhooks = [];
                this.page = 1;
                this.nextPage = 1;
                this.search = "";
                this.getWebhooks(toParams);
            }
        )
    },
    methods: {
        popupDelete(add) {
            this.popup_webhook = add;
            this.showModal = true;
        },
        closePopupDelete() {
            this.popup_webhook = "";
            this.showModal = false;
        },
        delayedSearch() {
            this.page = 1;
            this.nextPage = 1;
            this.totalPages = 1;
            this.webhooks = [];
            this.buttonText = "Loading";
            this.getWebhooks();
        },
        getWebhooks(toParams) {
            this.buttonText = 'Loading';
            let param = this.chainName.toLowerCase();
            if (toParams) {
                param = toParams.toLowerCase();
            }
            searchWebhooks(this.chainName.toLowerCase(), this.nextPage, this.limit, this.search)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.data.ipns.length > 0) {
                            this.webhooks.push(...response.data.data.ipns);
                            this.hasWebhooks = true;
                            this.totalPages = response.data.data.total_pages;
                            this.page = response.data.data.current_page;
                            this.nextPage = response.data.data.current_page + 1;
                            this.buttonText = "Load more";
                            if (this.page >= this.limit) {
                                this.buttonText = "No more Data";
                            }

                        } else {
                            this.hasWebhooks = false;
                        }

                    }
                });
        },
        createWebhook(toParams) {

            let param = this.chainName.toLowerCase();
            if (toParams) {
                param = toParams;
            }

            this.form_processing = true;
            this.errors = {};
            console.log
            createWebhook(param, this.form)
                .then((response) => {
                    if (response.status == 200) {
                        this.isSuccess = true;
                        this.$toast.success("Webhook created successfully", {
                            // optional options Object
                        });
                        this.delayedSearch();
                        this.form_processing = false;
                    }
                })
                .catch((error) => {

                    if (error.response.status == 400) {
                        error.response.data.errors.forEach((element) => { 
                         this.errors[element.context.field] = element.message
                        });

                        //console.log(error.response.data);

                    }
                    this.form_processing = false;
                });
        },

        deleteWebhook() {

            deleteWebhook(this.chainName.toLowerCase(), this.popup_webhook)
                .then((response) => {
                    if (response.status == 200) {
                        this.isSuccess = true;
                        this.$toast.warning("Your Webhook will be deleted <br> within the next 30 Seconds", {
                            // optional options Object
                        });
                        this.delayedSearch();
                        this.showModal = false;
                    }
                });
        },
        test(id) {
            createTest(this.chainName.toLowerCase(), id)
                .then((response) => {
                    if (response.status == 200) {
                        this.isSuccess = true;
                        this.$toast.success("Test data was sent", {
                            // optional options Object
                        })

                    }
                });
        },
        menuToggle: function () {
            this.menu = !this.menu;
        },
        menuToggleBlur: function () {
            this.menu = false;
        },
    },
};
</script>
  
  
<style>
</style>
