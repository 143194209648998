<template>
  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl  text-gray-800 dark:text-gray-200 font-medium">
        Manage Subscriptions addons
      </h1>
    </div>
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5" />

    <div class="mt-2 bg-white dark:bg-gray-800 dark:text-white p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
       Create new addon
      </h2>

        <form
          @submit.prevent="create()"
          class="grid lg:grid-cols-2 grid-cols-1  gap-2"
        >
          <div class="flex flex-col space-y-2 mb-4">
            <label
              class="text-gray-400"
              for="type"
            >Type</label>
            <input
              id="name"
              v-model="form.type"
              class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              "
              type="text"
              placeholder="Service Level Agreement"
              name="type"
            >
            <span
              v-show="errors.type"
              class="text-xs text-red-700"
            >{{
              errors.type
            }}</span>
          </div>
          <div class="flex flex-col  space-y-2 mb-4">
            <label
              class="text-gray-400"
              for="name"
            >Name</label>
            <input
              id="lexwareId"
              v-model="form.name"
              class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              "
              type="text"
              placeholder="24 Hrs response time"
              name="name"
            >
            <span
              v-show="errors.name"
              class="text-xs text-red-700"
            >{{
              errors.name
            }}</span>
          </div>
          <div class="flex flex-col space-y-2 mb-4">
            <label
              class="text-gray-400"
              for="price"
            >Monthly price</label>
            <input
              id="price"
              v-model="form.price"
              class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              "
              type="number"
              placeholder="120"
              name="price"
            >
            <span
              v-show="errors.price"
              class="text-xs text-red-700"
            >{{
              errors.price
            }}</span>
          </div>
          <div class="flex flex-col space-y-2 mb-4">
            <label
              class="text-gray-400"
              for="priceYearly"
            >Yearly price</label>
            <input
              id="price"
              v-model="form.priceYearly"
              class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              "
              type="number"
              placeholder="1000"
              name="priceYearly"
            >
            <span
              v-show="errors.priceYearly"
              class="text-xs text-red-700"
            >{{
              errors.priceYearly
            }}</span>
          </div>
          
        

          <button
            class="
              w-full
              col-span-2 
              px-10
              py-2
              bg-gruen
              text-white
              rounded-md
              hover:bg-lila hover:drop-shadow-md
              duration-300
              ease-in
              
              
            "
            type="submit"
          >
            create
          </button>
        </form>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5" />

    <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
        Subscription addons
      </h2>

      <div class="wrapping-table mt-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                ID
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Type
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Name
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Price / Price yearly
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Price ID / Price ID Yearly
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="addon in addons"
              :key="addon.id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
            >
              <td class="px-6 py-4">
                {{ addon.id }}
              </td>
              <td class="px-6 py-4">
                {{ addon.type }}
              </td>
              <td class="px-6 py-4">
                {{ addon.name }}
              </td>
              <td class="">
                  €{{addon.price}}/mont h <br> 
                  €{{addon.priceYearly}}/year
              </td>
              <td class="px-6 py-4">
                {{addon.priceId}} <br> 
                {{addon.priceIdYearly}}
              </td>

              <td class="px-6 py-4">
                <button class="bg-red-800 hover:bg-red-900 text-white p-2 rounded-md" @click="remove(addon.id)"> <Icon icon="bx:trash" /> </button>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>


import { getSubscriptionAddons, createSubscriptionAddons, deleteSubscriptionAddons } from '../../../api/admin/subscriptionAddons.api'
import { Icon } from "@iconify/vue";


export default {
    components: {
Icon
    },

    data() {
        return {
            addons: [],
            form_processing: false,
            form: {
              name: "", 
              type: "", 
              price: 0, 
              priceYearly: 0

            },
            errors: "",
        };
    },
    mounted() {
        this.getSubscriptionAddons();
    },
    
    methods: {
        getSubscriptionAddons() {
            getSubscriptionAddons()
                .then(response => {
                    if (response.data) {

                        this.addons = response.data.data.addons;
                        //console.log(response.data.data.addons);
                    }
                })
            //this.addons = flatten(this.addons);
        },
        remove(id){
          deleteSubscriptionAddons(id)
                .then(response => {
                  console.log(response)
                   this.$toast.success("addon deleted successfully", {
                    // optional options Object
                  });
                  this.getSubscriptionAddons();
                })
        },
        create(){
           this.errors =[];
           this.form_processing = true;
          createSubscriptionAddons(this.form)
                .then((response) => {
                    this.form_processing = false;
                    this.$toast.success("address imported successfully", {
                    // optional options Object
                  });
                  this.getSubscriptionAddons();
                  this.form.name = null;
                }).catch(error => {

                      this.errors = error.response.data.errors;
                      this.form_processing = false;
                    
                });
        }
    }
}
</script>
