<template>
  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl  font-medium">
        <span v-if="$route.params.id == 'new'">New Announcement</span>
        <span v-if="$route.params.id != 'new'">Edit Announcement: </span>
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div
      class="
        wrapper-card
        grid
        lg:grid-cols-4
        grid-cols-1
        md:grid-cols-2
        gap-2
        mt-5
      "
    />

    <div
      class="
        mt-2
        text-gray-400
        bg-white
        dark:bg-gray-800
        p-5
        w-full
        rounded-md
        box-border
        shadow
      "
    >
      <form @submit.prevent="postAnnouncement">
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="email"
          >Headline</label>
          <input
            id="headline"
            v-model="form.headline"
            class="
              p-3
              bg-white
              dark:bg-gray-900
              rounded-md
              outline-none
              focus:bg-gray-100
              dark:focus:bg-gray-700
            "
            type="text"
            placeholder="Short Headline!"
            name="headline"
          >
          <span
            v-show="errors.headline"
            class="text-xs text-red-700"
          >{{
            errors.headline
          }}</span>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            class="text-gray-400"
            for="content"
          >Content</label>
          <input
            id="content"
            v-model="form.content"
            class="
              p-3
              bg-white
              dark:bg-gray-900
              rounded-md
              outline-none
              focus:bg-gray-100
              dark:focus:bg-gray-700
            "
            type="text"
            placeholder="description of the subject the subject"
            name="name"
          >
          <span
            v-show="errors.content"
            class="text-xs text-red-700"
          >{{
            errors.content
          }}</span>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            for="type"
            class="
              block
              my-2
              text-sm
              font-medium
              text-gray-900
              dark:text-gray-300
            "
          >Type </label>
          <select
            id="type"
            v-model="form.type"
            class="
              bg-gray-50
              border border-gray-300
              text-gray-900 text-sm
              rounded-lg
              focus:ring-blue-500 focus:border-blue-500
              block
              w-full
              p-2.5
              dark:bg-gray-700
              dark:border-gray-600
              dark:placeholder-gray-400
              dark:text-white
              dark:focus:ring-blue-500
              dark:focus:border-blue-500
            "
          >
            <option class="bg-green-500">
              success-alert
            </option>
            <option class="bg-yellow-500">
              warning-alert
            </option>
            <option class="bg-red-500">
              danger-alert
            </option>
            <option class="bg-gruen">
              primary-alert
            </option>
          </select>
          <p
            class="mt-2 text-sm text-red-600 dark:text-red-500"
            style="display: none"
          >
            <span
              v-show="errors.content"
              class="text-xs text-red-700"
            >{{
              errors.content
            }}</span>
          </p>
        </div>
        <div class="flex flex-col space-y-2 mb-4">
          <label
            for="type"
            class="
              block
              my-2
              text-sm
              font-medium
              text-gray-900
              dark:text-gray-300
            "
          >Valid from - to </label>
          <div class="grid grid-cols-3">
            <div>
              <vue-tailwind-datepicker
                v-model="form.validFrom"
                :formatter="{date: 'YYYY-MM-DD', month: 'MMM'}"
                as-single
              />
              <span
                v-show="errors.validFrom"
                class="text-xs text-red-700"
              >{{
                errors.content
              }}</span>
            </div>
            <div class="text-center text-4xl">
              <b>-</b>
            </div> <div>
              <vue-tailwind-datepicker
                v-model="form.validTo"
                :formatter="{date: 'YYYY-MM-DD', month: 'MMM'}"
                as-single
              />
              <span
                v-show="errors.validTo"
                class="text-xs text-red-700"
              >{{
                errors.content
              }}</span>
            </div>
          </div>
        </div>
      
        <button
          class="
            w-full
            px-10
            py-2
            bg-gruen
            text-white
            rounded-md
            hover:bg-lila hover:drop-shadow-md
            duration-300
            ease-in
          "
          type="submit"
        >
          Create Announcement
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import {
  updateAnnouncement,
  createAnnouncement,
  getAnnouncement,
} from "../../../api/admin/announcements.api";


export default {
  data() {
    return {
      form: {
        headline: null,
        content: null,
        type: null,
        validFrom: null,
        validTo: "null"

      },
      errors: "",
    };
  },
  mounted() {

    if (this.$route.params.id != "new") {
      this.getAnnouncement(this.$route.params.id);
    } else {
      console.log("new Announcement");
    }
  },
  methods: {
    getAnnouncement(id) {
      getAnnouncement(id)
        .then((response) => {
          if (response.data) {
            this.form = response.data.data.announcement;
            
          }
        })
        .catch((error) => {
          console.log("error" + JSON.stringify(error));
          this.errors = error;
        });
      //this.announcements = flatten(this.announcements);
    },
    postAnnouncement() {
      if (this.$route.params.id != "new") {
        updateAnnouncement(this.$route.params.id, this.form)
          .then((response) => {
            if (response.data) {
              //this.form = JSON.parse(response.data.data.announcement);
              console.log(response.data.data.announcement);
              this.$router.push("/admin/announcements");
            }
          })
          .catch((error) => {
            console.log("error" + JSON.stringify(error));
            this.errors = error;
          });
      } else {
        this.erors = [];
        createAnnouncement(this.form)
          .then((response) => {
            if (response.data) {
              //this.form = JSON.parse(response.data.data.announcement);
              console.log(response.data);
              this.$router.push("/admin/announcements");
            }
          })
          .catch((error) => {
            console.log(error);
            this.errors = error.response.data.errors;
          });
      }
    },
  },
};
</script>