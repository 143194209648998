<template>
    <div class="dashboard p-4">

        <!-- grid wrapper card -->
        <div class="w-full wrapper-card mt-5">
            <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 text-gray-800 dark:text-gray-200 dark:text-gray-200">
                <h2 class="font-bold text-lg ">
                    Your Referral Link
                </h2>
                <p class="mb-2">
                    You can send it to partners and friends to get a 20% referral bonus as a coupon 
                </p>
            
                <form>   
                    <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Referral url</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <Icon icon="material-symbols:link-rounded" />
                        </div>
                        <input v-if="account" type="text" id="referral-link" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" :value="'https://app.chaingateway.io/referral/'+account.referralId" disabled>
                       <!-- <button v-on:click="copyURL" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><Icon icon="lucide:clipboard-copy" /></button>-->
                    </div>
                </form>

            </div> 
        </div>
        <div class="w-full wrapper-card mt-5">
            <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 text-gray-800 dark:text-gray-200 dark:text-gray-200">
                <h2 class="font-bold text-lg mb-2">
                    Your Referral Coupons
                </h2>

                <table id="webhooks" class=" w-full
            text-sm text-left text-gray-500
            dark:text-gray-400
            lg:overflow-auto
            overflow-x-scroll 
          ">
                <thead class="
              text-xs text-gray-700
              uppercase
              bg-gray-50
              dark:bg-gray-700 dark:text-gray-400
            ">
                        <tr>
                        <th class="uppercase px-6 py-3">Referred user</th>
                        <th class="uppercase px-6 py-3">Coupon amount</th>
                        <th class="uppercase px-6 py-3">Coupon Code</th>
                        <th class="uppercase px-6 py-3">Redeem</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="referral in referrals" v-bind:key="referral.id" class="
                bg-white
                border-b
                dark:bg-gray-800 dark:border-gray-700
                odd:bg-white
                even:bg-gray-50
              "> 
                            <td class="px-6 py-4">
                                <div class="user-avatar flex  p-1 rounded-md items-center" v-if="referral.referredAccount" >
                                    <img :src="referral.referredAccount.avatar" class="rounded-full mr-4 w-10 h-10 p-1 ring-1 ring-gray-300 dark:ring-gray-500" alt="">
                                    <span>{{referral.referredAccount.username}}    </span>
                                </div>
                                <span v-else class=""
                                >
                            General Promo</span>
                                
                            </td>
                            <td class="px-6 py-4">{{referral.coupon.amountOff}} €</td>
                            <td class="px-6 py-4">{{referral.coupon.stripeId}}</td>
                            <td class="px-6 py-4"><button @click="redeem(referral.coupon.id)" class="button p-2 rounded-md disabled:opacity-50" :disabled="referral.coupon.timesRedeemed >= referral.coupon.maxRedemptions">Redeem on next Billing</button></td>
                        </tr>
                    </tbody>
                </table>

            </div> 
        </div>
    </div>
    </template>
    
    <script>
    // @ is an alias to /src
    import { getAccount, getReferrals, redeemCode } from '@/api/accounts.api';
    import { Icon } from "@iconify/vue";
    export default {
        name: "Referrals",
        components: {
            Icon

        },
        data() {
            return {
                account: null,
                referrals: []
            };
            // end chart data line
        },
    
        mounted() {
            this.getAccount();
            this.getReferrals()
        },

        methods:{
            getAccount(){
                getAccount().then(response => {
                this.account = JSON.parse(response.data);
            });

            },
            getReferrals(){
                this.referrals= [];
                getReferrals().then((response) => {
                        if (response.status == 200) {
                            this.referrals = response.data.data.referrals
                        }
                    }).catch((error) => {
                        this.errorMessage = error.response.data
                    });
            },
            redeem(id){
                redeemCode(id).then((response) => {
                        if (response.status == 200) {
                            this.$toast.success("Coupon code redeemed successfully. See subscription settings for more Info.", {
                                // optional options Object
                            });
                            this.getReferrals();
                        }
                    }).catch((error) => {
                        this.errorMessage = error.response.data
                        this.$toast.error(this.errorMessage, {
                                // optional options Object
                            });
                    });
                    
            }
            
        },
        
  
    };
    </script>
    