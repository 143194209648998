<template>
  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl  text-gray-800 dark:text-gray-200 font-medium">
        <span> Edit Account </span>
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div
      class="
          wrapper-card
          grid
          lg:grid-cols-4
          grid-cols-1
          md:grid-cols-2
          gap-2
          mt-5
        "
    />
  
    <div
      class="
          mt-2
          text-gray-400
          bg-white
          dark:bg-gray-800
          p-5
          w-full
          rounded-md
          box-border
          shadow
        "
    >
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div v-if="account"> 
          <h2>Update address </h2>
          <billing :account="account" :parentErrors="errors" ref="billing"/>
        </div>
        <form
          v-if="account"
          @submit.prevent="updateAccount"
        >
          <div class="flex flex-col space-y-2 mb-4">
            <label
              class="text-gray-400"
              for="email"
            >Stripe Id</label>
            <input
              id="stripeCustomerId"
              v-model="account.StripeCustomerId"
              class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              "
              type="text"
              placeholder="00000"
              name="stripeCustomerId"
            >
            <span
              v-show="errors.StripeCustomerId"
              class="text-xs text-red-700"
            >{{
              errors.StripeCustomerId
            }}</span>
          </div>
          <div class="flex flex-col space-y-2 mb-4">
            <label
              class="text-gray-400"
              for="email"
            >Lexare Id</label>
            <input
              id="lexwareId"
              v-model="account.lexwareId"
              class="
                p-3
                bg-white
                dark:bg-gray-900
                rounded-md
                outline-none
                focus:bg-gray-100
                dark:focus:bg-gray-700
              "
              type="text"
              placeholder="00000"
              name="lexwareId"
            >
            <span
              v-show="errors.lexwareId"
              class="text-xs text-red-700"
            >{{
              errors.lexwareId
            }}</span>
          </div>
          <div class="flex flex-col space-y-2 mb-4">
            <label
              class="text-gray-400"
              for="email"
            >Roles</label>
            <select
              id="countries_multiple"
              v-model="account.roles"
              multiple
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option selected>
                Choose Roles
              </option>
              <option value="ROLE_USER">
                User
              </option>
              <option value="ROLE_EMPLOYEE">
                Employee
              </option>
              <option value="ROLE_ADMIN">
                Admin
              </option>
            </select>
            <span
              v-show="errors.roles"
              class="text-xs text-red-700"
            >{{
              errors.roles
            }}</span>
          </div>
          <div class="flex  space-y-2 mb-4 items-center">
            <input
              id="subscribe"
              v-model="account.subscribe"
              type="checkbox"
              name="subscribe"
              class="mr-1 checked:bg-blue-500"
            >
            <label
              class="text-sm font-light break-words"
              for="subscribe"
            >Newsletter
            </label>
          </div>
          <div class="flex  space-y-2 mb-4 items-center">
            <input
              id="subscribe"
              v-model="account.isVerified"
              type="checkbox"
              name="subscribe"
              class="mr-1 checked:bg-blue-500"
            >
            <label
              class="text-sm font-light break-words"
              for="subscribe"
            >Email verified
            </label>
          </div>
          <button
            class="
              w-full
              px-10
              py-2
              bg-gruen
              text-white
              rounded-md
              hover:bg-lila hover:drop-shadow-md
              duration-300
              ease-in
            "
            type="submit"
          >
            Update
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import {
    getAccount, updateAccount
  } from "../../../api/admin/accounts.api";
  import Billing from "../../components/billing.vue";
  
  export default {
    components:{Billing},
    data() {
      return {
        account:null,
        form: {
          headline: null,
          content: null,
          type: null,
          validFrom: null,
          validTo: "null"
        },
        errors: [],
      };
    },
    mounted() {
  
        this.getAccount(this.$route.params.id);
    },
    created() {
    this.$watch(
      () => this.$route.params.id,
      (toParams, previousParams) => {
        this.getAccount(toParams);
      }
    )
  },
    methods: {
      getAccount(id) {
        getAccount(id)
          .then((response) => {
            if (response.data) {
              this.account = response.data.data.account;
              console.log(response.data.data.account);
            }
          })
          .catch((error) => {
            console.log("error" + JSON.stringify(error.data));
            this.errors = error;
          });
        //this.announcements = flatten(this.announcements);
      },
      updateAccount(){
        let data = { ...this.account, ...this.$refs.billing.form}
        updateAccount(this.account.id, data )
          .then((response) => {
            if (response.data) {
              this.account = response.data.data.account;
            }
          })
          .catch((error) => {
            if(error.response.data){
              this.errors = error.response.data.errors;
            }
            
          });
      },
    
    },
  };
  </script>