<template>
  <div class="w-full mt-5 md:col-span-2 md:mt-0">
    <form action="#" method="POST" @submit.prevent="">
      <ul class="grid gap-6 w-full md:grid-cols-2">
        <li>
          <input
            id="address-personal"
            v-model="form.isBusiness"
            type="radio"
            name="address"
            :value="false"
            class="hidden peer"
            required
          />
          <label
            for="address-personal"
            class="
              inline-flex
              justify-between
              items-center
              px-5
              py-3
              w-full
              text-gray-500
              bg-white
              rounded-lg
              border border-gray-200
              cursor-pointer
              dark:hover:text-gray-300
              dark:border-gray-700
              dark:peer-checked:text-gruen
              peer-checked:border-gruen peer-checked:text-gruen
              hover:text-gray-800
              dark:text-gray-200 dark:
              text-gray-400
              hover:bg-gray-100
              dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700
            "
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Personal profile</div>
              <div class="w-full">For individuals</div>
            </div>
            <Icon icon="bx:home" class="text-3xl" />
          </label>
        </li>
        <li>
          <input
            id="address-organization"
            v-model="form.isBusiness"
            type="radio"
            name="address"
            :value="true"
            class="hidden peer"
          />
          <label
            for="address-organization"
            class="
              inline-flex
              justify-between
              items-center
              px-5
              py-3
              w-full
              text-gray-500
              bg-white
              rounded-lg
              border border-gray-200
              cursor-pointer
              dark:hover:text-gray-300
              dark:border-gray-700
              dark:peer-checked:text-gruen
              peer-checked:border-gruen peer-checked:text-gruen
              hover:text-gray-800
              dark:text-gray-200 dark:
              text-gray-400
              hover:bg-gray-100
              dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700
            "
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">Business Profile</div>
              <div class="w-full">For organizations</div>
            </div>
            <Icon icon="bi:building" class="text-3xl" />
          </label>
        </li>
      </ul>
      <div class="w-full overflow-hidden mt-5">
        <div class="p-1">
          <div
            v-if="!form.isBusiness"
            class="
              mb-5
              warning-alert
              bg-orange-500
              flex
              py-2
              px-2
              text-white
              w-full
              box-border
              rounded-md
              text-sm
            "
          >
            <span class="text-xl mr-3"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 36 36"
                class="iconify iconify--clarity"
              >
                <path
                  fill="currentColor"
                  d="M18 21.32a1.3 1.3 0 0 0 1.3-1.3V14a1.3 1.3 0 1 0-2.6 0v6a1.3 1.3 0 0 0 1.3 1.32Z"
                  class="clr-i-outline clr-i-outline-path-1"
                />
                <circle
                  cx="17.95"
                  cy="24.27"
                  r="1.5"
                  fill="currentColor"
                  class="clr-i-outline clr-i-outline-path-2"
                />
                <path
                  fill="currentColor"
                  d="M30.33 25.54L20.59 7.6a3 3 0 0 0-5.27 0L5.57 25.54A3 3 0 0 0 8.21 30h19.48a3 3 0 0 0 2.64-4.43Zm-1.78 1.94a1 1 0 0 1-.86.49H8.21a1 1 0 0 1-.88-1.48l9.74-17.94a1 1 0 0 1 1.76 0l9.74 17.94a1 1 0 0 1-.02.99Z"
                  class="clr-i-outline clr-i-outline-path-3"
                />
                <path fill="none" d="M0 0h36v36H0z" /></svg></span
            ><span>
              You need a "Business Profile" if you live within the EU and not in
              Germany
            </span>
          </div>

          <div class="grid grid-cols-6 gap-6">
            <div v-if="!form.isBusiness" class="col-span-6 sm:col-span-3">
              <label
                for="first-name"
                :class="
                  (errors.firstName ? 'text-red-700 dark:text-red-500' : '') +
                  'block mb-2 text-sm font-medium'
                "
                >First name</label
              >
              <input
                id="first-name"
                v-model="form.firstName"
                type="text"
                name="first-name"
                autocomplete="given-name"
                :class="
                  errors.firstName
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
              />
              <p
                v-if="errors.firstName"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.firstName }}
              </p>
            </div>

            <div v-if="!form.isBusiness" class="col-span-6 sm:col-span-3">
              <label
                for="last-name"
                :class="
                  (errors.lastName ? 'text-red-700 dark:text-red-500' : '') +
                  'block mb-2 text-sm font-medium'
                "
                >Last name</label
              >
              <input
                id="last-name"
                v-model="form.lastName"
                type="text"
                name="last-name"
                autocomplete="family-name"
                :class="
                  errors.lastName
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
              />
              <p
                v-if="errors.lastName"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.lastName }}
              </p>
            </div>

            <div v-if="form.isBusiness" class="col-span-6 sm:col-span-3">
              <label
                for="businessName"
                :class="
                  (errors.businessName
                    ? 'text-red-700 dark:text-red-500'
                    : '') + 'block mb-2 text-sm font-medium'
                "
                >Company Name</label
              >
              <input
                id="businessName"
                v-model="form.businessName"
                type="text"
                name="businessName"
                autocomplete="given-name"
                :class="
                  errors.businessName
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
              />
              <p
                v-if="errors.businessName"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.businessName }}
              </p>
            </div>

            <div v-if="form.isBusiness" class="col-span-6 sm:col-span-3">
              <label
                for="vat-id"
                :class="
                  (errors.vatId ? 'text-red-700 dark:text-red-500' : '') +
                  'block mb-2 text-sm font-medium'
                "
                >Vat ID</label
              >
              <input
                id="vat-id"
                v-model="form.vatId"
                type="text"
                name="vat-id"
                autocomplete="family-name"
                :class="
                  errors.vatId
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
              />
              <p
                v-if="errors.vatId"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.vatId }}
              </p>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label
                for="country"
                :class="
                  (errors.country ? 'text-red-700 dark:text-red-500' : '') +
                  'block mb-2 text-sm font-medium'
                "
                >Country</label
              >
              <select
                id="country"
                v-model="form.country"
                name="country"
                autocomplete="country-name"
                :class="
                  errors.country
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
                @change="getStates()"
              >
                <option
                  v-for="country in countries"
                  :key="country.iso2"
                  :value="country.iso2"
                >
                  {{ country.name }}
                </option>
              </select>
              <p
                v-if="errors.country"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.country }}
              </p>
            </div>
            <div v-if="states.length > 0" class="col-span-6 sm:col-span-3">
              <label
                for="country"
                :class="
                  (errors.region ? 'text-red-700 dark:text-red-500' : '') +
                  'block mb-2 text-sm font-medium'
                "
                >State / Region</label
              >
              <select
                id="country"
                v-model="form.region"
                :required="states.length > 0"
                name="country"
                autocomplete="country-name"
                :class="
                  errors.region
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
              >
                <option
                  v-for="region in states"
                  :key="region.name"
                  :value="region.name"
                >
                  {{ region.name }}
                </option>
              </select>
              <p
                v-if="errors.region"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.region }}
              </p>
            </div>

            <div class="col-span-6">
              <label
                for="street-address"
                :class="
                  (errors.address ? 'text-red-700 dark:text-red-500' : '') +
                  'block mb-2 text-sm font-medium'
                "
                >Street address</label
              >
              <input
                id="street-address"
                v-model="form.address"
                type="text"
                name="street-address"
                autocomplete="street-address"
                :class="
                  errors.address
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
              />
              <p
                v-if="errors.address"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.address }}
              </p>
            </div>
            <div class="col-span-6">
              <label
                for="street-address-addition"
                :class="
                  (errors.addressAddition
                    ? 'text-red-700 dark:text-red-500'
                    : '') + 'block mb-2 text-sm font-medium'
                "
                >Additional information</label
              >
              <input
                id="street-address-addition"
                v-model="form.addressAddition"
                type="text"
                name="street-address-addition"
                autocomplete="street-address-addition"
                :class="
                  errors.addressAddition
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
              />
              <p
                v-if="errors.addressAddition"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.addressAddition }}
              </p>
            </div>

            <div class="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                for="postal-code"
                :class="
                  (errors.postal ? 'text-red-700 dark:text-red-500' : '') +
                  'block mb-2 text-sm font-medium'
                "
                >ZIP / Postal code</label
              >
              <input
                id="postal-code"
                v-model="form.postal"
                type="text"
                name="postal-code"
                autocomplete="postal-code"
                :class="
                  errors.postal
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
              />
              <p
                v-if="errors.postal"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.postal }}
              </p>
            </div>
            <div class="col-span-6 sm:col-span-6 lg:col-span-4">
              <label
                for="city"
                :class="
                  (errors.city ? 'text-red-700 dark:text-red-500' : '') +
                  'block mb-2 text-sm font-medium'
                "
                >City</label
              >
              <input
                id="city"
                v-model="form.city"
                type="text"
                name="city"
                autocomplete="address-level2"
                :class="
                  errors.city
                    ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400'
                    : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                "
              />
              <p
                v-if="errors.city"
                class="mt-2 text-sm text-red-600 dark:text-red-500"
              >
                {{ errors.city }}
              </p>
            </div>
          </div>
          <div class="w-full mt-5">
            <button
              v-if="button"
              type="submit"
              class="
                button
                flex
                mr-2
                text-white
                bg-gruen
                hover:bg-blue-800
                focus:ring-2 focus:ring-lila
                font-medium
                rounded-lg
                text-sm
                px-5
                py-2.5
                float-right
                dark:hover:bg-lila
                focus:outline-none
                dark:focus:ring-lila
              "
              @click="updateAddress()"
            >
              <span class="text-xl inline mr-3">
                <Icon :icon="icon" class="" />
              </span>
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import { getCountries, getStates } from "../../api/regions.api";
import { updateAddress } from "../../api/accounts.api";
import UserService from "../../services/user.service";
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";

export default {
  components: {
    Icon,
  },
  props: ["button", "account", "parentErrors"],
  setup() {
    let { user } = storeToRefs(useUserStore());
    const userStore = useUserStore();
      
    return { user, userStore };
  },
  data() {
    return {
      countries: [],
      states: [],
      form: {},
      isLoading: false,
      errors: {},
      isValid: false,
      icon: "bx:save",
    };
  },
  watch: {
    account(newAccount, oldAccount) {
      this.preloadForm(newAccount);
    },
    parentErrors(newErrors, oldErrors) {
      this.errors = newErrors
    },
  },
  mounted() {
    this.getCountries();
    let user;
    if (this.account) {
      user = this.account;
    } else {
      user = this.user;
    }

    if (this.form.state == null && this.form.country != null) {
      this.getStates();
    }
    this.preloadForm(user);
  },
  methods: {
    preloadForm(user) {
      this.form = {
        isBusiness: false,
        firstName: user.firstName,
        lastName: user.lastName,
        businessName: user.businessName,
        vatId: user.vatId,
        country: user.country,
        address: user.address,
        addressAddition: user.addressAddition,
        city: user.city,
        region: user.region,
        postal: user.postal,
      };
    },
    updateAddress() {
      this.errors=[]
      this.icon = "line-md:loading-twotone-loop";
      updateAddress(this.form)
        .then((response) => {
          if (response.status == 200) {
            UserService.updateAccount();
            this.userStore.fetchUser();
            this.user = UserService.getAccount();
             
            this.$toast.success("Address updated", {
              // optional options Object
            });
            this.$emit("validated", true);
            this.icon = "bx:check-circle";

          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.errors = error.response.data.errors;
          }
          this.$emit("validated", false);
          this.isLoading = false;
        });

    },
    getCountries() {
      getCountries().then((response) => {
        this.countries = response.data;
      });
    },
    getStates() {
      getStates(this.form.country).then((response) => {
        if (response.status == 200) {
          this.states = response.data;
        }
      });
    },
  },
};
</script>

<style>
</style>
