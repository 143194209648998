<template>
<div v-if="user.subscription">
      <div class="w-100 col-2 md:col-4 text-right">
        <button @click="reloadUser" class="button rounded-md p-1.5 text-xl"> <Icon icon="mdi:reload" /> </button>
      </div>
  <div class="grid grid-cols-2 md:grid-cols-4 divide-x divide-gray-900 mx-auto text-gray-900 bg-white rounded-md   dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">

    <div class="mx-auto w-full ">
      <div class="w-full  p-4 text-center ">
        <h3 class="w-full mb-4 text-2xl font-semibold text-left">
          Transactions
        </h3>
            
        <div class=" mt-4 mb-2">
          <p class="text-gray-800 dark:text-gray-200 dark: text-gray-400 text-left ">
            {{ 100-calcPercent(user.subscription.transactionsUsed,user.subscription.plan.transactions_limit) }} % left
          </p>
          <div class="bg-gray-400 w-full h-3 rounded-lg mt-2 overflow-hidden">
            <div :class="'bg-gradient-to-r from-lila to-gruen w-['+calcPercent(user.subscription.addressesUsed,user.subscription.plan.ethaddresses_limit)+'%] h-full rounded-lg shadow-md'" />
          </div>
        </div>
        <h3 class="text-xs uppercase text-left">
          {{ user.subscription.transactionsUsed }} / {{ user.subscription.plan.transactions_limit }}
        </h3>
      </div>
    </div>
    <div class="mx-auto w-full ">
      <div class="p-4 border-r-black-500  text-center">
        <h3 class="mb-4 text-2xl font-semibold text-left">
          Wallets
        </h3>
            
        <div class="mt-4 mb-2">
          <p class="text-gray-800 dark:text-gray-200 dark: text-gray-400 text-left">
            {{ 100-calcPercent(user.subscription.addressesUsed,user.subscription.plan.ethaddresses_limit) }} % left
          </p>
          <div class="bg-gray-400 w-full h-3 rounded-lg mt-2 overflow-hidden">
            <div :class="'bg-gradient-to-r from-lila to-gruen w-['+calcPercent(user.subscription.addressesUsed,user.subscription.plan.ethaddresses_limit)+'%] h-full rounded-lg shadow-md'" />
          </div>
        </div>
        <h3 class="text-xs uppercase text-left">
          {{ user.subscription.addressesUsed }} / {{ user.subscription.plan.ethaddresses_limit }}
        </h3>
      </div>
    </div>
    <div class="mx-auto w-full ">
      <div class="p-4   text-center">
        <h3 class="mb-4 text-2xl font-semibold text-left">
          Requests
        </h3>
            
        <div class="mt-4 mb-2">
          <p class="text-gray-800 dark:text-gray-200 dark: text-gray-400 text-left">
            {{ 100-calcPercent(user.subscription.requestsUsed,user.subscription.plan.requests_limit) }} % left
          </p>
          <div class="bg-gray-400 w-full h-3 rounded-lg mt-2 overflow-hidden">
            <div :class="'bg-gradient-to-r from-lila to-gruen w-['+calcPercent(user.subscription.requestsUsed,user.subscription.plan.requests_limit)+'%] h-full rounded-lg shadow-md'" />
          </div>
        </div>
        <h3 class="text-xs uppercase text-left">
          {{ user.subscription.requestsUsed }} / {{ user.subscription.plan.requests_limit }}
        </h3>
      </div>
    </div>
    <div class="mx-auto w-full ">
      <div class="p-4 border-r-black-500 border-gray-100 text-center">
        <h3 class="mb-4 text-2xl font-semibold text-left">
          Webhooks
        </h3>
            
        <div class="mt-4 mb-2">
          <p class="text-gray-800 dark:text-gray-200 dark: text-gray-400 text-left">
            {{ 100-calcPercent(user.subscription.ipnsUsed,user.subscription.plan.ipns_limit) }} % left
          </p>
          <div class="bg-gray-400 w-full h-3 rounded-lg mt-2 overflow-hidden">
            <div :class="'bg-gradient-to-r from-lila to-gruen w-['+calcPercent(user.subscription.ipnsUsed,user.subscription.plan.ipns_limit)+'%] h-full rounded-lg shadow-md'" />
          </div>
        </div>
        <p class="text-xs uppercase text-left">
          {{ user.subscription.ipnsUsed }} / {{ user.subscription.plan.ipns_limit }}
        </p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import { Icon } from "@iconify/vue";
  import { useUserStore } from "@/store/user";
  import { storeToRefs } from 'pinia'

export default {
      components: {
      Icon,
    },
    setup() {
      const userStore = useUserStore();
      const { user } = storeToRefs(useUserStore())
      return { user, userStore };
    },
    methods:{
      reloadUser(){
              this.userStore.fetchUser();
      },
        calcPercent(lower,higher){
            let result;

            if(higher == 0 ){
                result = 100;
            }
            else if(lower == 0 ){
                result = 0;
            }
            else{
                result =(100/(higher)*lower).toFixed(0);
            }

            return result;
        }
    }
}
</script>

<style>

</style>