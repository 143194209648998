<template>
  <div
    class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow"
  >
    <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
      Latest Transactions
    </h2>
    <p class="text-gray-400 font-lexend font-normal">
      This is a list of latest transactions
    </p>
    <div 
      id="scroll-table" 
      class="wrapping-table mt-10  overflow-scroll"
    >
      <table
        class="w-full text-sm text-left text-gray-500 dark:text-gray-400 overflow-scroll"
      >
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th
              scope="col"
              class="uppercase px-6 py-3"
            >
              Transaction
            </th>
            <th
              scope="col"
              class="uppercase px-6 py-3"
            >
              Date & Time
            </th>
            <th
              scope="col"
              class="uppercase px-6 py-3"
            >
              Function
            </th>
            <th
              scope="col"
              class="uppercase px-6 py-3"
            >
              Addresses
            </th>
            <th
              scope="col"
              class="uppercase px-6 py-3"
            >
              Amount
            </th>
            <th
              scope="col"
              class="uppercase px-6 py-3"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="request in requests"
            :key="request.id"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
          >
            <td class="px-6 py-4">
              {{ request.id }}
            </td>
            <td class="px-6 py-4">
              {{ moment(request.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
            </td>
            <td class="px-6 py-4">
              {{ request.function }}
            </td>
            <td class="px-6 py-4">
              <span v-if="request.fromaddr">From: {{ request.fromaddr }}</span><br>
              <span v-if="request.toaddr">To: {{ request.toaddr }}</span><br>
              <span v-if="request.contractaddress">Contract: {{ request.contractaddress }}</span><br>
              <span v-if="request.tokenid">Token: {{ request.tokenid }}</span>
            </td>
            <td class="px-6 py-4">
              {{ request.amount }}
            </td>
            <td class="px-6 py-4">
              <span
                v-if="request.httpStatus <= '299'"
                class="text-green-800 bg-green-300 px-3 py-1 rounded-md"
              >
                {{ request.httpStatus }}
              </span>
                
              <span
                v-else
                class="text-red-800 bg-red-300 px-3 py-1 rounded-md"
              >
                {{ request.httpStatus }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Scrollbar from "smooth-scrollbar";

import {getRequests} from "../../api/requests.api";

export default {

  data() {
    return {
      requests: []

    };
  },
  mounted(){
    Scrollbar.init(document.querySelector("#scroll-table"));
    this.getRequests();
  },
  methods:{
  getRequests(type){

    getRequests(1,10,"")
       .then((response) => {
        if (response.status == 200) {
                if (response.data.data.requests.length > 0) {
                  this.requests = JSON.parse(response.data.data.requests);
                  
                }
        }
      })

    }
  }

};
</script>

<style>


</style>