<template>
<div class="p-4 dashboard">

         <div

    class="text-center  dark:text-white bg-white dark:bg-gray-800 p-5 rounded-md box-border shadow"
  >
    <div>
      <Vue3Lottie
        :animation-data="RocketJSON"
        :height="200"
        :width="200"
      />
    </div>
    <h1 class="text-3xl mb-2">Thank You!</h1>
    <h2>Your oder will be processed within the next minute.</h2>
    <h2>If your membership status will not be upgraded, please log out and sign in again.</h2>
    <div class="mt-10">
      <button
        @click="go"
        class=" button
        w-full
        px-10
        py-2
        my-30
        bg-gruen
        text-white
        rounded-md
        hover:bg-lila hover:drop-shadow-md
        duration-300
        ease-in
      "
      > Go to Dashboard</button>
    </div>
  </div>
  </div>

</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

import RocketJSON from '../assets/lotties/rocket.json'
import { useUserStore } from "@/store/user";
 import UserService from '@/services/user.service';
  import { login, confirm, twoFactorCheck } from "@/api/accounts.api";
  import { storeToRefs } from 'pinia'

export default {
    components:{ Vue3Lottie },
     setup() {
      const userStore = useUserStore();
      const { user } = storeToRefs(useUserStore())
      return { user, userStore };
    },
  
    data() {
    return {
      countries: null,
      RocketJSON
    };
  },
  methods:{
    reloadUser(){
      this.userStore.fetchUser();
    },
    go(){
      this.reloadUser();
      this.$router.push('/');
    }
  },
   mounted() {
        this.reloadUser();

   }
}
</script>
<style lang="">
    
</style>