<template>
    <div class="text-center">
     
     <div v-if="errorMessage">
        <p>{{errorMessage}}</p><br>
        <a href="/" class="button p-4">Continue to Dashboard</a>
     </div>
     <div v-if="isSuccess">
        You activated the referral code {{$route.params.id}}
        <p>Since you are logged in, the referral code is automatically linked to your account.</p><br>
        <a href="/" class="button p-4">Continue to Dashboard</a>
     </div>
    <div v-if="!user.id" class="text-xl">

        <p class="text-center  pt-6">
            To apply your referral code, you need to <br><a
          :href="'/register?referral='+$route.params.id"
          class="text-lila font-light underline hover:text-gruen"
        > register a free account </a>
      </p>
        <br>
        Already have an account? log in and <br><b>open the link again!</b>
    </div>
    </div>
  </template>
  
  <script>
  import {
    useUserStore
} from "@/store/user";
import {
    storeToRefs
} from 'pinia'
import { updateReferralCode } from "@/api/accounts.api"

  export default {
      name: "Referral",
      setup() {

const {
    user
} = storeToRefs(useUserStore())
return {
    user
};
},
  
      data() {
        return {
          isSuccess: false,
          errorMessage: false,
          referrals:[]
        }
      },
  
      mounted() {
        if(this.user.id){
            updateReferralCode(this.$route.params.id).then((response) => {
                    if (response.status == 200) {
                        this.isSuccess = true
                    }
                }).catch((error) => {
                    this.errorMessage = error.response.data
                });
        }

      },
      methods: {
        
      
      }
  
  }
  </script>
  
  <style>
  
  </style>
  
  
  