import axiosClient  from "../services/axios.service";

export function getAddresses(chain){
    return axiosClient.get('/v2/'+chain+'/addresses');
}

export function getBalance(chain, address){
    return axiosClient.get('/v2/'+chain+'/balances/'+address);
}

export function createAddress(chain, data){
    return axiosClient.post('/v2/'+chain+'/addresses', JSON.stringify(data));
}

export function deleteAddress(chain, address, data){
    return axiosClient.delete('/v2/'+chain+'/addresses/'+address, data);
}

export function importAddress(chain, data){
    return axiosClient.post('/v2/'+chain+'/addresses/import', data);
}

export function migrate(apiKey){
    return axiosClient.post('/api/migration/'+apiKey);
}