<template>
  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl  text-gray-800 dark:text-gray-200 font-medium">
        Manage Subscriptions
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5" />

    <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
        Subscriptions
      </h2>

      <div class="wrapping-table mt-10">
        <button @click="getActiveSubscriptions" type="button" class="button p-2.5 rounded-md mb-4 mr-2">Filter only active</button>
        <button @click="getPayedSubscriptions" type="button" class="button p-2.5 rounded-md mb-4">Filter payed</button>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                ID
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                User
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Plan
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                status
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Crated at
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Ends At
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Billing Period / Price
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="subscription in subscriptions"
              :key="subscription.id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
            >
              <td class="px-6 py-4">
                {{ subscription.id }}
              </td>
              <td class="px-6 py-4">
                {{ subscription.account.username }}
              </td>
              <td class="px-6 py-4">
                {{ subscription.plan.name }}
              </td>
              <td class="">
                  <div v-if="subscription.isActive" class="w-fit rounded-md p-1 bg-green-500 text-white"> active </div>
                  <div v-else class="w-fit rounded-md p-1 bg-red-500 text-white"> inactive </div>
                
              </td>
              <td class="px-6 py-4">
                {{ subscription.createdAt }}
              </td>
              <td class="px-6 py-4">
                {{ subscription.endsAt }}
              </td>
              <td class="px-6 py-4">
                <div v-if="subscription.plan.isFree" class="w-fit rounded-md p-1 bg-gray-500 text-white"> free plan </div>
                  <div v-else-if="subscription.plan.yearly" class="w-fit rounded-md p-1 bg-blackberry-500 text-white"> €{{subscription.plan.plan_pricin_yearly}}/year </div>
                  <div v-else class="w-fit rounded-md p-1 bg-teal-500 text-white"> €{{subscription.plan.plan_pricing}}/month </div>

              </td>
              <td class="px-6 py-4">
                <a
                  :href="'/admin/subscription/'+subscription.id"
                  class="text-white bg-lila px-3 py-1 ml-1 rounded-md"
                >EDIT</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>


import { getSubscriptions, getActiveSubscriptions } from '../../../api/admin/subscriptions.api'

export default {
    components: {

    },

    data() {
        return {
            columns2: [{
                    label: "ID",
                    field: "id"
                },
                {
                    label: "Avatar",
                    field: "avatar"
                },
                {
                    label: "Username",
                    field: "username"
                },
                {
                    label: "Roles",
                    field: "roles"
                },
                {
                    label: "Country",
                    field: "country"
                },
                {
                    label: "Is Verified",
                    field: "isVerified"
                },
                {
                    label: "Registered at",
                    field: "registered_at"
                }
            ],
            subscriptions: [],
            errors: "",
            columns: [{
                prop: "name"
            }, {
                prop: "details"
            }],
            rows: [{
                name: "1",
                details: "Item 1",
            }]
        };
    },
    mounted() {
        this.getSubscriptions();
    },
    
    methods: {
        getSubscriptions() {
            getSubscriptions()
                .then(response => {
                    if (response.data) {

                        this.subscriptions = response.data.data.subscriptions;
                        //console.log(response.data.data.subscriptions);
                    }
                })
            //this.subscriptions = flatten(this.subscriptions);
        },
        getActiveSubscriptions() {
          getActiveSubscriptions()
                .then(response => {
                    if (response.data) {

                        this.subscriptions = response.data.data.subscriptions;
                        //console.log(response.data.data.subscriptions);
                    }
                })
            //this.subscriptions = flatten(this.subscriptions);
        },
        getPayedSubscriptions(){
          this.subscriptions = this.subscriptions.filter(function (el) {
            return !el.plan.isFree
          });
        }
    }
}
</script>
