<template>
  <!-- this header -->
  <header class="bg-white dark:bg-gray-800 p-2 border-b-2 dark:border-gray-700">
    <div class="wrap-header flex gap-2 items-center order-1 justify-between flex-wrap">
      <button
        class="text-gray-500 lg:hidden ml-3 block justify-self-start"
        @click="sidebarToggle"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="2em"
          height="2em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 16 16"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M2.75 12.25h10.5m-10.5-4h10.5m-10.5-4h10.5"
          />
        </svg>
      </button>
      <div class="shrink sm:order-last order-last items-center">
        <h2
          v-if="!alert" 
      
          class="text-md text-indigo-800 ml-3 lg:block hidden px-5 p-2 rounded-md bg-indigo-200"
        >
          Welcome back, {{ user.username }}
        </h2>
        <div
          v-else
          class="flex  flex-nowrap  items-center justify-self-start"
        >
          <div
            v-if="(alerts.length>1)"
            class="mr-2"
          >
            <span
              class=" text-4xl text-gray-500 text-gray-400 "
              @click="switchAlert('next')"
            >
              <Icon icon="material-symbols:switch-right" />
            </span>
          </div>
          <div>
            <div 
              :class="alert.type +' '+ alertColor +' flex py-3 px-5 text-white w-full box-border rounded-md'"
            >
              <span class="text-2xl mr-3">
                <Icon
                  v-if="alert.type == 'danger-alert'"
                  icon="ic:outline-dangerous"
                />
                <Icon
                  v-if="alert.type == 'warning-alert'"
                  icon="clarity:warning-line"
                />
                <Icon
                  v-if="alert.type == 'success-alert'"
                  icon="bi:check-circle"
                />
                <Icon
                  v-if="alert.type == 'primary-alert'"
                  icon="bytesize:info"
                />
              </span>
              <span> <b> {{ alert.headline }}: </b> {{ alert.content }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mr-5 flex order-2 md:order-last  justify-self-end">
        <button
          id="theme-toggle"
          ref="themeToggle"
          type="button"
          class="text-gray-500 mr-5 dark:text-gray-400 h outline-none rounded-lg text-sm p-2.5"
        >
          <svg
            id="theme-toggle-light-icon"
            ref="light"
            class="hidden w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
            />
          </svg>
          <svg
            id="theme-toggle-dark-icon"
            ref="dark"
            class="hidden w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
              fill-rule="evenodd"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <button class="mr-5 text-2xl text-gray-500">
          <Icon icon="clarity:notification-line" />
        </button>
        <button
          @click="menuToggle"
          @blur="menuToggleBlur"
        >
          <div
            class="user-avatar flex hover:bg-gray-100 dark:hover:bg-gray-700 p-1 cursor-pointer rounded-md"
          >
            <img
           
              :src="user.avatar"
              class="rounded-full mr-4 w-10 h-10 p-1 ring-1 ring-gray-300 dark:ring-gray-500"
              alt=""
            >
            <span class="text-md mt-4 text-gray-300"><Icon icon="bi:caret-down-fill" /></span>
          </div>
        </button>

        <transition name="fade">
          <div
            v-show="menu"
            id="dropdownSmall"
            class="block absolute right-10 mt-12 z-10 w-44 border dark:border-gray-700 bg-white dark:bg-gray-800 rounded divide-y dark:divide-gray-700 divide-gray-100 shadow"
          >
            <div class="py-3 px-4 text-sm text-gray-800 dark:text-gray-200">
              <div>Logged As</div>
              <div
                v-if="user"
                class="font-medium truncate"
              >
                {{ user.username }}
              </div>
            </div>
            <ul
              class="py-1 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownSmallButton"
            >
              <li>
                <a
                  href="/settings"
                  class="block py-2 px-4 hover:bg-primary hover:text-white"
                >Settings</a>
              </li>
              <li>
                <a
                  href="/referrals"
                  class="block py-2 px-4 hover:bg-primary hover:text-white"
                >Referrals</a>
              </li>
            </ul>
            <div class="py-1">
              <a
                href="#"
                class="block py-2 px-4 text-sm text-gray-700 dark:text-gray-200 hover:bg-primary hover:text-white"
                @click="logout"
              >Sign out</a>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>

  import { Icon } from "@iconify/vue";
  import { useUserStore } from "@/store/user";
  import { storeToRefs } from 'pinia'
  import UserService from "@/services/user.service";
  import { getAnnouncements } from "@/api/announcements.api";

export default {
  
    components: {
      Icon,
    },
    setup() {

      const { user } = storeToRefs(useUserStore())
      return { user };
    },
    data() {
      return {
        menu: false,
        alert: null,
        alerts:[],
        index: 0,
        alertColor: null,
      };
    },
    
    computed: {

    },
    mounted() {
      this.getAnnouncements();

      var themeToggleDarkIcon = this.$refs.dark;
      var themeToggleLightIcon = this.$refs.light;

      // Change the icons inside the button based on previous settings
      if (
        localStorage.getItem("color-theme") === "dark" ||
        !("color-theme" in localStorage)
      ) {
        document.documentElement.classList.add("dark");
        themeToggleLightIcon.classList.remove("hidden");
      } else {
        document.documentElement.classList.remove("dark");
        themeToggleDarkIcon.classList.remove("hidden");
      }
      /*
      // if set via local storage previously
      if (!localStorage.getItem("color-theme")) {
        if (localStorage.getItem("color-theme") === "light") {
          document.documentElement.classList.add("dark");
          localStorage.setItem("color-theme", "dark");
        } else {
          document.documentElement.classList.remove("dark");
          localStorage.setItem("color-theme", "light");
        }

      //if NOT set via local storage previously
      } else {
        if (document.documentElement.classList.contains("dark")) {
          document.documentElement.classList.remove("dark");
          localStorage.setItem("color-theme", "light");
        } else {
          document.documentElement.classList.add("dark");
          localStorage.setItem("color-theme", "dark");
        }
      }*/

      var themeToggleBtn = this.$refs.themeToggle;

      themeToggleBtn.addEventListener("click", function () {
        // toggle icons inside button
        themeToggleDarkIcon.classList.toggle("hidden");
        themeToggleLightIcon.classList.toggle("hidden");

        // if set via local storage previously
        if (localStorage.getItem("color-theme")) {
          if (localStorage.getItem("color-theme") === "light") {
            document.documentElement.classList.add("dark");
            localStorage.setItem("color-theme", "dark");
          } else {
            document.documentElement.classList.remove("dark");
            localStorage.setItem("color-theme", "light");
          }

          // if NOT set via local storage previously
        } else {
          if (document.documentElement.classList.contains("dark")) {
            document.documentElement.classList.remove("dark");
            localStorage.setItem("color-theme", "light");
          } else {
            document.documentElement.classList.add("dark");
            localStorage.setItem("color-theme", "dark");
          }
        }
      });
    },
    methods: {
      switchAlert(dir){
        if(dir == 'next'){
          this.index++;
          if(this.index == this.alerts.length){
            this.index=0;
            this.alert = this.alerts[0];
          }
            this.alert = this.alerts[this.index];
        }else{
          this.index--;
          if(this.index < 0){
            this.alert = this.alerts[this.alerts.length-1];
          }
            this.alert = this.alerts[this.index];

        }
        this.alertColor= (this.alert.type=='danger-alert'?'bg-red-500':(this.alert.type=='warning-alert'?'bg-yellow-500':(this.alert.type=='success-alert'?'bg-green-500':'bg-gruen')));

      },
      getAnnouncements(){
        getAnnouncements() 
        .then((response) => {
          if (response.status == 200) {
            if (response.data.data.announcements.length > 0) {
             this.alerts = response.data.data.announcements;
             this.alert=this.alerts[0];
             this.alertColor= (this.alert.type=='danger-alert'?'bg-red-500':(this.alert.type=='warning-alert'?'bg-yellow-500':(this.alert.type=='success-alert'?'bg-green-500':'bg-gruen')));
            }
            
          }
        });
      },
      logout: function(){
        UserService.logout();
      },
      menuToggle: function () {
        this.menu = !this.menu;
      },
      menuToggleBlur: function () {
        this.menu = false;
      },
      sidebarToggle: function () {
        document.querySelector(".flex-sidebar").classList.remove("hidden");
      },
      
    },
  };
</script>
