<template>
  <!-- Pricing Card -->
  <div
    class="
                    flex flex-col
                    p-6
                    w-[100%]
                    px-10
                    mx-auto
                    max-w-100
                    text-center text-gray-900
                    bg-white
                    rounded-lg
                    border border-gray-100
                    shadow
                    dark:border-gray-600
                    xl:p-8
                    dark:bg-gray-800 dark:text-white
                  " 
  >
    <h3 class="mb-4 text-2xl font-semibold">
      {{ plan.name }}
    </h3>
                  

               
    <div
      v-if="yearly && plan.plan_pricing != 0 && !hidebutton"
      class="flex justify-center items-baseline mt-8"
    >
      <p
        class="bg-green-600 rounded-md text-xs py-1 px-4 text-white"
        :v-if="(plan.plan_pricing*12)!=plan.plan_pricing_yearly"
      >
        2 month free
      </p>
    </div>
    <div
      v-if="yearly && plan.plan_pricing == 0 && !hidebutton"
      class="flex justify-center items-baseline mt-8"
    >
      <p class="rounded-md text-xs py-1 px-4 text-white dark:text-gray-800 dark:text-gray-200">
        0
      </p>
    </div>
    <div
      v-if="!yearly"
      class="flex justify-center items-baseline my-8"
    >
      <span class="mr-2 text-4xl font-extrabold">€{{ plan.plan_pricing }}</span>
      <span class="text-gray-500 dark:text-gray-400">/month</span>
    </div>
    <div
      v-else
      :class="'flex justify-center items-baseline mt-2 mb-8'"
    >
      <span class="mr-2 text-4xl font-extrabold">€{{ plan.plan_pricing_yearly }}</span>
      <span class="text-gray-500 dark:text-gray-400">/year</span> 
    </div>
                  



                  
    <!-- List -->
    <ul
      role="list"
      class="mb-8 space-y-4 text-left"
    >
      <li class="flex items-center space-x-3">
        <svg
          class="flex-shrink-0 w-5 h-5 text-gruen dark:text-gruen"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Api keys: 
          <span class="font-semibold">{{ th(plan.apikeys_limit) }}</span> </span>
      </li>
      <li class="flex items-center space-x-3">
        <svg
          class="flex-shrink-0 w-5 h-5 text-gruen dark:text-gruen"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Transactions: 
          <span class="font-semibold">{{ th(plan.transactions_limit) }}</span> </span>
      </li>
      <li class="flex items-center space-x-3">
        <svg
          class="flex-shrink-0 w-5 h-5 text-gruen dark:text-gruen"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Wallet addresses:
          <span class="font-semibold">{{ th(plan.ethaddresses_limit) }}</span></span>
      </li>
      <li class="flex items-center space-x-3">
        <svg
          class="flex-shrink-0 w-5 h-5 text-gruen dark:text-gruen"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Webhooks/IPN's:
          <span class="font-semibold">{{ th(plan.ipns_limit) }}</span></span>
      </li>
      <li class="flex items-center space-x-3">
        <svg
          class="flex-shrink-0 w-5 h-5 text-gruen dark:text-gruen"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Requests:
          <span class="font-semibold">{{ th(plan.requests_limit) }}</span></span>
      </li>
      <li class="flex items-center space-x-3">
        <svg
          class="flex-shrink-0 w-5 h-5 text-gruen dark:text-gruen"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Support response time 
          <span class="font-semibold">{{ th(plan.sla) }} h</span> </span>
      </li>
    </ul>
    <a
      v-if="!hidebutton"
      :href="'/order/'+plan.id+'/'+(yearly?'yearly':'monthly')"
      class="
                      text-white
                      button
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      disabled:opacity-25"
      
                    
    > Get started </a> 
  </div>
</template>

<script>
export default {
    props:['plan','yearly', 'hidebutton', 'disable'],
    methods:{
      th(num){
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    }
}
</script>

<style>

</style>