<template>
  <div class="container  gap-6">
    <h2 class="text-xl mb-3">
      Update Password
    </h2>
  
    <div
      v-if="errors"
      class="danger-alert bg-red-500 flex py-3 px-5 text-white w-full box-border rounded-md"
    >
      <span class="text-2xl mr-3"><svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        class="iconify iconify--ic"
      ><path
        fill="currentColor"
        d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8zm-4.17-7.14L12 10.59L9.17 7.76L7.76 9.17L10.59 12l-2.83 2.83l1.41 1.41L12 13.41l2.83 2.83l1.41-1.41L13.41 12l2.83-2.83l-1.41-1.41z"
      /></svg>
      </span>
      <span> {{ errors }} </span>
    </div>
    <form @submit.prevent="send">
      <label
        class="block mb-2 text-sm font-medium"
        for="new-password"
      >New password</label>
      <input
        id="new-password"
        v-model="form.password"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
        type="password"
        placeholder="Your Password"
        name="new-password"
      >
      <label
        class="mt-3 block mb-2 text-sm font-medium"
        for="email"
      >Repeat new password</label>
      <input
        id="repeat-new-password"
        v-model="form.password_repeat"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
        type="password"
        placeholder="Your Password"
        name="repeat-new-password"
      >
   
      <button
        class="button mt-3 w-full px-10 py-2  text-white rounded-md
            duration-300 ease-in"
        type="submit"
      >
        Update password
      </button>
    </form>
  </div>
</template>

<script>

import {updatePassword} from '../../api/accounts.api'


export default {
    name: "UpdatePassword",

  data() {
    return {
        form: {
          password: "",
          password_repeat: ""
        },
        errors:null
    }},
    mounted(){
  
        
        
    },
  methods: {
    send(){


        updatePassword(this.form)
          .then(response => {
            this.$toast.success(
                            "Password updated",{
                                // optional options Object
                            }
                        );
            this.form.password="";
            this.form.password_repeat="";

          }).catch(error => {
            console.log(error.response.data);
            this.errors = error.response.data;
            
          });

        }
    
}
};
</script>

<style>
</style>