import axiosClient  from "../../services/axios.service";

export function getPlans(){
    return axiosClient.get('/api/admin/plans');
}

export function getPlan(id){
    return axiosClient.get('/api/admin/plan/'+id);
}

export function deletePlan(id){
    return axiosClient.delete('/api/admin/plan/'+id);
}

export function searchPlans(data){
    return axiosClient.post('/api/admin/plans', data);
}

export function updatePlan(id, data){
    return axiosClient.post('/api/admin/plan/'+id, data);
}

export function createPlan(data){
    return axiosClient.post('/api/admin/plan', data);
}




