<template>
  <div class="container  gap-6">
    <h2 class="text-xl mb-3">
      Update Whitelist
    </h2>
  
    <div
      v-if="errors"
      class="danger-alert bg-red-500 flex py-3 px-5 text-white w-full box-border rounded-md"
    >
      <span class="text-2xl mr-3"><svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        class="iconify iconify--ic"
      ><path
        fill="currentColor"
        d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8zm-4.17-7.14L12 10.59L9.17 7.76L7.76 9.17L10.59 12l-2.83 2.83l1.41 1.41L12 13.41l2.83 2.83l1.41-1.41L13.41 12l2.83-2.83l-1.41-1.41z"
      /></svg>
      </span>
      <span> {{ errors }} </span>
    </div>
    <div class="flex content-center">
      <Toggle
        v-model="whitelist"
        @change="toggle"
        :classes="{
          toggleOn: 'bg-gruen border-gruen justify-start text-white',
        }"
      />
      <div
        class="
                  mb-5
                  ml-2
                  font-light
                  text-gray-500
                  dark:text-gray-400 
                "
      />
     
      <span v-if="whitelist">Enabled</span>
      <span v-else> Disabled </span><br>
    </div>
  </div>
  <div v-if="whitelist">
    <form @submit.prevent="updateWhitelist">
      <label
        class="block my-2 text font-medium"
        for="new-password"
      >Whitelisted IP's</label>
      
      <textarea 
        id="whitelist"
        v-model="form.ips"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 w-full xl:w-1/2 focus:border-blue-500 block  p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
        type="textarea"
        rows="8" 
        placeholder="10.10.10.1 "
        name="whitelist"
      />
      <p class="text-sm">
        *You can use ipv4 and ipv6 addresses, use a new line for each address
      </p>
   

      
   
      <button
        class=" mt-3  px-10 py-2 bg-gruen text-white rounded-md
            hover:bg-lila hover:drop-shadow-md duration-300 ease-in"
        type="submit"
      >
        Update Whitelist
      </button>
    </form>
  </div>
</template>

<script>
import Toggle from "@vueform/toggle";
import { getWhitelist, getWhitelistState, updateWhitelist, toggleWhitelist } from "@/api/accounts.api";

export default {
    components:{
        Toggle
    },
    data(){
        return{
            form:{ips:null},
            whitelist: false
        }
    },
    mounted(){
        this.getWhitelistState();
        this.getWhitelist();
    },
    methods:{
        toggle(){
            toggleWhitelist()
            .then((response) => {
            if (response.data) {
                this.whitelist = response.data.data.whitelist;
                //console.log(response.data.data.announcements);
            }
            })
            .catch((error) => {
            console.log("error" + JSON.stringify(error.data));
            this.errors = error;
            });
        },
        getWhitelistState(){
            getWhitelistState()
            .then((response) => {
            if (response.data) {
                this.whitelist = response.data.data.whitelist;
                //console.log(response.data.data.announcements);
            }
            })
            .catch((error) => {
            console.log("error" + JSON.stringify(error.data));
            });
        },
        updateWhitelist(){
            const data = {whitelist: this.form.ips.split('\n')};
            console.log(data);
            updateWhitelist(data)
            .then((response) => {
            if (response.data) {
                //this.whitelist = response.data.data.whitelist;
                //console.log(response.data.data.announcements);
                this.$toast.success("IP whitelist updated", {
                    // optional options Object
                });
                this.getWhitelist();
            }
            })
            .catch((error) => {
            console.log("error" + JSON.stringify(error.data));
            });
        },
        getWhitelist(){
            getWhitelist()
            .then((response) => {
            if (response.data) {
                let data = [];
                response.data.data.whitelists.forEach(element => {
                    data.push(element.ip)
                });
                this.form.ips = data.join('\n'); 
                //console.log(response.data.data.announcements);
            }
            })
            .catch((error) => {
            console.log("error" + JSON.stringify(error.data));
            });
        }
    }

}
</script>

<style>

</style>