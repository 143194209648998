<template>
  <div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid gap-2 mt-5">
      <div class="card w-full rounded-md">
        <section class="">
          <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h2
                class="
                  mb-4
                  text-4xl
                  tracking-tight
                  font-extrabold
                  text-gray-900
                  dark:text-white
                "
              >
                Designed for your business
              </h2>
              <p
                class="
                  mb-5
                  font-light
                  text-gray-500
                  sm:text-xl
                  dark:text-gray-400
                "
              >
                Here at Chaingateway we focus on markets where technology,
                innovation, and capital can unlock long-term value and drive
                economic growth.
              </p>
              <p
                class="
                  mb-5
                  font-light
                  text-gray-500
                  sm:text-xl
                  dark:text-gray-400 
                "
              >
                <span v-if="yearly">Monthly</span>
                <span v-else> Yearly </span><br>
                <Toggle
                  v-model="yearly"
                  :classes="{
                    toggleOn: 'bg-gruen border-gruen justify-start text-white',
                  }"
                />
              </p>
            </div>
            <div
              id="pricing"
              ref="pricing"
              class="
                space-y-8
                lg:grid lg:grid-cols-3
                sm:gap-6
                xl:gap-10
                lg:space-y-0
              "
            >
              <pricing-table
                v-for="plan in featuredPlans"
                :key="plan.id"
                :yearly="yearly"
                :plan="plan"
                :hidebutton="active == plan.name"
              />
            </div>
            

            <div class="w-full mx-auto center mt-3">
              <menu-accordion>
                <template #icon>
                  <Icon icon="akar-icons:plus" />
                </template>
                <template #title>
                  Show all plans
                </template>
                <template #content>
                  <div
                    class="
                space-y-8
                lg:grid lg:grid-cols-3
                sm:gap-6
                xl:gap-10
                lg:space-y-0
                mt-3
              "
                  >
                    <pricing-table
                      v-for="plan in nonFeaturedPlans"
                      :key="plan.id"
                      :yearly="yearly"
                      :plan="plan"
                      :hidebutton="active == plan.name"
                    />
                    
                  
                  </div>
                </template>
              </menu-accordion>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Toggle from "@vueform/toggle";
import PricingTable from "../components/PricingTable.vue";
import MenuAccordion from "../components/MenuAccordion.vue";
import { getPlans } from "../api/plans.api";

export default {
  name: "Plans",
  props:['active'],
  components: {
    Icon,
    Toggle,
    PricingTable,
    MenuAccordion
  },
  data() {
    return {
      plans: [],
      yearly: false,
    };
  },
  computed: {
    featuredPlans() {
      return this.plans.filter(function (plan) {
        return plan.isFeatured === true;
      });
    },
    nonFeaturedPlans() {
      return this.plans.filter(function (plan) {
        return plan.isFeatured === false;
      });
    },
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    getPlans() {
      this.isLoading = true;
      getPlans()
        .then((response) => {
          if (response.status == 200) {
            if (response.data.data.plans.length > 0) {
              this.plans = JSON.parse(response.data.data.plans);
            } else {
            }

            this.isLoading = false;
          }
        });
    },
  },
};
</script>

<style src="@vueform/toggle/themes/default.css">
</style>
