<template>
  <div>
    <div class="dashboard p-4">
      <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <a href="#" class="
                  inline-flex
                  items-center
                  text-sm
                  font-medium
                  text-gray-700
                  hover:text-gray-900
                  dark:text-gray-400 dark:hover:text-white
                ">
              <Icon icon="bxs:rocket" class="mr-2" />

              Subscription
            </a>
          </li>
        </ol>
      </nav>
      <!-- end nav -->
      <div class="mt-5 w-full">
        <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
          <Icon icon="bxs:rocket" class="mr-2 text-3xl" />
          No Subscription found
        </h1>
      </div>



      <div class="wrapper-card grid gap-2 mt-5 " v-if="!user.subscription">
        <div id="alert-additional-content-2" class="p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
  <div class="flex items-center">
    <svg class="flex-shrink-0 w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
    </svg>
    <span class="sr-only">Info</span>
    <h3 class="text-lg font-medium ml-2">Your membership exceeded. Please subscribe to one of our plans to continue using our product.</h3>
  </div>


</div>
        <plans />
      </div>

    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Plans from "./plans.vue";
import { useUserStore } from "@/store/user";
import { storeToRefs } from 'pinia'

export default {
  name: "NewSubscription",
  props: ['scrollbar'],
  components: {
    Icon,
    Plans,

  },
  setup() {

    const { user } = storeToRefs(useUserStore())
    return { user };
  },
  data() {
    return {


    };
  },
}
</script>

<style></style>