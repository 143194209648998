<template>
  <div class="container">
    <h2 class="text-xl">
      Two Factor Authentication
    </h2>

    <div
      v-if="enabled"
      class=""
    >
      2FA already enabled.

      <button
        type="button"
        class="button mt-3 flex mr-2 text-white font-medium rounded-lg text-sm px-5 py-2.5"
        @click="disable"
      >
        Disable 2FA
      </button>
    </div>
    <div
      v-else
      class=""
    >
      <p class="mb-3">
        To Enable 2fa:
        <ul>
          <li>1. Scan the QR Code with your authenticator app </li>
          <li>2. Type in the code in the validation input field </li>
          <li>3. Hit confirm</li>
        </ul>
      </p>
      <div v-if="!success">
        <img
          v-if="qr"
          :src="qr"
          title="QR Code"
        >

        <form @submit.prevent="confirm">
          <label
            for="first-name"
            class="block text-sm font-medium mt-3 mb-3"
          >Enter OTP Code to confirm Configuration</label>
          <input
            id="first-name"
            v-model="code"
            type="text"
            name="first-name"
            class="lg:w-1/3 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          >
          <button
            type="submit"
            class="button mt-3 flex mr-2 text-white font-medium rounded-lg text-sm px-5 py-2.5 "
          >
            Validate & save
          </button>


          <div
            v-if="message"
            class="danger-alert bg-red-500 flex py-3 px-5 text-white w-full box-border rounded-md mt-3 lg:w-1/3 "
          >
            <span class="text-2xl mr-3"><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
              class="iconify iconify--ic"
            ><path
              fill="currentColor"
              d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8zm-4.17-7.14L12 10.59L9.17 7.76L7.76 9.17L10.59 12l-2.83 2.83l1.41 1.41L12 13.41l2.83 2.83l1.41-1.41L13.41 12l2.83-2.83l-1.41-1.41z"
            /></svg>
            </span>
            <span> {{ message }} </span>
          </div>
        </form>
      </div>
      <div v-else>
        <div class="success-alert flex bg-green-500 py-3 px-5 text-white w-full box-border rounded-md">
          <span class="text-2xl mr-3"><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16"
            class="iconify iconify--bi"
          ><g fill="currentColor"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" /></g></svg></span><span> {{ message }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {get2faSecret, get2faQrCode, confirm2fa, disable2fa} from '../../api/accounts.api'


export default {
    name: "2Fa",
  data() {
    
    return {
        secret: null,
        qr: null,
        code: "",
        enabled: false,
        message: null
    }},
    mounted(){
        this.getSecret();
        
        
    },
  methods: {
    getSecret() {
      get2faSecret(this.secret)
        .then((response) => {
          if(response.data.enabled){
            this.enabled = true;
          }
          else{
            this.secret = response.data.secret;
          
            this.getQrCode(response.data.secret); 
          }
          
          
        }).catch(error => {
            this.errors = error.response;
          }
      );
    },
    getQrCode(secret) {
      get2faQrCode(secret)
      .then(response => {
        this.qr = 'data:image/png;base64,'+ response.data;

        

      }).catch(console.error)
    },
    confirm(){
      confirm2fa(this.secret, this.code)
        .then((response) => {
          if(response.data.success){
            this.enabled = true;
            this.success = true;
          }
          else{
            this.enabled = false;
            this.success = false;
          }
          this.message = response.data.message;

          
        }).catch(error => {
            this.errors = error.response;
          }
      );
    },
    disable(){
      disable2fa()
        .then((response) => {

            this.enabled = false;
            this.success = false;

            this.getSecret();

          
        }).catch(error => {
            this.errors = error.response;
          }
      );
    },
}
};
</script>

<style>
</style>