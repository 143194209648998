<template>
  <div v-if="!twofa_required" class="w-full">
   
    <form
      class="space-y-4"
      @submit.prevent="signin"
    >
      <div
        v-if="isSuccess"
        class="success-alert flex bg-green-500 py-3 px-5 text-whitebox-border rounded-md"
      >
        <span class="text-2xl mr-3"><svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 16 16"
          class="iconify iconify--bi"
        ><g fill="currentColor"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" /></g></svg>
        </span>
        <span> Your Email is successfully verified. Please log in</span>
      </div>

      
           
      <h1 class="text-center text-2xl text-gray-800 dark:text-gray-800">
        Log In {{userStore.user.username}}
      </h1>
      <div
        v-if="errors"
        class="danger-alert bg-red-500 flex py-3 px-5 text-white w-full box-border rounded-md"
      >
        <span class="text-2xl mr-3"><svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
          class="iconify iconify--ic"
        ><path
          fill="currentColor"
          d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8zm-4.17-7.14L12 10.59L9.17 7.76L7.76 9.17L10.59 12l-2.83 2.83l1.41 1.41L12 13.41l2.83 2.83l1.41-1.41L13.41 12l2.83-2.83l-1.41-1.41z"
        /></svg>
        </span>
        <span> {{ errors }} </span>
      </div>
      <div class="flex flex-col space-y-2">
        <label
          class="text-sm font-light"
          for="email"
        >Email</label>
        <input
          id="email"
          v-model="form.email"
          class=" px-3 py-2 rounded-md border border-slate-400"
          type="email"
          placeholder="Your Email"
          name="email"
        >
      </div>
      <div class="flex flex-col space-y-2">
        <label
          class="text-sm font-light"
          for="password"
        >Password</label>
        <input
          id="password"
          v-model="form.password"
          class="px-3 py-2 rounded-md border border-slate-400"
          type="password"
          placeholder="Your Password"
          name="password"
        >
      </div>

      <!--<div>
                <input type="checkbox" name="remember" id="remember" />
                <label class="text-sm font-light" for="remember">Remember me</label>
            </div>-->

      <button
        class="button w-full px-10 py-2  text-white rounded-md
            duration-300 ease-in "
        type="submit"
      >
        Sign In  
      </button>

      <p class="text-right">
        <a
          class="text-lila text-sm font-light hover:underline hover:text-gruen"
          href="/forget-password"
        >Forget Password?</a>
      </p>

      <p class="text-center text-xl pt-6">
        Don't have an account yet? <br><a
          href="/register"
          class="text-lila font-light underline hover:text-gruen"
        > Register a free account </a>
      </p>
    </form>  
  </div>
  <div v-else >
    <form
      class="space-y-4 "
      @submit.prevent="twoFa"
    >
      <div
        v-if="errors"
        class="danger-alert bg-red-500 flex py-3 px-5 text-white box-border rounded-md"
      >
        <span class="text-2xl mr-3"><svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
          class="iconify iconify--ic"
        ><path
          fill="currentColor"
          d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8zm-4.17-7.14L12 10.59L9.17 7.76L7.76 9.17L10.59 12l-2.83 2.83l1.41 1.41L12 13.41l2.83 2.83l1.41-1.41L13.41 12l2.83-2.83l-1.41-1.41z"
        /></svg>
        </span>
        <span> {{ errors }} </span>
      </div>
           
      <h1 class="text-center text-2xl text-gray-800 dark:text-gray-800">
        Two Factor Authentication
      </h1>
      <div class="flex flex-col space-y-2">
        <label
          class="text-sm font-light"
          for="OTP"
        >Enter your OTP from your authenticator </label>
        <input
          id="OTP"
          v-model="code"
          class="px-3 py-2 rounded-md border border-slate-400"
          type="text"
          placeholder="000 000"
          name="OTP"
        >

        <button
          class="button px-10 py-2 bg-gruen text-white rounded-md
             duration-300 ease-in "
          type="submit"
        >
          Sign In 
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  // @ is an alias to /src

  import UserService from '../../services/user.service';
  import { login, confirm, twoFactorCheck } from "../../api/accounts.api";
  import { useUserStore } from "@/store/user";

  export default {
    name: "Login",
    components: {

    },
    setup(){
      const userStore = useUserStore();
      return { userStore };
    },
    data() {
      return {
        form:{
          email: "",
          password: ""
        },
        token: null,
        errors: null,
        isSuccess: false,
        twofa_required: false,
        code: ""
      }
    },
    computed: {

    },

    mounted() {
      console.log(this.userStore.user.username)
      if(this.$route.query.confirm != undefined){
        this.confirm();
      }
    },
    methods: {


      signin(){
        console.log("login");

        login(this.form)
          .then(response => {
            if(response.data.login){
              //console.log(response.data["2fa_complete"]);
              if(!response.data["2fa_complete"]){
                this.twofa_required = true;
                this.errors = null;
              }
            }
            if (response.data.token) {
              this.token = response.data.token;
              var token = response.data.token;
              localStorage.setItem('token', token);
              this.userStore.fetchUser();
              if(this.$route.query.returnUrl){
                this.$router.push(this.$route.query.returnUrl);
              }
              else{
                this.$router.push('/')
              }

            }
          }).catch(error => {

            this.errors = error.response.data.message;

          })

      },
      twoFa(){
        twoFactorCheck(this.code)
          .then(response => {
            if (response.data.token) {
              try{
              this.token = response.data.token;
              localStorage.setItem('token', this.token);

              this.userStore.fetchUser();
              if(this.$route.query.returnUrl){
                this.$router.push(this.$route.query.returnUrl);
              }
              else{
                this.$router.push('/')
              }
              
              }catch(error){
                console.log("error"+JSON.stringify(error.response.message));
                this.errors = "Wrong Token provided";
              }
            }
            else{
              this.errors = "Wrong Token provided";
            }
          }).catch(error => {
            console.log("error"+JSON.stringify(error.response.message));
            if(error.response){
              this.errors = error.response.message;
            }
          })
      },
      confirm(){
        console.log("confirm");
        
        confirm({confirm : this.$route.query.confirm})
        .then((response) => {

          if(response.status == 201 || response.status == 200 ){
            this.isSuccess = true;
          }
        })
        .catch((error) => {

          console.log("error"+JSON.stringify(error));
          this.errors = error.response.data;

         /* if(error.response.status == 400){
            this.errors = error.response.data.errors;
            console.log( error.response.data);
          }
          else{
            

            /*this.$toast.error("An Error occured: <br> "+error+" <br> please contact support", {
              // optional options Object
            });asd

          }*/
          
        });
      }
      
    }
  };
</script>
