<template>
<div>
    <div class="dashboard p-4">
        <nav class="flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li class="inline-flex items-center">
                    <a href="#" class="
                inline-flex
                items-center
                text-sm
                font-medium
                text-gray-700
                hover:text-gray-900
                dark:text-gray-400 dark:hover:text-white
              ">
                        <Icon icon="bxs:rocket" class="mr-2" />

                        Subscription
                    </a>
                </li>
                <li class="inline-flex items-center">
                    <a href="#" class="
                inline-flex
                items-center
                text-sm
                font-medium
                text-gray-700
                hover:text-gray-900
                dark:text-gray-400 dark:hover:text-white
              ">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                        </svg>

                        Order
                    </a>
                </li>
            </ol>
        </nav>
        <!-- end nav -->
        <div class="mt-5 w-full">
            <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
                <Icon icon="bxs:shopping-bag" class="mr-2 text-3xl" />
                New Order
            </h1>
        </div>

        <!-- grid wrapper card -->
        <div class="grid grid-cols-1 md:grid-cols-2 mt-5 gap-2">
            <div class="col card bg-white dark:bg-gray-800  rounded-md p-5 text-gray-800 dark:text-gray-200 dark:text-gray-200">
                <h2 class="font-bold text-lg ">
                    Billing Information
                </h2>
                <p class="mb-2">
                    Please fill in your Billing Information
                </p>
                <billing ref="billing" :errors="errors" button-text="Check Address" :button="false" @validated="order" />

            </div>

            <div class="col card bg-white dark:bg-gray-800  rounded-md p-5 text-gray-800 dark:text-gray-200 dark:text-gray-200">
                <h2 class="font-bold text-lg">
                    Order Options
                </h2>

                <form>

                    <ul class="grid gap-6 w-full md:grid-cols-2 mt-3">
                        <li>
                            <input id="billing-monthly" v-model="orderSummary.yearly" type="radio" billing-period :value="false" class="hidden peer" required />
                            <label for="billing-monthly" class="
              inline-flex
              justify-between
              items-center
              px-5
              py-3
              w-full
              text-gray-500
              bg-white
              rounded-lg
              border border-gray-200
              cursor-pointer
              dark:hover:text-gray-300
              dark:border-gray-700
              dark:peer-checked:text-gruen
              peer-checked:border-gruen peer-checked:text-gruen
              hover:text-gray-800
              dark:text-gray-200 dark:
              text-gray-400
              hover:bg-gray-100
              dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700
            ">
                                <div class="block">
                                    <div class="w-full text-lg font-semibold">Monthly billing</div>
                                    <div class="w-full">Most flexible</div>
                                </div>
                                <Icon icon="ic:baseline-date-range" class="text-3xl" />
                            </label>
                        </li>
                        <li>
                            <input id="billing-yearly" v-model="orderSummary.yearly" type="radio" billing-period :value="true" class="hidden peer" />
                            <label for="billing-yearly" class="
              inline-flex
              justify-between
              items-center
              px-5
              py-3
              w-full
              text-gray-500
              bg-white
              rounded-lg
              border border-gray-200
              cursor-pointer
              dark:hover:text-gray-300
              dark:border-gray-700
              dark:peer-checked:text-gruen
              peer-checked:border-gruen peer-checked:text-gruen
              hover:text-gray-800
              dark:text-gray-200 dark:
              text-gray-400
              hover:bg-gray-100
              dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700
            ">
                                <div class="block">
                                    <div class="w-full text-lg font-semibold">Yearly billing</div>
                                    <div class="w-full"> Save 10% </div>
                                </div>
                                <Icon icon="ic:baseline-calendar-month" class="text-3xl" />
                            </label>
                        </li>
                    </ul>

                    <div class="col-span-6 sm:col-span-3 mt-3" v-for="(options, key) in addons" v-bind:key="key">

                        <label for="country" class="block mb-2 text-sm font-medium">{{key}}</label>

                        <select :id="key" v-model="orderAddons[key]" :name="key" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                            <option :value="null"> No option </option>
                            <option v-for="option in options" :key="option.id" :value="option">
                                {{ option.name }}

                                <span v-if="orderSummary.yearly"> (+ €{{option.priceYearly}}/year) </span>
                                <span v-else> (+ €{{option.price}}/month) </span>

                            </option>
                        </select>
                    </div>
                </form>

                <h3 class="font-bold text-lg mt-3"> Order Summary </h3>
                <div class="grid grid-cols-2 my-4 mt-5 flex text-center">
                    <div v-if="this.plan" class="text-left"> {{ plan.name }} -
                        <span v-if="orderSummary.yearly"> yearly </span>
                        <span v-else> monthly </span>
                    </div>
                    <div v-if="this.plan" class="text-right"> 
                        <span v-if="orderSummary.yearly"> € {{ plan.plan_pricing_yearly }}  / year </span>
                        <span v-else> € {{ plan.plan_pricing }}  /month </span>
                    </div>
                </div>

                <div class="grid grid-cols-2 my-4 mt-5 flex text-center" v-for="(addon, key) in orderAddons" v-bind:key="key">
                    <div class="text-left" v-if="addon"> {{ addon.name }}
                    </div>
                    <div v-if="addon" class="text-right" >  
                        <span v-if="orderSummary.yearly"> € {{ addon.priceYearly }}/ year </span>
                        <span v-else> € {{ addon.price }}/ month </span>
                    </div>
                </div>

                <div class="grid grid-cols-2 my-4  flex  border-t-2">
                  <div class="text-bold text-left"> total</div>
                  <div  class="text-right" >  
                        <span v-if="orderSummary.yearly"> € {{ total }} / year </span>
                        <span v-else> € {{ total }} / month </span>
                    </div>
                </div>

                <div class="my-4 mt-5 flex text-center gap-2">
                    <button type="submit" class=" flex-1 mx-auto text-white bg-[#FF9119] mx-auto hover:bg-[#FF9119]/80 focus:ring-4 focus:ring-[#FF9119]/50 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#FF9119]/80 dark:focus:ring-[#FF9119]/40 mr-2 mb-2 disabled:opacity-50 hover:disabled:bg-[#FF9119] dark:hover:disabled:bg-[#FF9119]" @click="validateAddress('forumpay')">
                        <Icon icon="bx:bitcoin" class="mr-2" />
                        Pay with Crypto
                    </button>
                    <button type="submit" class="flex-1 mx-auto text-white bg-[#635BFF] mx-auto hover:bg-[#635BFF]/80 focus:ring-4 focus:ring-[#635BFF]/50 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#635BFF]/80 dark:focus:ring-[#635BFF]/40 mr-2 mb-2 disabled:opacity-50 hover:disabled:bg-[#635BFF] dark:hover:disabled:bg-[#635BFF]" @click="validateAddress('stripe')">
                        <Icon icon="fa:cc-stripe" class="mr-2" /> Pay with Credit Card or SEPA
                    </button>

                </div>
                <div class="flex items-center">
                <p>Crypto payment accepts: </p>

                    <Icon icon="cryptocurrency:usdt" class="ml-2 mr-2 text-xl" />
                    <Icon icon="cryptocurrency:trx" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:btc" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:eth" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:ltc" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:bch" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:usdc" class="mr-2 text-xl" />
                    <Icon icon="cryptocurrency:dash" class="mr-2 text-xl" />
                </div>
       
                *You will be redirected to our payment provider. Once your payment is completed, we will activate your Subscription. Please note that Forumpay will not redirect you back.

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    Icon
} from "@iconify/vue";
import {
    getPlan
} from "../api/plans.api";
//import PricingTable from "../components/PricingTable.vue";
import Billing from "./components/billing.vue";
import {
    createSubscription,
    getSubscriptionAddons
} from '../api/subscriptions.api'
import {
    useUserStore
} from "@/store/user";
import {
    storeToRefs
} from 'pinia'

export default {
    name: "OrderSubscription",
    components: {
        Icon,
        //PricingTable,
        Billing
    },
    setup() {

        const {
            user
        } = storeToRefs(useUserStore())
        return {
            user
        };
    },
    computed: {
      total(){
        if(!this.plan){
          return 0;
        }
        if(this.orderSummary.yearly){
          let total = this.plan.plan_pricing_yearly;
          for (const [key, value] of Object.entries(this.orderAddons)) {
            if(value){
              total += value.priceYearly;
            }
          }
          return total;
        }
         else{
          let total = this.plan.plan_pricing;
          for (const [key, value] of Object.entries(this.orderAddons)) {
            if(value){
              total += value.price;
            }
            
          }
          return total;
        }
      }
    },
    data() {
        return {
            plan: null,
            isLoading: false,
            errors: {},
            isValid: false,
            provider: null,
            addons: [],
            orderAddons: {},
            orderSummary: {
                yearly: false
            }
        };
    },
    mounted() {
        this.getPlan();
        this.getAddons();
        if (this.$route.params.interval == "yearly") {
            this.orderSummary.yearly = true;
        }
    },
    methods: {
        getPlan() {
            this.isLoading = true;
            getPlan(this.$route.params.id)
                .then((response) => {
                    if (response.status == 200) {
                      console.log(response.data)
                        this.plan = JSON.parse(response.data.data.plan)
                        
                        this.isLoading = false;
                    }
                });
        },
        getAddons() {
            getSubscriptionAddons(this.$route.params.id)
                .then((response) => {
                    if (response.status == 200) {
                        this.addons = response.data.data.addons;

                        for (const [key, value] of Object.entries(this.addons)) {
                          console.log(key);
                          this.orderAddons[key] = null;
                        }

                        this.isLoading = false;
                    }
                });
        },
        validateAddress(paymentMethod) {
            this.provider = paymentMethod;
            this.$refs.billing.updateAddress();
        },
        order(isValid) {
            if (isValid) {
                let addons = [];

                  for (const [key, value] of Object.entries(this.orderAddons)) {
                      if (value != null) {
                          addons.push(value.id);
                      }
                  }
              

                createSubscription({
                        "provider": this.provider,
                        "plan": this.plan.id,
                        "yearly": this.orderSummary.yearly,
                        "addons": addons
                    })
                    .then((response) => {
                        if (response.status == 200) {
                            console.log(response.data.data.url)
                            window.location.href = response.data.data.url;
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 400) {
                            this.errors = error.response.data.errors;
                            this.isLoading = false;
                        } else {
                            this.$toast.error(

                                error.response.data.message,

                                {
                                    // optional options Object
                                }
                            );
                        }
                    });
            }
        },
    }
    }

</script>

<style>
</style>
