import axiosClient  from "../../services/axios.service";

export function getAnnouncements(){
    return axiosClient.get('/api/admin/announcement');
}

export function getAnnouncement(id){
    return axiosClient.get('/api/admin/announcement/'+id);
}

export function deleteAnnouncement(id){
    return axiosClient.delete('/api/admin/announcement/'+id);
}

export function updateAnnouncement(id, data){
    return axiosClient.post('/api/admin/announcement/'+id, data);
}

export function createAnnouncement(data){
    return axiosClient.post('/api/admin/announcement', data);
}




