import axiosClient  from "../../services/axios.service";

export function getSubscriptionAddons(){
    return axiosClient.get('/api/admin/subscription_addon');
}
export function deleteSubscriptionAddons(id){
    return axiosClient.delete('/api/admin/subscription_addon/'+id);
}

export function createSubscriptionAddons(form){
    return axiosClient.post('/api/admin/subscription_addon', form);
}



