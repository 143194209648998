<template>
    <div class="dashboard p-4 relative">
        <nav class="flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li class="inline-flex items-center">
                    <a href="#" class="
                      inline-flex
                      items-center
                      text-sm
                      font-medium
                      hover:text-gray-900
                      dark:text-gray-400 dark:hover:text-white
                    ">
                        <Icon icon="eos-icons:database-migration" class="mr-2" />

                        Migrate from V1
                    </a>
                </li>
            </ol>
        </nav>
        <!-- end nav -->
        <div class="mt-5 w-full">
            <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
                <Icon icon="eos-icons:database-migration" class="mr-2" />

                Migrate from V1
            </h1>
        </div>
        <!-- grid wrapper card -->
        <div class="wrapper-card grid gap-2 mt-5">
            <div class="card bg-white dark:bg-gray-800 w-full rounded-md p-5">
                <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
                    Create migration request
                </h2>
                <p class="text-gray-400 font-lexend font-normal">
                    This feature helps you to migrate all your V1 Ethereum and Binance Smart Chain addresses from V1 to V2.
                    For this you need to enter your <strong>V1 Api Key</strong> to the form.
                </p>

                <form class="space-y-2" action="#" @submit.prevent="migrate()">
                    <div class="flex mt-2">
                        <div class="flex-auto pr-3">
                            <label for="apiKey" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">V1 Api Key</label>
                            <input id="apiKey" v-model="form.apiKey" type="text" name="from"
                                placeholder="jakkl5xij48c44cskog0g8c8kgs8gwkgo08c44sgo44o0k" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">

                        </div>

                    </div>
                    <div class="flex mt-4">
                        <button type="submit" class="
                        button
                        flex
                        text-white
                        bg-gruen
                        hover:bg-blue-800
                        font-medium
                        rounded-lg
                        text-sm
                        px-5
                        py-2.5
                        mr-2
                        mb-2
                      ">
                            <span v-if="form_processing" class="mr-2 text-xl">
                                <Icon icon="line-md:loading-twotone-loop" />
                            </span>
                            <span v-else class="mr-2 text-xl">
                                <Icon icon="eos-icons:database-migration" />
                            </span>

                            <span> Migrate </span>
                        </button>
                    </div>
                </form>

                <div v-if="errors" class="flex p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
                role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Info</span>
                <div>
                    <span class="font-medium">Migration failed</span> {{ errors }}
                </div>
            </div>

            <div v-if="success" class="flex p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Info</span>
                <div>
                    <span class="font-medium">Success alert!</span> {{ success }}
                </div>
            </div>

            </div>

           

        </div>
    </div>
</template>
<script>
import { Icon } from "@iconify/vue";
import { migrate } from "../api/addresses.api";

export default {
    name: "Addresses",
    components: {
        Icon,
    },
    props: { chainName: String, icon: String },

    data() {
        return {
            errors: null,
            success: null,
            form: {
                apiKey: null
            },
            form_processing: false
        };
    },
    methods: {
        migrate() {
            this.form_processing = true;
            migrate(this.form.apiKey)
                .then((response) => {
                    if (response.status == 200) {
                        this.isSuccess = true;
                        this.$toast.success("Migrated successfully", {
                            // optional options Object
                        });
                        this.success=response.data;
                        this.form_processing = false;
                    }
                    
                })
                .catch((error) => {

                  
                    this.errors = error.response.data

                        //console.log(error.response.data);

                    this.form_processing = false;
                });

        }
    }
};
</script>
  
<style></style>