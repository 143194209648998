<template>
  <div class="dashboard p-4">
    <!-- end nav -->
    <div class="mt-5 w-full">
      <h1 class="text-2xl  text-gray-800 dark:text-gray-200 font-medium">
        Manage Accounts
      </h1>
    </div>
    <!-- grid wrapper card -->
    <div class="wrapper-card grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mt-5" />

    <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow">
      <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
        Accounts
      </h2>

      <div class="wrapping-table mt-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                ID
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Email
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Username
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Roles
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Plan
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                Registered_at
              </th>
              <th
                scope="col"
                class="uppercase px-6 py-3"
              >
                actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="account in accounts"
              :key="account.id"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
            >
              <td class="px-6 py-4">
                {{ account.id }}
              </td>
              <td class="px-6 py-4">
                {{ account.email }}
              </td>
              <td class="px-6 py-4">
                {{ account.username }}
              </td>
              <td class="px-6 py-4">
                <span
                  v-for="role in account.roles"
                  :key="role"
                  class="text-gray-800 dark:text-gray-200 bg-primary px-3 py-1 ml-1 rounded-md"
                >{{ role }}</span>
              </td>
              <td class="px-6 py-4">
                <span
                  v-if="account.subscription && !account.subscription.plan.isFree"
                  class="text-green-800 bg-green-300 px-3 py-1 rounded-md"
                >{{ account.subscription.plan.name }}</span>
                <span
                  v-if="account.subscription && account.subscription.plan.isFree"
                  class="text-white bg-primary px-3 py-1 rounded-md"
                >{{ account.subscription.plan.name }}</span>
              </td>
              <td class="px-6 py-4">
                {{ account.registeredAt }}
              </td>
              <td class="px-6 py-4">
                <a
                  :href="'/admin/account/'+account.id"
                  class="text-white bg-lila px-3 py-1 ml-1 rounded-md"
                >EDIT</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>


import { getAccounts } from '../../../api/admin/accounts.api'

export default {
    components: {

    },

    data() {
        return {
            columns2: [{
                    label: "ID",
                    field: "id"
                },
                {
                    label: "Avatar",
                    field: "avatar"
                },
                {
                    label: "Username",
                    field: "username"
                },
                {
                    label: "Roles",
                    field: "roles"
                },
                {
                    label: "Country",
                    field: "country"
                },
                {
                    label: "Is Verified",
                    field: "isVerified"
                },
                {
                    label: "Registered at",
                    field: "registered_at"
                }
            ],
            accounts: [],
            errors: "",
            columns: [{
                prop: "name"
            }, {
                prop: "details"
            }],
            rows: [{
                name: "1",
                details: "Item 1",
            }]
        };
    },
    mounted() {
        this.getAccounts();
    },
    
    methods: {
        getAccounts() {
            getAccounts()
                .then(response => {
                    if (response.data) {

                        this.accounts = JSON.parse(response.data.data.accounts);
                        //console.log(response.data.data.accounts);
                    }
                })
            //this.accounts = flatten(this.accounts);
        }
    }
}
</script>
