<template>
  <div class="container mx-auto text-center">
    <router-link
      to="/"
      exact
    >
      <img
        src="../../assets/img/navlogo.png"
        width="46"
        class="invert  inline-block align-middle opacity-50"
      >
      <h2
        class="text-2xl font-normal text-gray-800 dark:text-gray-200 opacity-50 inline-block align-middle align-text-middle"
        translate="no" 
      >
        <span class="text-black   text-3xl inline-block align-middle align-text-middle">Chaingateway.io</span>
      </h2>
    </router-link>
  </div>
</template>


<script>

export default {
    name: "SiteLogo",
    props:['large', 'center'],
    setup() {
        
    },
   
};
</script>
