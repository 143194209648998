<template>
  <div>
    <h2 class="text-xl">
      Membership
    </h2>

    <div v-if="noSubscription">
      No Subscription found. 
      <a
          href="/subscription"
          class="button flex mr-2 text-white bg-gruen hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 float-right"
        > Subscribe </a>
    </div>
    <div v-else>
    <p class="text-gray-400 font-lexend font-normal my-2">
      Subscription
    </p>

    <div
      v-if="subscription != null"
      class="min-h-20  box-shadow flex flex-wrap bg-gray-200 dark:bg-gray-700 rounded-lg flex p-2 place-items-center"
    >
      <div class="shrink pr-5">
        {{ subscription.plan.name }}
      </div>
      <div class="shrink px-5 w-40">
        <div
          v-if="!yearly"
          class="flex justify-center items-baseline my-8"
        >
          <span class="mr-2  ">€{{ subscription.plan.plan_pricing }}</span>
          <span class="text-gray-500 dark:text-gray-400">/ month</span>
        </div>
        <div
          v-else
          :class="'flex justify-center items-baseline mt-2 mb-8'"
        >
          <span class="mr-2  ">€{{ subscription.plan.plan_pricing_yearly }}</span>
          <span class="text-gray-500 dark:text-gray-400">/ year</span> 
        </div>
      </div>
      <div class="grow  w-300">
        <span>Webhooks:
          <span class="">{{ th(subscription.plan.ipns_limit) }}</span></span> <br>
        <span>Transactions:
          <span class="">{{ th(subscription.plan.transactions_limit) }}</span></span> <br>
        <span>Wallets:
          <span class="">{{ th(subscription.plan.ethaddresses_limit) }}</span></span> <br>
        <span>Requests:
          <span class="">{{ th(subscription.plan.requests_limit) }}</span></span>
      </div>
      <div class="grow  w-300">
        <span>addons: </span> <br>
          <span class="" v-for="addon in subscription.addons" v-bind:key="addon.id">{{ addon.name }}</span> <br>
       
      </div>
      <div class="shrink w-100">
        <button
          v-if="!subscription.plan.isFree && subscription.endsAt == null"
          type="button"
          class="button flex mr-2 text-white bg-gruen hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 float-right "
          @click="switchPopup = true"
        >
          Switch plan
        </button>
        
     
        <button
          v-if="!subscription.plan.isFree && subscription.endsAt == null"
          type="button"
          class="  mr-2 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-2.5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          @click="cancelPopup = true"
        >
          Cancel
        </button>
        <button
          v-if="!subscription.plan.isFree && subscription.endsAt != null"
          type="button"
          class="  mr-2 text-white bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-2.5 py-2.5 text-center"
          @click="retrieveSubscription"
        >
          retrieve
        </button>
        <a
          v-if="subscription.plan.isFree"
          href="/subscription"
          class="button flex mr-2 text-white bg-gruen hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 float-right"
        > Subscribe </a>
      </div>
    </div>
    <p
      v-if="subscription != null && subscription.upcomingPlan != null"
      class="text-gray-400 font-lexend font-normal my-2"
    >
      Upcoming Changes at next Billing period
    </p>
    <div
      v-if="subscription != null && subscription.upcomingPlan != null"
      class="min-h-20  box-shadow flex flex-wrap bg-gray-200 dark:bg-gray-700 rounded-lg flex p-2 place-items-center"
    >
      <div class="shrink pr-5">
        {{ subscription.upcomingPlan.name }}
      </div>
      <div class="shrink px-5 w-40">
        <div
          v-if="!yearly"
          class="flex justify-center items-baseline my-8"
        >
          <span class="mr-2  ">€{{ subscription.upcomingPlan.plan_pricing }}</span>
          <span class="text-gray-500 dark:text-gray-400">/ month</span>
        </div>
        <div
          v-else
          :class="'flex justify-center items-baseline mt-2 mb-8'"
        >
          <span class="mr-2  ">€{{ subscription.upcomingPlan.plan_pricing_yearly }}</span>
          <span class="text-gray-500 dark:text-gray-400">/ year</span> 
        </div>
      </div>
      <div class="grow  w-300">
        <span>Webhooks:
          <span class="">{{ th(subscription.upcomingPlan.ipns_limit) }}</span></span> <br>
        <span>Transactions:
          <span class="">{{ th(subscription.upcomingPlan.transactions_limit) }}</span></span> <br>
        <span>Wallets:
          <span class="">{{ th(subscription.upcomingPlan.ethaddresses_limit) }}</span></span> <br>
        <span>Requests:
          <span class="">{{ th(subscription.upcomingPlan.requests_limit) }}</span></span>
      </div>
       <div class="grow  w-300">
        <span>addons: </span> <br>
          <span class="" v-for="addon in subscription.upcoming_addons" v-bind:key="addon.id">{{ getName(addon) }}</span> <br>
       
      </div>
    </div>
    <div class="">
      <p class="text-gray-400 font-lexend font-normal my-2 ">
        Payment information
      </p>
      <ul
        v-if="subscription != null"
        class=" min-h-20 box-shadow p-2 bg-gray-200 dark:bg-gray-700 rounded-lg"
      >

         <li>Current period start: {{ moment(subscription.current_period_start).format("YYYY-MM-DD") }}</li> 

         <li>
          Next billing around: {{ moment(subscription.current_period_end).format("YYYY-MM-DD") }}
         </li>
        <li>
          Cancellation date: <span v-if="subscription.endsAt">{{ moment(subscription.endsAt).format("YYYY-MM-DD") }}</span>
        </li>
        <li>
          Upcoming addon price: <span v-if="subscription.addonValue">{{ subscription.addonValue }}</span>
        </li>
        <li>
          Coupon value next month: <span v-if="subscription.couponValue">{{ subscription.couponValue }}</span>
        </li>
        <li>
          Total next period: <span v-if="subscription.total">{{ subscription.total }}</span>
        </li>
      </ul>
      <div
        v-if="subscription != null">

      </div>
      <p class="text-gray-400 font-lexend font-normal my-2">
        Billing
      </p>
      <div
        v-if="subscription != null"
        class=" flex flex-wrap min-h-20 box-shadow flex bg-gray-200 dark:bg-gray-700 rounded-lg flex p-2 justify-content-center"
      >
        <billing :button="true" />
      </div>



      <div
        id="password-modal"
        tabindex="-1"
        aria-hidden="true"
        :class="
          (cancelPopup ? '' : 'hidden') +
            ' fixed inset-0 mx-auto bg-gray-900 top-100 bg-opacity-70 overflow-y-auto h-full w-full'
        "
      >
        <div
          id="popup-modal"
          class="relative mx-auto sm:w-3/4 md:w-2/4  p-4 w-full max-w-md h-full md:h-auto"
        >
          <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="popup-modal"
                @click="cancelPopup = false"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                ><path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                /></svg>
                <span class="sr-only" />
              </button>
              <div class="p-6 text-center">
                <svg
                  aria-hidden="true"
                  class="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                ><path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                /></svg>
                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to cancel this membership?
                </h3>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  @click="deleteSubscription"
                >
                  Yes, I'm sure
                </button>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  class="text-gray-500 bg-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  @click="cancelPopup = false"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="switch-plan-modal"
        tabindex="-1"
        aria-hidden="true"
        :class="
          (switchPopup ? '' : 'hidden') +
            ' fixed inset-0 mx-auto bg-gray-900 top-100 bg-opacity-70 overflow-y-auto h-full w-full'
        "
      >
        <div
          id="popup-modal-switch"
          class="relative mx-auto sm:w-3/4 md:w-2/4  p-4 w-full max-w-md h-full md:h-auto"
        >
          <div class="relative p-4 w-full max-w-md h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="popup-modal"
                @click="switchPopup=false"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                ><path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                /></svg>
                <span class="sr-only" />
              </button>
              <div class="p-6 text-center">
                <h3> Switch Plan</h3>
              </div>


              <div class="p-6 text-left">
                <form @submit.prevent="">
                  <label
                    for="countries"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >Select your new Plan</label>
                  <select
                    id="countries"
                    v-model="form.plan_id"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option
                      v-for="plan in plans"
                      :key="plan.id"
                      :value="plan.id"
                    >
                      {{ plan.name }} for  € <span v-if="yearly">{{ plan.plan_pricing_yearly }}/year</span><span velse>{{ plan.plan_pricing }} / year</span> 
                    </option>
                  </select>
                  <div
                    v-if="errors"
                    class="mt-5 danger-alert bg-red-500 flex py-3 px-5 text-white w-full box-border rounded-md"
                  >
                    {{ errors }}
                  </div>
                    <label
                    for="countries"
                    class="block mb-2 text-md font-medium mt-4"
                  >Addons <br><span class="text-sm"> (leave empty if you dont want to change)</span> </label>
                  <div class="col-span-6 sm:col-span-3 mt-3" v-for="(options, key) in addons" v-bind:key="key">

                        <label for="country" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400" >{{key}}</label>

                        <select :id="key" v-model="orderAddons[key]" :name="key" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                            <option :value="null"> No option </option>
                            <option v-for="option in options" :key="option.id" :value="option">
                                {{ option.name }}

                                <span v-if="orderSummary.yearly"> (+ €{{option.priceYearly}}/year) </span>
                                <span v-else> (+ €{{option.price}}/month) </span>

                            </option>
                        </select>
                    </div>
                </form>
              </div>
              <div class="p-6 text-center">
                <span v-if="subscription">Your new Plan will begin at the end of your current Billing cycle </span>
              </div>


              <div class="p-1 text-center">
                <button
                  data-modal-toggle="popup-modal-switch"
                  type="submit"
                  class="text-white bg-lila hover:bg-lila focus:ring-4 focus:outline-none focus:ring-lila dark:focus:ring-lila font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  @click="switchPlan"
                >
                  Yes, I'm sure
                </button>
                <button
                  data-modal-toggle="popup-modal-switch"
                  type="button"
                  class="text-gray-500 bg-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  @click="switchPopup = false"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Billing from '../components/billing'
import {getSubscription, cancelSubscription, retrieveSubscription, switchPlan, getSubscriptionAddons} from '../../api/subscriptions.api'
import { getPlans } from "../../api/plans.api";
import userService from '@/services/user.service';

export default {
    name: "MembershipSettings",
    components:{Billing},
  data() {
    
    return {
        subscription: null,
        cancelPopup: false,
        switchPopup: false,
        plans: [],
        errors: null,
        form:{
          plan_id: null,
          addons:[]
        },
        orderAddons:{},
        addons: [],
        orderSummary:{},
        noSubscription: true,

    }},
    mounted(){
        this.getSubscription();
        this.getPlans();
        this.getAddons();
        

    },
  methods: {
    th(num){ 
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dt(dt){
      return new Date(dt);
    },
    getName(id){
      for (const [key, values] of Object.entries(this.addons)) {
          for(const value of values){
            if (value.id == id) {
              console.log(value.name)
                return value.name
            }

          }

          
      }
    },
    getSubscription() {
      getSubscription()
        .then((response) => {
          if (response.status == 200) {
            if (response.data != null) {
              this.subscription = response.data;
              this.getCurrentVal();
              this.noSubscription = false;
            }
          }

        }).catch((error) => {
          this.noSubscription = true;
        });
    },
    getCurrentVal(){
      for (const [key, value] of Object.entries(this.subscription.addons)) {
          this.orderAddons[value.type] = value;
      }
    },
    deleteSubscription(){

      cancelSubscription()
        .then((response) => {
          if (response.status == 200) {
            this.getSubscription();
            this.$toast.success(
             "Your subscription was cancelled successfully",
              {
                // optional options Object
              }
            );
            this.cancelPopup = false;
          }
        });
    },
    retrieveSubscription(){
      retrieveSubscription()
        .then((response) => {
          if (response.status == 200) {
            this.getSubscription();
            this.$toast.success(
             "Your subscription was retrieved successfully",
              {
                // optional options Object
              }
            );
            this.cancelPopup = false;
          }
        });
    },
    getAddons() {
            getSubscriptionAddons(this.$route.params.id)
                .then((response) => {
                    if (response.status == 200) {
                        this.addons = response.data.data.addons;


                        this.isLoading = false;
                    }
                });
        },
    getPlans() {
      this.isLoading = true;
      getPlans()
        .then((response) => {
          if (response.status == 200) {
            if (response.data.data.plans.length > 0) {
              this.plans = JSON.parse(response.data.data.plans);
            } else {
            }

            this.isLoading = false;
          }
        });
    },
    switchPlan() {

      let addons = [];

      for (const [key, value] of Object.entries(this.orderAddons)) {
          if (value != null) {
              this.form.addons.push(value.id);
          }
      }

      switchPlan(this.form)
        .then((response) => {
          if (response.status == 200) {
            this.switchPopup = false;
            userService.updateAccount();
            this.getSubscription();
          }
        }).catch(error => {
            this.errors = error.response;
          }
      );
    },
  },
};
</script>

<style>
</style>