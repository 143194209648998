<template>
  <div>
    <div class="dashboard p-4">
      <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-3">
          <li class="inline-flex items-center">
            <a href="#" class="
                  inline-flex
                  items-center
                  text-sm
                  font-medium
                  text-gray-700
                  hover:text-gray-900
                  dark:text-gray-400 dark:hover:text-white
                ">
              <Icon icon="bxs:rocket" class="mr-2" />

              Subscription
            </a>
          </li>
        </ol>
      </nav>
      <!-- end nav -->
      <div class="mt-5 w-full">
        <h1 class="text-2xl flex text-gray-800 dark:text-gray-200 font-medium">
          <Icon icon="bxs:rocket" class="mr-2 text-3xl" />
          Subscription
        </h1>
      </div>



      <div class="wrapper-card grid gap-2 mt-5 " v-if="!user.subscription">

        <plans />
      </div>

      <div class="wrapper-card grid gap-2 mt-5 " v-else>
        <div 
          class="card bg-white dark:bg-gray-800 w-full rounded-md p-5 text-gray-800 dark:text-gray-200 dark:text-gray-200">
          <h2 class="font-bold text-lg ">
            Your Current Subscription
          </h2>
          <p class="mb-2 flex items-center">
            You are currently subscribed to Plan
            <span class="bg-gruen rounded-md text-xs py-1 px-4 mx-1 text-white">
              {{ user.subscription.plan.name }}
            </span> for

            <span v-if="user.subscription.yearly">€{{ user.subscription.plan.plan_pricing_vearly }}/year</span>
            <span v-else>€{{ user.subscription.plan.plan_pricing }}/month</span>.
            <!--<button @click="scrollToElement" v-if="user.subscription.plan.isFree"
              class="ml-2 button rounded-md py-2 px-4 text-white text-sm text-bold">
              Upgrade now!
            </button>-->


          </p>

          <usage />

        </div>

        <div />

        <div v-if="!user.subscription || user.subscription.plan.isFree" class="card w-full rounded-md" id="p">

          <plans />
        </div>

        <div v-if="user.subscription.provider == 'forumpay'" class="card w-full rounded-md" id="p">

          <renewSubscription />
        </div>
        <div ref="p"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import Plans from "./plans.vue";
import Usage from "./components/usage.vue";
import { useUserStore } from "@/store/user";
import { storeToRefs } from 'pinia'
import renewSubscription from './renewSubscription';

export default {
  name: "NewSubscription",
  props: ['scrollbar'],
  components: {
    Icon,
    Plans,
    Usage,
    renewSubscription
  },
  setup() {

    const { user } = storeToRefs(useUserStore())
    return { user };
  },
  data() {
    return {


    };
  },
}
</script>

<style></style>