import axiosClient  from "../../services/axios.service";

export function getCoupons(){
    return axiosClient.get('/api/admin/coupons');
}


export function createCoupon(data){
    return axiosClient.post('/api/admin/coupons', data);
}
