<template>
  <div
    class="bg-white dark:bg-gray-800 p-5 lg:w-96 lg:mt-0 mt-4 shadow rounded-md w-full"
  >
    <h2 class="dark:text-gray-200 text-xl font-bold">
      Failed Webhooks
    </h2>
    <p class="text-gray-400">
      This is list if your last failed IPN's
    </p>
    <div class="mt-4 grid grid-cols-1 gap-4">
      <span
        v-if="!ipns"
        class="mx-auto"
      > No data to display</span>
      <button
        v-for="ipn in ipns.slice(0, 7)"
        :key="ipn.id"
        class="w-full box-border flex gap-4"
      >
        <span class="mr-1 text-5xl text-gray-400">
          <Icon
            v-if="ipn.type=='tron'"
            icon="cryptocurrency:trx"
          />
          <Icon
            v-if="ipn.type=='ethereum'"
            icon="cryptocurrency:eth"
          />
          <Icon
            v-if="ipn.type=='bsc'"
            icon="cryptocurrency:bnb"
          />
        </span>
        <span class="text-left">
          <h1 class="font-bold text-md dark:text-gray-200">{{ ipn.datetime }}</h1>
          <p class="text-gray-400 dark:text-gray-200">amount: {{ ipn.amount }}</p>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Icon } from "@iconify/vue";
import {getFailedNotifications} from "../../api/webhooks.api";
export default {
  components: {Icon},
  data() {
    return {
      eth: [],
      trx: [],
      bsc:[],
      ipns:[]

    };
  },
  mounted(){
    this.getFailedWebhooks();
  },
  methods:{
    getFailedWebhooks(){
      this.ipns = []
      this.restCall('ethereum');
      this.restCall('tron');
      this.restCall('bsc');
      
  },
  restCall(type){
    let array = [];
    getFailedNotifications(type)
       .then((response) => {
        let data = response.data.data.ipns
        data.forEach(function (element) {
          element.type = type;
        });
        this.ipns = this.ipns.concat(data)
        this.ipns.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.datetime) - new Date(a.datetime);
      });
       })
        .catch((error) => {
          console.error
        });

    }
  }

};
</script>

<style>


</style>