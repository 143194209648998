<template>
  <div
    class="bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border shadow"
  >
    <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200 dark:text-gray-200">
      Requests
    </h2>
    <p class="text-gray-400 font-lexend font-normal">
      Your Requests per Period
    </p>
    
    <br>
    <apexchart
      id="chart"
      width="100%"
      height="380"
      type="area"
      :options="optionsArea"
      :series="seriesArea"
      :sparkline="{
        enabled: true,
      }"
    />
    <br>
    <hr>
    <div class="wrapper-button mt-3">
      <select
        id=""
        v-model="type"
        name=""
        class="
          dark:bg-gray-800 dark:hover:bg-gray-700
          border-gray-200
          dark:border-gray-300
          border
          max-w-lg
          px-4
          py-3
          block
          rounded-md
          text-gray-500
          dark:text-white
        "
        @change="getStats"
      >
        <option value="month">
          Last 30 Days
        </option>
        <option value="year">
          Last 12 Months
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {getStatistics} from "../../api/requests.api";
export default {
  data() {
    return {
      type: 'year',
      // chart data area
      optionsArea: {
        chart: {
          id: 'chart',
          foreColor: '#9ca3af',
          fontFamily: "lexend, sans-serif",
        },
        noData: {
          text: "No Data to display",
          align: "center",
          verticalAlign: "middle",
        },
        xaxis: {
          
        },
        
        fontFamily: "Segoe UI, sans-serif",
        stroke: {
          curve: "straight",
        },

        markers: {
          size: 0,
        },
        yaxis: {
          show: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
      },
      colors : ['#008ffb', '#00e396','#ef4444'],
      seriesArea: [
        {
          name: "Revenue",
          data: [],
        },
        {
          name: "Revenue (Previous period)",
          data: [],
        },
      ],
    };
  },
  mounted(){
    this.getStats();
  },
  methods:{
    getStats(){
      getStatistics(this.type)
       .then((response) => {
          if(response.status == 200){
            try{
            
            console.log(response.data);

            this.optionsArea = {
              xaxis :{
                categories: response.data.map(({ countdate }) => moment(new Date(countdate)).format(this.type=="month"?"MM-DD": "YYYY-MM")),
                labels:{
                  style:{

                  }
                }
              },
              colors : ['#008ffb', '#00e396','#ef4444']
            }

            this.seriesArea = [
              {
                name: 'All Requests',
                data: response.data.map(({ all }) => all)
              },
              {
                name: 'Succcessful Requests',
                data: response.data.map(({ successful }) => successful)
              },
              {
                name: 'Failed Requests',
                data: response.data.map(({ failed }) => failed)
              },


            ]
            }
            catch($e){
              console.log($e)
            }
          }
        })
        .catch((error) => {

          if(error.response.status == 400){
            this.errors = error.response.data;
            console.log( error.response.data);
          }
          else{
          
            this.$toast.error("An Error occured: <br> "+error+" <br> please contact support", {
              // optional options Object
            });


          }
          
        });

    }
  }
};
</script>

<style>


</style>